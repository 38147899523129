/* eslint-disable array-callback-return */
import React, {Component, Fragment} from "react";
import {
  addConsultationDetailAPI,
  addHealthAssessmentPatientAPI,
  getConsultationDetailAPI,
  getPatientHealthAssessmentDetailApi,
  updateAppointmentReasonApi,
  endPrescriberAppointment,
  markAsReviewedAPI,
  updateConsultationDetailAPI,
  handleAutoSaveConsultationAPI,
} from "../../services/patientConsultation";
import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
  checkboxArray,
  Health_Assessment_KVP,
  showToast,
  // resizeTextArea,
} from "../../utilities/utils";
import {connect} from "react-redux";
import {ValidateInput} from "./PatientConsultationValidations";
// import ModalPopUp from "../../components/common/ModalPopUp";
import InformationalOutcomeModal from "./InformationalOutcomeModal";
import {
  Checkbox,
  Image,
  // Input,
  InputNumber,
  Popover,
  Radio,
  Select,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
// import Highlighter from "react-highlight-words";
import {searchSnomedCode} from "../../services/snomedCodes";
import {isEmpty, debounce, map, uniq, uniqBy, isArray, isNumber} from "lodash";
// import Highlighter from "react-highlight-words";
// import addImage from "../../assets/images/common/add-ic.png";
import deleteImage from "../../assets/images/common/delete-ic.png";
// import verifiedImage from "../../assets/images/common/ic_right.svg";
// import unverifiedImage from "../../assets/images/common/ic_cancel.svg";
// import editImage from "../../assets/images/common/edit-ic.png";
import moment from "moment";
import CommonLoader from "../../components/common/CommonLoader";
import store from "../../app/store";
import {
  DATE_FORMAT_24_HR,
  DP_QUESTIONNAIRE,
  ROLE_CLINICAL_PHARMACIST,
  ROLE_DOCTOR,
  presentingpopover,
  ROLE_SUPERINTENDENT_PHARMACIST,
  savedToast,
  savingToast,
  DP_QUESTIONNAIRE_LIVE,
} from "../../constants/common";
import {
  EditOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
// import PrescriptionAddModal from "./PrescriptionContainer/PrescriptionAddModal";
import {bindActionCreators} from "redux";
import {
  onRequestSetComponent,
  onRequestSetComponentID,
  onSetDynamicInput,
  onSetSidebarKey,
  onSetDynamicHealthInput,
} from "../../reducers/dynamicTab";
import {actions as preEncountersActions} from "../../reducers/preEnccounter";

import CreatableSelect from "react-select/creatable";
import {Button} from "antd";
import PreEncountersContainer from "../../containers/PatientDetailContainer/PreEncountersContainer/PreEncountersContainer";
import PrescriptionsContainer from "../../containers/PatientDetailContainer/PrescriptionsContainer/PrescriptionsContainer";
import SickNotesContainer from "../../containers/PatientDetailContainer/SickNotesContainer/SickNotesContainer";
import ReferralNotesContainer from "../PatientDetailContainer/ReferralNotesContainer/ReferralNotesContainer";
import CallScreenShotContainer from "../WalkinAppointment/CallScreenShotContainer";
// import ScrollBar from "react-perfect-scrollbar";
import AutoTextArea from "components/AutoTextArea";
import {actions as prescriptionAbbreviationActions} from "reducers/prescriptionAbbreviation";

import PerfectScrollbar from "react-perfect-scrollbar";
// import SplitPane from "react-split-pane";
import SplitPane, {Pane} from "react-split-pane-next";
import SymmtomDiagnosisTabs from "containers/WalkinAppointment/SymmtomDiagnosisTabs";
import ModalPopUp from "components/common/ModalPopUp";
import {PictureFilled, InfoCircleOutlined} from "@ant-design/icons";
import QuestionnaireFillUp from "containers/WalkinAppointment/QuestionnaireFillUp";
import InputMask from "react-input-mask";
import {
  fetchDiagnosisApi,
  fetchRegionsAPI,
  fetchSymptomsApi,
} from "services/symptoms";
import {Toaster} from "react-hot-toast";
import {appRoutesConst, navigateTo} from "app/navigation";
import {fetchSubmittedPreConsultations} from "services/preConsultConditions";
// import ExaminationEditor from "containers/WalkinAppointment/ExaminationEditor";
import Axios from "axios";
// import PreConsultSubmittedQuestionnaire from "containers/WalkinAppointment/PreConsultSubmittedQuestionnaire";
// import { Spinner } from "react-bootstrap";
// const {Option} = Select;
const SET_CONSULTATION_OBJ = "SET_CONSULTATION_OBJ";

class PatientConsultationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consultationDetail: null,
      history: "",
      editHistory: false,
      examination: "",
      editExamination: false,
      editDiagnosis: false,
      managementPlan: "",
      editManagementPlan: false,
      editPrescriptionPlan: false,
      prescription_plan: "",
      // barcodeNumber: "",
      // editBarcodeNumber: false,
      editOutcome: false,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      snomedCodes: [],
      searching: false,
      isLoadmore: false,
      search: false,
      snomedCodeText: "",
      showCodeModal: false,
      selectedCodesArray: [],
      newselectedCodesArray: [],
      selectedSnomedCodesArray: [],
      addingDetail: false,
      updatingDetail: false,
      isLoading: false,
      snomedCodeId: "",
      splitSize: true,
      fetchingRegion: false,
      regionsData: [],
      recentTravel: false,
      region_id: 1,
      searchingDiagnose: false,
      symptompSnomedCode: [],
      checkboxStateArray: [...checkboxArray],
      healthStateArray: [...Health_Assessment_KVP],
      enterCount: 0,
      isFinshedEdit: true,
      updatingConsultationDetail: false,
      comment_other: "",
      reviewer: "healthya_corp",
      review_by_id: null,
      template_id: null,
      isTriggeredAutoSave: false,
      presriptionPlan: "",
    };
    this.prescriptionModal = React.createRef();
    this.creatableRef = React.createRef();

    [
      "_getPatientConultationDetails",
      "_handleSubmit",
      "_handleTextChange",
      "_hideModal",
      "_showCodeModal",
      "_hideCodeModal",
      "_handleSnomedCodeSearch",
      "_getSnomedCodes",
      "_handleDropDownChange",
      "_handleCustomCodeAdd",
      "_handleModalSubmission",
      "_handleEdit",
      "_handleUpdateoutcome",
      "_handleUpdate",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSnomedCodeSearch, 1000);
    this.searchUpdateSymptom = debounce(this._handleSymptomSearch, 1000);
  }

  componentDidMount() {
    if (this.props.prev_appt_tab_key !== "pending")
      this._getPatientConultationDetails();
    if (this.props.prev_appt_tab_key === "unfinished") {
      this.setState({isFinshedEdit: false});
    }
    this.handleMountDynamicInput();
    const urlParams = new URLSearchParams(window.location.search);
    const fromPreConsultPopup = urlParams.get("fromPreConsultPopup");
    const pre_consult_id =
      this.props.prev_appt_tab_key === "pre_consult_reviewed"
        ? urlParams.get("pre_consult_id")
        : verifyObject(this.props.appointmentObj, "pre_consult_id", null);
    let {submitedPreConsultQuestionnaire} = this.props;

    console.log("this.props ---> into", submitedPreConsultQuestionnaire);
    if (
      !fromPreConsultPopup &&
      pre_consult_id &&
      submitedPreConsultQuestionnaire &&
      submitedPreConsultQuestionnaire.length === 0
    ) {
      this._getPreConsultSubmittedQuestionnaire();
    } else {
      this.setState({
        submitedQuestionnairesArray: submitedPreConsultQuestionnaire,
      });
    }
  }
  _getPreConsultSubmittedQuestionnaire = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const pre_consult_id =
      this.props.prev_appt_tab_key === "pre_consult_reviewed"
        ? urlParams.get("pre_consult_id")
        : verifyObject(this.props.appointmentObj, "pre_consult_id", null);

    this.setState(
      {
        fetchingSubmittedQuestionnaire: true,
      },
      async () => {
        try {
          let response = await fetchSubmittedPreConsultations({
            pre_consult_id: pre_consult_id,
          });
          if (response?.data?.data && response.status === 200) {
            this.setState(
              {
                submitedQuestionnairesArray: response?.data?.data,
                fetchingSubmittedQuestionnaire: false,
                subimittedQuestionnaireAPICount:
                  this.state.subimittedQuestionnaireAPICount + 1,
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;

                let {submitedQuestionnairesArray} = this.state;
                if (submitedQuestionnairesArray?.length === 0) {
                  //  submitedQuestionnairesArray = [];
                  errorToast({
                    content: "Submitted questionnaire not available",
                  });
                }

                console.log(
                  "submitedQuestionnairesArray",
                  submitedQuestionnairesArray
                );
                // let preConsultNoteParams = {};
                // if (consentShared) {
                //   preConsultNoteParams = {
                //     pre_consult_note:
                //       this.props.dynamicInputs?.pre_consult_note +
                //       `\n\n\n` +
                //       "Safety netting provided to patient",
                //   };
                // }

                let Inputs = {
                  ...this.props.dynamicInputs,
                  // ...preConsultNoteParams,
                  submitedQuestionnairesArray,
                };
                if (typeof onSetDynamicInput === "function") {
                  onSetDynamicInput(Inputs);
                }
              }
            );
          }
        } catch (e) {
          let {message} = getErrorObject(e);
          console.log(
            "🚀 ~ file: PreConsultDetailContainer.js:748 ~ PreConsultDetailContainer ~ _getPreConsultSubmittedQuestionnaire= ~ message:",
            message
          );
          this.setState({
            fetchingSubmittedQuestionnaire: false,
          });
          errorToast({
            content: message,
          });
        }
      }
    );
  };

  _handleReviewerChange = (e) => {
    let errors = null;
    // let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState(
      {
        [e.target.name]: e.target.value,
        errors: errors,
      },
      () => {
        if (this.state.reviewer === "ip_users") {
          // this._fetchIPUsers();
          this.setState({
            review_by_id: null,
          });
        } else {
          if (
            isEmpty(this.state.history) ||
            isEmpty(this.state.examination) ||
            isEmpty(this.state.managementPlan)
          ) {
            this.setState({isShowSubmit: false}, () => {
              this._SaveComponentStateToRedux();
            });
          } else {
            this._SaveComponentStateToRedux();
          }
        }
      }
    );
  };
  handleMountDynamicInput = async () => {
    let {dynamicInputs, dynamicHealthInputs, is_gp_informed} = this.props;
    let consentArray =
      is_gp_informed &&
      verifyObject(dynamicInputs, "isConsentGiven", []).length === 0
        ? JSON.parse(localStorage.getItem("isConsentGiven"))
          ? JSON.parse(localStorage.getItem("isConsentGiven"))
          : []
        : verifyObject(dynamicInputs, "isConsentGiven", []);
    let managementPlanLocal =
      is_gp_informed && verifyObject(dynamicInputs, "managementPlan", "") === ""
        ? +consentArray[0]?.code === 768321000000106
          ? "Patient made aware of the importance of good medical record sharing, Consent given"
          : "Patient made aware of the importance of good medical record sharing, declined"
        : verifyObject(dynamicInputs, "managementPlan", "");

    this.setState(
      {
        fetchingRegion: false,
        // regionsData: verifyObject(response, "data.data", []),
        ...dynamicInputs,
        ...dynamicHealthInputs,
        selectedCodesArray:
          dynamicInputs &&
          dynamicInputs.selectedSnomedCodesArray !== null &&
          dynamicInputs.isConsentGiven &&
          isArray(dynamicInputs.isConsentGiven)
            ? isArray(dynamicInputs.selectedSnomedCodesArray)
              ? [
                  ...(dynamicInputs.selectedSnomedCodesArray || []),
                  ...consentArray,
                ]
              : [dynamicInputs.selectedSnomedCodesArray, ...consentArray]
            : [
                ...consentArray,
                ...verifyObject(dynamicInputs, "selectedSnomedCodesArray", []),
              ],
        tabKey:
          this.props.consultation_tab_key !== null
            ? this.props.consultation_tab_key
            : "preencounters",
        managementPlan: managementPlanLocal,
      },
      () => {
        let autoTriggerPayload = {
          management_plan: this.state.managementPlan,
          prescription_plan: verifyObject(
            dynamicInputs,
            "prescription_plan",
            ""
          ),
        };

        this.handleAutoSaveConsultationTrigger(autoTriggerPayload);
        let foundValueCheckbox = checkboxArray.find(
          (item) => item.value !== null
        );
        let foundValueHealthbox = Health_Assessment_KVP.find(
          (item) => item.value !== null
        );

        if (this.state.examination === "" && foundValueCheckbox) {
          this.handleClearCheckboxValue();
        }
        if (this.state.examination === "" && foundValueHealthbox) {
          this.handleClearHealthboxValue();
        }
        this.setState({
          selectedSnomedCodesArray: uniq([
            ...(isArray(this.state.selectedSnomedCodesArray)
              ? this.state.selectedSnomedCodesArray
              : []),
            ...(isArray(this.state.selectedCodesArray)
              ? this.state.selectedCodesArray
              : []),
          ]),
        });
      }
    );
  };
  handleAutoSaveConsultationTrigger = async (
    data,
    fromHealthAssessment,
    fromRiskScore
  ) => {
    console.log(
      "MB TRIGGER FUNCTION",
      data,
      fromHealthAssessment,
      fromRiskScore
    );
    const {appointmentId} = this.props;
    const formData = new FormData();
    const {isAutoSaveConsultationData} = this.state;

    if (isAutoSaveConsultationData) {
      this.setState({isTriggeredAutoSave: true});

      setTimeout(
        async () => {
          showToast(savingToast, {
            id: "handleAutoSaveConsultationAPI",
            duration: 2000,
          });
          formData.set("temp_consultation_info[appointment_id]", appointmentId);

          for (const [key, value] of Object.entries(data)) {
            if (key !== "snomed_code_data" && !fromRiskScore) {
              formData.set(`temp_consultation_info[${key}]`, value);

              this.state.healthStateArray.forEach((item) => {
                if (item.key) {
                  formData.set(
                    `temp_consultation_info[health_summary][${item.key}]`,
                    item.value === null || item.value === "null"
                      ? ""
                      : item.value
                  );
                }
              });
              // } else if (fromRiskScore) {
              this.state.checkboxStateArray.forEach((item) => {
                if (item.short_text) {
                  formData.set(
                    `temp_consultation_info[risk_score][${item.short_text}]`,
                    item.value === null || item.value === "null"
                      ? ""
                      : item.value
                  );
                }
              });
            }
            if (key === "snomed_code_data") {
              if (
                data.snomed_code_data &&
                isArray(data.snomed_code_data) &&
                data.snomed_code_data.length > 0
              ) {
                data.snomed_code_data.forEach((item, index) => {
                  if (item.id)
                    formData.set(
                      `temp_consultation_info[snomed_code_data][${index}][id]`,
                      item.id
                    );
                  if (item.code)
                    formData.set(
                      `temp_consultation_info[snomed_code_data][${index}][code]`,
                      item.code
                    );
                  if (item.description)
                    formData.set(
                      `temp_consultation_info[snomed_code_data][${index}][description]`,
                      item.description
                    );
                });
              } else {
                formData.set(`temp_consultation_info[snomed_code_data]`, []);
              }
            }
          }

          try {
            this.setState({isTriggeredAutoSave: true});
            let response = await handleAutoSaveConsultationAPI(formData);
            if (response.data.status === 200) {
              showToast(savedToast, {
                id: "handleAutoSaveConsultationAPI",
              });
            }
          } catch (error) {
            console.error("Auto-save failed:", error);
          } finally {
            this.setState({isTriggeredAutoSave: false});
          }
        },
        fromHealthAssessment || fromRiskScore ? 0 : 1000
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    let {dynamicInputs, dynamicHealthInputs} = this.props;

    let consentArray = verifyObject(dynamicInputs, "isConsentGiven", []);

    if (
      consentArray.length !== 0 &&
      this.state.selectedCodesArray.length === 0
    ) {
      this.setState(
        {
          ...dynamicInputs,
          ...dynamicHealthInputs,
          selectedCodesArray:
            dynamicInputs &&
            dynamicInputs.selectedSnomedCodesArray !== null &&
            dynamicInputs.isConsentGiven &&
            isArray(dynamicInputs.isConsentGiven)
              ? isArray(dynamicInputs.selectedSnomedCodesArray)
                ? [
                    ...(dynamicInputs.selectedSnomedCodesArray || []),
                    ...consentArray,
                  ]
                : [dynamicInputs.selectedSnomedCodesArray, ...consentArray]
              : [...consentArray],
          // tabKey:
          //   this.props.consultation_tab_key !== null
          //     ? this.props.consultation_tab_key
          //     : "preencounters",
        },
        () => {},
        () => {
          this.setState({
            selectedSnomedCodesArray: uniq([
              ...(this.state.selectedSnomedCodesArray || []),
              ...(this.state.selectedCodesArray || []),
            ]),
          });
        }
      );
    }
  }
  componentWillUnmount() {
    // let {onSetDynamicInput} = this.props.dynamicTabAction;

    store.dispatch({type: "FROM_PAST_CONSULTATION_TRIGGER", payload: false});

    // let {onSetDynamicInput} = this.props.dynamicTabAction;

    // if (typeof onSetDynamicInput === "function") {
    //   onSetDynamicInput(null);
    // }
    // if (typeof onSetDynamicInput === "function") {
    //   onSetDynamicInput(null);
    // }

    // window.onpopstate = null;
  }

  async _handleSnomedCodeSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          // snomedCodes: [],
          // snomedCodeId: "",
          page: 1,
          searching: true,
          isLoadmore: false,
          search: value,
        },
        async () => {
          if (this.state.cancelToken && this.state.cancelToken.token) {
            this.state.cancelToken.cancel("Canceling previous request");
          }
          let cancelToken = Axios.CancelToken.source();
          await this.setState(
            {
              cancelToken: cancelToken,
            },
            async () => {
              // await this._getDrugs(false, cancelToken);

              this._getSnomedCodes(false, value, cancelToken);
            }
          );
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }

  _getSnomedCodes = async (isLoadMore, search, cancelToken) => {
    this.setState({searching: true});
    try {
      let response = await searchSnomedCode(
        {
          page: this.state.page,
          search: this.state.search,
        },
        cancelToken
      );
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      let newSnomedCodes = snomedCodes.map((k) => {
        return {
          value: k.code,
          label: k.description,
          id: k.id,
        };
      });

      this.setState({
        snomedCodes: isLoadMore
          ? [...this.state.snomedCodes, ...newSnomedCodes]
          : newSnomedCodes,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      if (error !== "REQ_CANCELLED") {
        const {message} = getErrorObject(error);
        await this.setState({searching: false});
        errorToast({content: message});
      } else {
        this.setState({searching: false});
        setTimeout(() => {
          this.setState({searching: true});
        }, [1000]);
      }
    }
  };

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        if (this.state.cancelToken && this.state.cancelToken.token) {
          this.state.cancelToken.cancel("Canceling previous request");
        }
        let cancelToken = Axios.CancelToken.source();
        await this.setState(
          {
            cancelToken: cancelToken,
          },
          async () => {
            // await this._getDrugs(false, cancelToken);

            this._getSnomedCodes(true, null, cancelToken);
          }
        );
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;
    let {
      page,
      pagination: {total_pages},
    } = this.state;
    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };
  async _getPatientConultationDetails(fromPreEncounter = false) {
    let {appointmentId, dynamicInputs} = this.props;
    try {
      await this.setState({isLoading: true});

      let response = await getConsultationDetailAPI({
        appointment_id: appointmentId,
      });
      let consultationDetail = verifyObject(response, "data.data", null);
      store.dispatch({
        type: SET_CONSULTATION_OBJ,
        payload: consultationDetail,
      });
      if (verifyObject(response, "data.status") === 202) {
        this.setState(
          {
            isAutoSaveConsultationData: true,
            history: verifyObject(consultationDetail, "history"),
            examination: verifyObject(consultationDetail, "examination"),
            managementPlan:
              dynamicInputs &&
              dynamicInputs.managementPlan &&
              dynamicInputs.managementPlan !== ""
                ? dynamicInputs.managementPlan
                : verifyObject(consultationDetail, "management_plan"),
            presriptionPlan: verifyObject(
              consultationDetail,
              "prescription_plan"
            ),
            pre_consult_note: verifyObject(
              consultationDetail,
              "pre_consult_note"
            ),
            pre_consult_tag: verifyObject(
              consultationDetail,
              "pre_consult_tag"
            ),
            selectedCodesArray: verifyObject(
              consultationDetail,
              "snomed_code_data"
            ),
            reason: verifyObject(consultationDetail, "reason"),
            reason_text:
              verifyObject(consultationDetail, "reason") &&
              verifyObject(consultationDetail, "reason") !== "null" &&
              verifyObject(consultationDetail, "reason") !== "undefined" &&
              typeof verifyObject(consultationDetail, "reason") === "string"
                ? this.processString(verifyObject(consultationDetail, "reason"))
                : [],
            // history:verifyObject(consultationDetail,"history"),
            isLoading: false,
          },
          () => {
            if (this.state.reason_text.length !== 0) {
              const dontCallFuncForReasons =
                process.env.REACT_APP === "prod"
                  ? DP_QUESTIONNAIRE_LIVE
                  : DP_QUESTIONNAIRE;
              if (
                !dontCallFuncForReasons.some((que) =>
                  this.state.reason_text.some((item) => item.value === que.id)
                )
              ) {
                if (this.props.gender !== "female") {
                  this.handleGetDiagnosis();
                } else {
                  this.handleGetDiagnosisFemale();
                }
              }
            }
            if (verifyObject(consultationDetail, "risk_score")) {
              const keys = Object.keys(
                verifyObject(consultationDetail, "risk_score", {})
              );
              console.log("All keys----", keys);

              keys.forEach((key) => {
                const foundInState = this.state.checkboxStateArray.find(
                  (s) => s.short_text === key
                );
                console.log("All keys founded in state----", foundInState);

                if (foundInState) {
                  this.setState((prevState) => {
                    // Clone the current state object and nested checkboxStateArray
                    const newState = {...prevState};
                    const newCheckboxStateArray = [
                      ...prevState.checkboxStateArray,
                    ];

                    // Find the index of the item in the array
                    const index = newCheckboxStateArray.indexOf(foundInState);
                    if (index !== -1) {
                      // Update the specific item in the array immutably
                      newCheckboxStateArray[index] = {
                        ...newCheckboxStateArray[index],
                        value: verifyObject(
                          consultationDetail,
                          "risk_score",
                          {}
                        )[key],
                      };
                    }

                    // Set the updated array back in the new state
                    newState.checkboxStateArray = newCheckboxStateArray;

                    // Return the new state object
                    return newState;
                  });
                }
              });
            }

            if (verifyObject(consultationDetail, "health_summary")) {
              const keys = Object.keys(
                verifyObject(consultationDetail, "health_summary", {})
              );
              console.log("All health_summary keys----", keys);
              keys.forEach((key) => {
                const foundInState = this.state.healthStateArray.find(
                  (s) => s.key === key
                );
                console.log(
                  "All health_summary keys founded in state----",
                  foundInState
                );
                if (foundInState) {
                  this.setState((prevState) => {
                    // Clone the current state object and nested healthStateArray
                    const newState = {...prevState};
                    const newHealthStateArray = [...prevState.healthStateArray];

                    // Update the state based on the found key
                    newState[key] = verifyObject(
                      consultationDetail,
                      "health_summary",
                      {}
                    )[key];

                    // Update the specific item in the array
                    const index = newHealthStateArray.indexOf(foundInState);
                    if (index !== -1) {
                      newHealthStateArray[index] = {
                        ...newHealthStateArray[index],
                        value: verifyObject(
                          consultationDetail,
                          "health_summary",
                          {}
                        )[key],
                      };
                    }

                    // Set the updated array back in the new state
                    newState.healthStateArray = newHealthStateArray;

                    // Return the new state object
                    return newState;
                  });
                }
              });
            }

            store.dispatch({
              type: "IS_AUTOSAVE_CONSULTATION_DATA",
              payload: this.state.isAutoSaveConsultationData,
            });
            let {onSetDynamicInput} = this.props.dynamicTabAction;

            if (typeof onSetDynamicInput === "function") {
              let payload = {
                ...dynamicInputs,
                reason_text: this.state.reason_text,
                history: this.state.history,
                examination: this.state.examination,
                managementPlan: this.state.managementPlan,
                pre_consult_note: this.state.pre_consult_note,
                pre_consult_tag: this.state.pre_consult_tag,
                reason: this.state.reason,
                selectedCodesArray: this.state.selectedCodesArray,
              };
              onSetDynamicInput(payload);
            }
            this.fetchRegions(true); //passing true for don't update state as consultation get from auto save API

            if (!verifyObject(this.props, "dynamicHealthInputs", false)) {
              this.getPatientHealthAssessmentDetail(true); //passing true for don't update state as consultation get from auto save API
            }
            if (fromPreEncounter) {
              setTimeout(() => {
                this.updateHeight();
              }, 500);
            }
          }
        );
      } else {
        await this.setState(
          {
            isAutoSaveConsultationData: false,
            consultationDetail: consultationDetail,
            newselectedCodesArray: dynamicInputs?.newselectedCodesArray
              ? dynamicInputs?.newselectedCodesArray
              : [],
            selectedSnomedCodesArray: dynamicInputs?.selectedSnomedCodesArray
              ? dynamicInputs?.selectedSnomedCodesArray
              : verifyObject(consultationDetail, "snomed_codes", []).length !==
                0
              ? uniqBy(consultationDetail.snomed_codes, "description")
              : [],
            selectedCodesArray: dynamicInputs?.selectedCodesArray
              ? dynamicInputs?.selectedCodesArray
              : verifyObject(consultationDetail, "snomed_codes", []).length !==
                0
              ? uniqBy(consultationDetail.snomed_codes, "description")
              : [],
            prescription_plan: dynamicInputs?.prescription_plan
              ? dynamicInputs?.prescription_plan
              : verifyObject(
                  consultationDetail,
                  "consultation_log.prescription_plan",
                  null
                ),
            prescriptionPlan: dynamicInputs?.prescription_plan
              ? dynamicInputs?.prescription_plan
              : verifyObject(
                  consultationDetail,
                  "consultation_log.prescription_plan",
                  null
                ),
            isLoading: false,
          },
          () => {
            store.dispatch({
              type: "IS_AUTOSAVE_CONSULTATION_DATA",
              payload: this.state.isAutoSaveConsultationData,
            });
            if (fromPreEncounter) {
              setTimeout(() => {
                this.updateHeight();
              }, 500);
            }
          }
        );
      }
    } catch (error) {
      if (
        (verifyObject(error, "data.status", false) === 404 ||
          +error === +404) &&
        this.props.prev_appt_tab_key !== "pending"
      ) {
        this.setState({isAutoSaveConsultationData: true}, () => {
          store.dispatch({
            type: "IS_AUTOSAVE_CONSULTATION_DATA",
            payload: this.state.isAutoSaveConsultationData,
          });
        });
        this.fetchRegions();

        if (!verifyObject(this.props, "dynamicHealthInputs", false)) {
          await this.getPatientHealthAssessmentDetail();
        }
      }
      store.dispatch({
        type: SET_CONSULTATION_OBJ,
        payload: null,
      });
      const {message} = getErrorObject(error);
      this.setState({isLoading: false});
      if (error?.data?.status !== 404) {
        errorToast({content: message});
      }
    }
  }
  processString = (input) => {
    // Try to parse the input as JSON
    try {
      let parsedJson = JSON.parse(input);
      // Check if the result is an array
      if (Array.isArray(parsedJson)) {
        console.log("Parsed as JSON array:", parsedJson);
        return parsedJson;
      }
    } catch (e) {
      // If it's not valid JSON, we proceed
      console.log("Not JSON, moving on to check for other formats.");
    }

    // If input is comma-separated
    if (input.includes(",")) {
      let commaSeparatedArray = input.split(",").map((item) => ({
        value: item.trim(),
        label: item.trim(),
      }));
      console.log("Parsed as comma-separated array:", commaSeparatedArray);
      return commaSeparatedArray;
    }

    // Default case: Treat it as a single string
    return [{value: input, label: input}];
  };
  handleRegionHAfunction = async () => {
    if (this.props.prev_appt_tab_key !== "pending") {
      await this.fetchRegions();

      await this.getPatientHealthAssessmentDetail();
    }
  };
  getObjectFromData = (data, value) => {
    let found;
    if (data && data.length > 0) {
      // eslint-disable-next-line array-callback-return
      data.find((item, index) => {
        if (item.name === value) return (found = item);
      });
    }

    return found;
  };
  getPatientHealthAssessmentDetail = async (fromAutoSaveEvent) => {
    let {patientId, dynamicHealthInputs, patient_user_id} = this.props;
    if (!dynamicHealthInputs) {
      try {
        this.setState({isLoadingHealthAssessment: true});
        let response = await getPatientHealthAssessmentDetailApi(
          patientId ? patientId : patient_user_id
        );
        let data = verifyObject(response, "data.data", []);
        // verifyObject(response, "data.data", []).length > 0 &&
        //   verifyObject(response, "data.data", []).map((item, index) => {
        //     return
        this.setState(
          {
            pulseObject: this.getObjectFromData(data, "pulse_per_minute"),
            heightObject: this.getObjectFromData(data, "height"),
            weightObject: this.getObjectFromData(data, "weight"),
            body_temperatureObject: this.getObjectFromData(
              data,
              "body_temperature"
            ),
            blood_oxygen_saturationObject: this.getObjectFromData(
              data,
              "blood_oxygen_saturation"
            ),
            systolic_bpObject: this.getObjectFromData(data, "systolic_bp"),
            diastolic_bpObject: this.getObjectFromData(data, "diastolic_bp"),
            bmiObject: this.getObjectFromData(data, "bmi"),
          },
          async () => {
            let {
              pulseObject,
              heightObject,
              weightObject,
              blood_oxygen_saturationObject,
              body_temperatureObject,
              bmiObject,
              systolic_bpObject,
              diastolic_bpObject,
            } = this.state;
            let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
            if (typeof onSetDynamicHealthInput === "function") {
              let Inputs = {
                ...this.props.dynamicHealthInputs,
                pulseObject,
                heightObject,
                weightObject,
                blood_oxygen_saturationObject,
                body_temperatureObject,
                bmiObject,
                systolic_bpObject,
                diastolic_bpObject,
              };
              await onSetDynamicHealthInput(Inputs);
            }
          }
        );

        await this.setState({isLoadingHealthAssessment: false});
      } catch (error) {
        // const {message} = getErrorObject(error);
        await this.setState({isLoadingHealthAssessment: false});
        // errorToast({content: message});
      }
    }
  };
  // _handleTextChange(e) {
  //   let errors = null;

  //   if (this.state.errors) {
  //     errors = Object.assign("", this.state.errors);
  //     delete errors[e.target.name];
  //   }
  //   this.setState({[e.target.name]: e.target.value, errors: errors}, () => {});
  // }
  _handleTextChange(e, controlName, isFromQuestionnaire) {
    let errors = null;
    let name = null;
    if (controlName) {
      name = controlName;
    } else {
      name = e.target.name;
    }
    // let value = e.target.value;
    console.log("TEXT CHANGE --->", e);
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[name];
    }
    this.setState(
      {
        [name]: controlName ? e : e.target.value,
        errors: errors,
      },
      () => {
        if (
          (name === "history" && e) ||
          (name === "examination" && (e || !e)) ||
          (name === "managementPlan" && e && !this.state.isTriggeredAutoSave)
        ) {
          let autoTriggerPayload = {
            [name === "managementPlan" ? "management_plan" : name]: controlName
              ? e
              : e.target.value,
            snomed_code_data:
              this.removeDuplicates(this.state.selectedSnomedCodesArray) ||
              this.removeDuplicates(this.state.selectedCodesArray) ||
              [],
          };
          if (this.state.presriptionPlan && this.state.presriptionPlan !== "") {
            autoTriggerPayload = {
              ...autoTriggerPayload,
              prescription_plan:
                this.state.presriptionPlan !== "null"
                  ? this.state.presriptionPlan
                  : "",
            };
          }
          if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
          this.debounceTimeout = setTimeout(() => {
            if (!this.state.isTriggeredAutoSave) {
              this.handleAutoSaveConsultationTrigger(autoTriggerPayload);
            }
            this.debounceTimeout = null;
          }, 1000);
        }
        if (
          isEmpty(this.state.history) ||
          isEmpty(this.state.examination) ||
          isEmpty(this.state.managementPlan)
        ) {
          this.setState({isShowSubmit: false}, () => {
            this._SaveComponentStateToRedux();
          });
        } else {
          this._SaveComponentStateToRedux();
        }
      }
    );
  }

  _SaveComponentStateToRedux = (fromClearUpdateConsultation) => {
    let {onSetDynamicInput, onSetDynamicHealthInput} =
      this.props.dynamicTabAction;
    if (fromClearUpdateConsultation) {
      if (
        typeof onSetDynamicInput === "function" ||
        typeof onSetDynamicHealthInput === "function"
      ) {
        onSetDynamicInput(null);
        onSetDynamicHealthInput(null);
      }
    } else {
      if (typeof onSetDynamicInput === "function") {
        let {
          history,
          examination,
          managementPlan,
          barcodeNumber,
          // selectedSnomedCodesArray,
          selectedCodesArray,
          isShowSubmit,
          isConsentGiven,
          reason_text,
          selectedCheckbox,
          // checkboxStateArray,
        } = this.state;
        let inputObj = {};
        // eslint-disable-next-line no-unused-vars
        // let inputs = checkboxStateArray.map((item) => {
        //   if (this.state[item.id]) {
        //     // {`${item.id}`:this.state[item.id]}
        //     inputObj = {
        //       ...inputObj,
        //       [item.id]: this.state[item.id],
        //     };
        //   }
        // });

        let Inputs = {
          ...this.props.dynamicInputs,
          ...inputObj,
          history: history,
          examination: examination,
          managementPlan: managementPlan,
          barcodeNumber: barcodeNumber,
          selectedSnomedCodesArray: selectedCodesArray,
          selectedCodesArray: selectedCodesArray,

          isShowSubmit: isShowSubmit,
          isConsentGiven: isConsentGiven,
          reason_text: reason_text,
          selectedCheckbox: selectedCheckbox,
          isFinshedEdit: this.state.isFinshedEdit,
          regionsData: this.state.regionsData,
        };
        if (fromClearUpdateConsultation) {
          onSetDynamicInput(null);
          onSetDynamicHealthInput(null);
        } else {
          onSetDynamicInput(Inputs);
        }
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    let {dynamicInputs, dynamicHealthInputs} = nextProps;
    this.setState({
      ...dynamicInputs,
      ...dynamicHealthInputs,
    });
  }

  _handleCustomCodeAdd(value) {
    // let {snomedCodeText} = this.state;
    let Obj = {
      id: "",
      code: "",
      description: value,
    };
    this.setState(
      {
        snomedCodeText: "",
        snomedCodeId: "",
        newselectedCodesArray: this.state.newselectedCodesArray.concat(Obj),
      },
      () => {
        if (this.state.consultationDetail === null) {
          this.setState(
            {
              // showCodeModal: false,
              selectedSnomedCodesArray: uniq([
                ...(this.state.selectedSnomedCodesArray || []),
                ...(this.state.newselectedCodesArray || []),
              ]),
            },
            () => {
              let autoTriggerPayload = {
                snomed_code_data: this.state.selectedSnomedCodesArray,
              };

              this.handleAutoSaveConsultationTrigger(autoTriggerPayload);
            }
          );
        } else {
          // this._handleSnomedCodeUpdate(); // need to update on click of save button
        }
      }
    );
  }

  async _handleSubmit(isSubmitForReview) {
    let {
      history,
      examination,
      managementPlan,
      // prescription_plan,
      // barcodeNumber,
    } = this.state;

    let toBeValidateObj = {
      history: history,
      examination: examination,
      managementPlan: managementPlan,
      // barcodeNumber: barcodeNumber,
      // prescription_plan: prescription_plan,
    };

    const errors = ValidateInput(toBeValidateObj);

    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      this.setState(
        {
          visible: true,
          isSubmitForReview: isSubmitForReview ? isSubmitForReview : false,
        },
        () => {}
      );
    }
  }

  async _handleModalSubmission() {
    let {
      treatment,
      followUp,
      referral,
      comment,
      history,
      examination,
      managementPlan,
      barcodeNumber,
      presriptionPlan,
      reason_text,
      selectedCodesArray,
      selectedSnomedCodesArray,
      comment_other,
      enableOtherField,
      template_id,
    } = this.state;
    let {
      appointmentId,
      patientId,
      pulse_per_minute,
      systolic_bp,
      diastolic_bp,
      body_temperature,
      blood_oxygen_saturation,
      height,
      weight,
      appointment_tag,
      respiratory_rate,
    } = this.props;

    let consentArray = verifyObject(
      this.props.dynamicInputs,
      "isConsentGiven",
      []
    );
    let appointmentTagsParams = {};
    if (appointment_tag) {
      appointmentTagsParams = {
        appointment_tag: appointment_tag,
      };
    }
    let toBeValidateObj2 = {
      treatment: treatment,
      followUp: followUp,
      referral: referral,
      comment: enableOtherField
        ? // &&
          // this.props.prev_appt_tab_key === "pre_consult_reviewed"
          comment_other
        : comment,

      visible: true,
    };
    const errors = ValidateInput(toBeValidateObj2, "fromPreConsult");

    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let submittedReviewParamas = {};

      if (this.state.isSubmitForReview) {
        submittedReviewParamas = {
          status: "submitted_to_review", //or finished
        };
      }
      let payload = {
        consultation: {
          appointment_id: appointmentId,
          history: history,
          examination: examination,
          management_plan: managementPlan,
          prescription_plan: presriptionPlan,
          barcode: barcodeNumber,
          patient_id: patientId ? patientId : this.props.patient_user_id,
          respiratory_rate: respiratory_rate,
          outcome: {
            treatment: treatment,
            follow_up: followUp,
            referral: referral,
            comment: enableOtherField
              ? // &&
                // this.props.prev_appt_tab_key === "pre_consult_reviewed"
                comment_other
              : comment,
          },
          ...submittedReviewParamas,
        },

        snomed_codes:
          isArray(selectedSnomedCodesArray) &&
          selectedSnomedCodesArray.length !== 0
            ? this.removeDuplicates([
                ...(selectedSnomedCodesArray || []),
                ...(consentArray || []),
              ])
            : this.removeDuplicates([
                ...(selectedCodesArray || []),
                ...(consentArray || []),
              ]),
      };
      let health_summaryPayload = {
        health_summary: {},
      };
      if (pulse_per_minute && pulse_per_minute !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            pulse_per_minute,
          },
        };
      }

      if (systolic_bp && systolic_bp !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            systolic_bp,
          },
        };
      }
      if (diastolic_bp && diastolic_bp !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            diastolic_bp,
          },
        };
      }
      if (blood_oxygen_saturation && blood_oxygen_saturation !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            blood_oxygen_saturation,
          },
        };
      }
      if (body_temperature && body_temperature !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            body_temperature,
          },
        };
      }
      if (height && height !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            height,
          },
        };
      }
      if (weight && weight !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            weight,
          },
        };
      }

      if (respiratory_rate && respiratory_rate !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            respiratory_rate,
          },
        };
      }
      // if (bmi && bmi !== "") {
      //   health_summaryPayload = {
      //     health_summary: {
      //       ...health_summaryPayload.health_summary,
      //       bmi,
      //     },
      //   };
      // }

      let healthPayload = {
        appointment_id: appointmentId,
        ...health_summaryPayload,
      };
      let reasonP = {};
      if (
        (this.props.dynamicInputs && this.props.dynamicInputs.reason_text) ||
        reason_text
      ) {
        reasonP = {
          reason: this.props.dynamicInputs.reason_text
            ? this.props.dynamicInputs.reason_text &&
              isArray(this.props.dynamicInputs.reason_text)
              ? this.props.reason_text.map((item) => item.label).join(",")
              : this.props.dynamicInputs.reason_text
            : reason_text,
        };
      }
      let payloadReason = {
        id: appointmentId,
        ...reasonP,
      };

      let reviewerParams1 = {};
      if (this.props.appointmentObj.appointment_type === "video") {
        reviewerParams1 = {
          review_by_id: this.state.review_by_id,
        };
        if (this.state.reviewer === "healthya_corp") {
          reviewerParams1 = {
            review_by_healthya_team: true,
            // review_by_id: reviewerParams.review_by_id,
          };
        }
      }
      try {
        this.setState({addingDetail: true});
        if (reason_text && !isEmpty(reason_text)) {
          this.setState({
            addingDetail: true,
            isAutoSaveConsultationData: false,
          });

          let reasonResponse = await updateAppointmentReasonApi(payloadReason);

          successToast({
            content: verifyObject(reasonResponse, "data.message", "Success"),
          });
          if (
            healthPayload &&
            Object.keys(healthPayload.health_summary).length > 0
          ) {
            let healthResponse = await addHealthAssessmentPatientAPI(
              healthPayload
            );
            successToast({
              content: verifyObject(healthResponse, "data.message", "Success"),
            });
          }
          let appointmentStatus =
            verifyObject(this.props, "appointmentObj.status", null) ===
            "call_ended";

          if (appointmentStatus) {
            // Call _endAppointment first if appointmentStatus is true
            await this._endAppointment(
              this.state.isSubmitForReview,
              appointmentStatus
            );
          }
          payload = {
            consultation: {
              appointment_id: appointmentId,
              history: history,
              examination: isArray(examination)
                ? examination.join(",")
                : examination,
              management_plan: managementPlan,
              prescription_plan: presriptionPlan,
              barcode: barcodeNumber,
              patient_id: patientId ? patientId : this.props.patient_user_id,
              respiratory_rate: respiratory_rate,
              outcome: {
                treatment: treatment,
                follow_up: followUp,
                referral: referral,
                comment: enableOtherField
                  ? // &&
                    // this.props.prev_appt_tab_key === "pre_consult_reviewed"
                    comment_other
                  : comment,
              },
              ...(this.props.prev_appt_tab_key === "unfinished" &&
              this.state.isSubmitForReview
                ? submittedReviewParamas
                : {}),
            },
            snomed_codes: selectedSnomedCodesArray,
          };
          let templateIdParams = {};
          if (template_id) {
            templateIdParams = {
              template_id,
            };
          }
          let response = await addConsultationDetailAPI({
            ...payload,
            consultation: {
              ...payload.consultation,
              ...appointmentTagsParams,
            },
            ...templateIdParams,
            ...reviewerParams1,
          });
          this.handleClearUpdateDetails();

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
          if (typeof this.props.onSetDynamicHealthInput === "function") {
            await this.props.onSetDynamicHealthInput(null);
          }
          store.dispatch({
            type: "SET_SYMPTOMP_FETCHED_LINKS",
            payload: null,
          });
          let {navigateTo} = this.props.navigationAction;

          if (typeof navigateTo === "function") {
            if (
              this.props.prev_appt_tab_key === "pre_consult_reviewed" ||
              this.props.prev_appt_tab_key === "kiosk_reviewed"
            ) {
              await navigateTo(appRoutesConst.preconsultationReview);
            } else {
              await navigateTo(appRoutesConst.dashboard);
            }
          }
        } else if (
          healthPayload &&
          Object.keys(healthPayload.health_summary).length > 0
        ) {
          this.setState({
            addingDetail: true,
            isAutoSaveConsultationData: false,
          });

          let healthResponse = await addHealthAssessmentPatientAPI(
            healthPayload
          );
          successToast({
            content: verifyObject(healthResponse, "data.message", "Success"),
          });
          //# TODO: append text into examination here
          payload = {
            consultation: {
              appointment_id: appointmentId,
              history: history,
              examination: isArray(examination)
                ? examination.join(",")
                : examination,

              // examination !== "" && examination.trim().length !== 0
              //   ? `${examination + "\n" + append_text.join("\n")}\n`
              //   : `${append_text.join("\n")}`,
              management_plan: managementPlan,
              prescription_plan: presriptionPlan,
              barcode: barcodeNumber,
              patient_id: patientId ? patientId : this.props.patient_user_id,
              respiratory_rate: respiratory_rate,
              outcome: {
                treatment: treatment,
                follow_up: followUp,
                referral: referral,
                comment: enableOtherField
                  ? // &&
                    // this.props.prev_appt_tab_key === "pre_consult_reviewed"
                    comment_other
                  : comment,
              },
              ...(this.props.prev_appt_tab_key === "unfinished" &&
              this.state.isSubmitForReview
                ? submittedReviewParamas
                : {}),
            },
            snomed_codes: selectedSnomedCodesArray,
          };
          let appointmentStatus =
            verifyObject(this.props, "appointmentObj.status", null) ===
            "call_ended";

          if (appointmentStatus) {
            // Call _endAppointment first if appointmentStatus is true
            await this._endAppointment(
              this.state.isSubmitForReview,
              appointmentStatus
            );
          }
          let templateIdParams = {};
          if (template_id) {
            templateIdParams = {
              template_id,
            };
          }
          let response = await addConsultationDetailAPI({
            ...payload,
            consultation: {
              ...payload.consultation,
              ...appointmentTagsParams,
            },
            ...templateIdParams,
            ...reviewerParams1,
          });
          this.handleClearUpdateDetails();

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
          if (typeof this.props.onSetDynamicHealthInput === "function") {
            await this.props.onSetDynamicHealthInput(null);
            store.dispatch({
              type: "SET_SYMPTOMP_FETCHED_LINKS",
              payload: null,
            });
          }
          let {navigateTo} = this.props.navigationAction;

          if (typeof navigateTo === "function") {
            if (
              this.props.prev_appt_tab_key === "pre_consult_reviewed" ||
              this.props.prev_appt_tab_key === "kiosk_reviewed"
            ) {
              await navigateTo(appRoutesConst.preconsultationReview);
            } else {
              await navigateTo(appRoutesConst.dashboard);
            }
          }
        } else {
          this.setState({
            addingDetail: true,
            isAutoSaveConsultationData: false,
          });

          let appointmentStatus =
            verifyObject(this.props, "appointmentObj.status", null) ===
            "call_ended";

          if (appointmentStatus) {
            // Call _endAppointment first if appointmentStatus is true
            await this._endAppointment(
              this.state.isSubmitForReview,
              appointmentStatus
            );
          }
          let templateIdParams = {};
          if (template_id) {
            templateIdParams = {
              template_id,
            };
          }
          let response = await addConsultationDetailAPI({
            ...payload,
            consultation: {
              ...payload.consultation,
              ...appointmentTagsParams,
              ...(this.props.prev_appt_tab_key === "unfinished" &&
              this.state.isSubmitForReview
                ? submittedReviewParamas
                : {}),
            },
            ...templateIdParams,
            ...reviewerParams1,
          });
          this.handleClearUpdateDetails();

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
          if (typeof this.props.onSetDynamicHealthInput === "function") {
            await this.props.onSetDynamicHealthInput(null);
          }
          store.dispatch({
            type: "SET_SYMPTOMP_FETCHED_LINKS",
            payload: null,
          });
        }
        this.handleClearCheckboxValue();
        let appointmentStatus =
          verifyObject(this.props, "appointmentObj.status", null) ===
          "call_ended";

        this._getPatientConultationDetails();
        if (
          !appointmentStatus &&
          this.props.prev_appt_tab_key !== "finished" &&
          this.props.prev_appt_tab_key !== "pre_consult_reviewed" &&
          this.props.prev_appt_tab_key !== "kiosk_reviewed" &&
          this.props.prev_appt_tab_key !== "reviewed"
        ) {
          this._endAppointment(this.state.isSubmitForReview, appointmentStatus);
        }
        let {navigateTo} = this.props.navigationAction;

        if (typeof navigateTo === "function") {
          if (
            this.props.prev_appt_tab_key === "pre_consult_reviewed" ||
            this.props.prev_appt_tab_key === "kiosk_reviewed"
          ) {
            await navigateTo(appRoutesConst.preconsultationReview);
          } else {
            await navigateTo(appRoutesConst.dashboard);
          }
        }
        this.setState({
          addingDetail: false,
          isAutoSaveConsultationData: false,
        });
        // this._clearData();
        // this._clearComponents();
      } catch (error) {
        const {message} = getErrorObject(error);
        this.setState({addingDetail: false});
        errorToast({content: message});
      }
    }
  }
  handleClearUpdateDetails = (consultationData) => {
    return this.setState(
      (prevState) => {
        return {
          consultationDetail: consultationData
            ? consultationData.data.data
            : this.state.consultationDetail,
          selectedSnomedCodesArray: consultationData
            ? verifyObject(consultationData, "data.data.snomed_codes", [])
                .length !== 0
              ? uniqBy(consultationData.data.data.snomed_codes, "description")
              : []
            : this.state.selectedSnomedCodesArray,
          newselectedCodesArray: [],
          updatingConsultationDetail: false,
          reason_text: [],
          diagnosisData: {},
          // history: removeHistoryStringArray.join("\n"),
          region_id: 1,
          recentTravel: false,
          checkboxStateArray: [...checkboxArray],
          healthStateArray: [...Health_Assessment_KVP],
          history: "",
          editHistory: false,
          examination: "",
          editExamination: false,
          editDiagnosis: false,
          managementPlan: "",
          editManagementPlan: false,
          prescription_plan: "",
          editPrescriptionPlan: false,
          // barcodeNumber: "",
          // editBarcodeNumber: false,
          editOutcome: false,
          treatment: "",
          followUp: "",
          referral: "",
          comment: "",
          snomedCodes: [],
          searching: false,
          isLoadmore: false,
          search: false,
          snomedCodeText: "",
          showCodeModal: false,
          addingDetail: false,
          updatingDetail: false,
          visible: false,
          isFinshedEdit: true,
          outcome: {},
          reason: null,
          symptomsData: [],
          pulse_per_minute: "",
          diastolic_bp: "",
          systolic_bp: "",
          blood_oxygen_saturation: "",
          body_temperature: "",
          height: "",
          weight: "",
          respiratory_rate: "",
          bmi: "",
          enableOtherField: false,
          comment_other: "",
        };
      },
      () => {
        this._SaveComponentStateToRedux(true); // passed true for clear all input from redux also
      }
    );
  };

  _endAppointment = async (isSubmitForReview, appointmentStatus) => {
    let submittedReviewParamas = {};
    let reviewerParams1 = {
      review_by_id: this.state.review_by_id,
    };
    if (this.state.reviewer === "healthya_corp") {
      reviewerParams1 = {
        review_by_healthya_team: true,
        // review_by_id: reviewerParams.review_by_id,
      };
    }
    if (isSubmitForReview) {
      submittedReviewParamas = {
        status: "submitted_to_review", //or finished
      };
    }
    let {appointmentId} = this.props;
    let payload = {
      id: appointmentId,
      ...submittedReviewParamas,
      ...reviewerParams1,
    };
    console.log("Payload:endPrescriberAppointment ", payload);
    try {
      await this.setState({
        isCallEnding: true,
      });

      let response = await endPrescriberAppointment(payload);
      if (response.data && response.data.message) {
        this.setState({prescriberCallEnded: true, isCallEnding: false});
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  };

  _clearData() {
    this.setState({
      history: "",
      editHistory: false,
      examination: "",
      editExamination: false,
      editDiagnosis: false,
      managementPlan: "",
      editManagementPlan: false,
      prescription_plan: "",
      editPrescriptionPlan: false,
      // barcodeNumber: "",
      // editBarcodeNumber: false,
      editOutcome: false,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      snomedCodes: [],
      searching: false,
      isLoadmore: false,
      search: false,
      snomedCodeText: "",
      showCodeModal: false,
      // p@
      addingDetail: false,
      updatingDetail: false,
      visible: false,

      outcome: {},
      region_id: 1,
      reason: null,
      symptomsData: [],
      recentTravel: false,
      diagnosisData: {},
    });
    store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: false});
    store.dispatch({
      type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
      payload: null,
    });
  }

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    // eslint-disable-next-line eqeqeq
    let found = this.state.snomedCodes.find((d) => d?.id == value?.id);
    let ids = map(this.state.newselectedCodesArray, "id");

    // if (found) {
    if (found && ids.includes(found.id) === false) {
      this.setState(
        {
          newselectedCodesArray: this.state.newselectedCodesArray.concat({
            id: found.id,
            code: found.value,
            description: found.label,
          }),
          snomedCodeId: "",
        },
        () => {
          this.setState(
            {
              showCodeModal: false,
              selectedCodesArray: uniq([
                ...this.state.selectedCodesArray,
                ...this.state.newselectedCodesArray,
              ]),
            },
            () => {
              let autoTriggerPayload = {
                snomed_code_data: this.state.selectedCodesArray,
              };

              this.handleAutoSaveConsultationTrigger(autoTriggerPayload);
              let {onSetDynamicInput} = this.props.dynamicTabAction;

              let Inputs = {
                ...this.props.dynamicInputs,
                newselectedCodesArray: this.state.newselectedCodesArray,
                selectedSnomedCodesArray: this.state.selectedCodesArray,
                selectedCodesArray: this.state.selectedCodesArray,
                isFinshedEdit: this.state.isFinshedEdit,
              };
              if (typeof onSetDynamicInput === "function") {
                onSetDynamicInput(Inputs);
              }
            }
          );
        }
      );
    } else {
      this.setState(
        {
          newselectedCodesArray: this.state.newselectedCodesArray.concat({
            id: found?.id,
            code: found?.value,
            description: found?.label,
          }),
          snomedCodeId: "",
        },
        () => {
          this.setState(
            {
              showCodeModal: false,
              selectedCodesArray: uniq([
                ...this.state.selectedCodesArray,
                ...this.state.newselectedCodesArray,
              ]),
            },
            () => {
              let autoTriggerPayload = {
                snomed_code_data: this.state.selectedCodesArray,
              };

              this.handleAutoSaveConsultationTrigger(autoTriggerPayload);
              let {onSetDynamicInput} = this.props.dynamicTabAction;

              let Inputs = {
                ...this.props.dynamicInputs,
                newselectedCodesArray: this.state.newselectedCodesArray,
                selectedSnomedCodesArray: this.state.selectedCodesArray,
                selectedCodesArray: this.state.selectedCodesArray,
                isFinshedEdit: this.state.isFinshedEdit,
              };
              if (typeof onSetDynamicInput === "function") {
                onSetDynamicInput(Inputs);
              }
            }
          );
        }
      );
    }
    // }
  }
  _removeCode = (record) => {
    if (this.state.newselectedCodesArray.length === 0) {
    } else {
      this.setState(
        {
          newselectedCodesArray: this.state.newselectedCodesArray.filter(
            (d) => d.id !== record.id || d.description !== record.description
          ),
        },
        () => {
          this.setState(
            {
              showCodeModal: false,
              selectedCodesArray: uniq([
                ...this.state.selectedSnomedCodesArray,
                ...this.state.newselectedCodesArray,
              ]).filter(
                (d) =>
                  d.id !== record.id || d.description !== record.description
              ),
              selectedSnomedCodesArray: uniq([
                ...this.state.selectedSnomedCodesArray,
                ...this.state.newselectedCodesArray,
              ]).filter(
                (d) =>
                  d.id !== record.id || d.description !== record.description
              ),
            },
            () => {
              let autoTriggerPayload = {
                snomed_code_data: this.state.selectedCodesArray,
              };

              this.handleAutoSaveConsultationTrigger(autoTriggerPayload);
              let {onSetDynamicInput} = this.props.dynamicTabAction;

              let Inputs = {
                ...this.props.dynamicInputs,
                newselectedCodesArray: this.state.newselectedCodesArray,
                selectedSnomedCodesArray: this.state.selectedCodesArray,
                selectedCodesArray: this.state.selectedCodesArray,
              };
              if (typeof onSetDynamicInput === "function") {
                onSetDynamicInput(Inputs);
              }
            }
          );
        }
      );
    }
  };
  _hideModal() {
    this.setState({
      visible: false,
      errors: null,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      editOutcome: false,
      changing: false,
      template_id: null,
    });
  }
  _showCodeModal() {
    this.setState({showCodeModal: true});
  }
  _hideCodeModal() {
    this.setState({showCodeModal: false});
  }

  _handleEdit(state) {
    if (state === "editPrescriptionPlan") {
      if (
        this.prescriptionModal &&
        this.prescriptionModal.current &&
        this.props.userRole !== ROLE_SUPERINTENDENT_PHARMACIST
      ) {
        this.prescriptionModal.current._handleShowModal();
      }
    } else {
      this.setState({[state]: !this.state[state]}, () => {
        if (this.state.editOutcome) {
          this.setState({visible: true});
        }
      });
    }
  }

  async _handleUpdateoutcome() {
    let {
      treatment,
      followUp,
      referral,
      comment,
      comment_other,
      enableOtherField,
      // consultationDetail: {
      //   consultation_log: {consultation_id},
      // },
    } = this.state;

    let toBeValidateObj2 = {
      treatment: treatment,
      followUp: followUp,
      referral: referral,
      comment: enableOtherField
        ? // &&
          // this.props.prev_appt_tab_key === "pre_consult_reviewed"
          comment_other
        : comment,
      visible: true,
    };

    const errors = ValidateInput(toBeValidateObj2, "fromPreConsult");

    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      this.setState({
        visible: false,
        errors: null,
        outcome: toBeValidateObj2,
      });
    }
    // try {
    //   this.setState({changing: true});
    //   let response = await updateConsultationDetailAPI(payload, true);
    //   if (response.data && response.data.message) {
    //     successToast({
    //       content: `Updated Outcome.`,
    //     });
    //     this._onRequestListPre();
    //     this.setState({
    //       ...this.state,
    //       changing: false,

    //       consultationDetail: response.data.data,
    //       // [state]: false,
    //     });
    //   }

    //   // this._getPatientConultationDetails();
    //   this._clearIndividualData("outcome", payload.outcome);
    // } catch (error) {
    //   const {message} = getErrorObject(error);
    //   this.setState({changing: false});
    //   errorToast({content: message});
    // }
  }
  async _handleSnomedCodeUpdate() {
    let {
      // selectedCodesArray,
      newselectedCodesArray,
      selectedSnomedCodesArray,
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;

    let payload = {
      snomed_codes: uniq([
        ...selectedSnomedCodesArray,
        ...newselectedCodesArray,
      ]),
      consultation_id: consultation_id,
    };
    const urlParams = new URLSearchParams(window.location.search);

    const pre_consult_id = urlParams.get("pre_consult_id")
      ? urlParams.get("pre_consult_id")
      : verifyObject(this.props.appointmentObj, "pre_consult_id", null);
    if (
      pre_consult_id &&
      pre_consult_id !== null &&
      pre_consult_id !== "null"
    ) {
      payload = {
        ...payload,
        from_pre_consultation: true,
      };
    }
    try {
      this.setState({updatingDetail: true});
      let response = await updateConsultationDetailAPI(payload);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated Snomed Codes.`,
        });
        this.setState({
          ...this.state,
          updatingDetail: false,
          consultationDetail: response.data.data,
          selectedSnomedCodesArray:
            verifyObject(response, "data.data.snomed_codes", []).length !== 0
              ? uniqBy(response.data.data.snomed_codes, "description")
              : [],
          newselectedCodesArray: [],
          // [state]: false,
        });
      }

      // this._getPatientConultationDetails();
      this._clearIndividualData("snomedCodeId", "editDiagnosis");
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  }
  handleDynamicCondition = (param, forPrescrptionPLan) => {
    let condition = forPrescrptionPLan
      ? param && param !== undefined && param !== ""
      : param &&
        param !== undefined &&
        param !== null &&
        param !== "" &&
        param.trim().length !== 0;
    return condition;
  };
  handleUpdateConsultation = async () => {
    let {
      consultationDetail: {
        consultation_log: {consultation_id},
      },
      pulse_per_minute,
      height,
      weight,
      blood_oxygen_saturation,
      body_temperature,
      systolic_bp,
      diastolic_bp,
      respiratory_rate,
      reason_text,

      managementPlan,
      examination,
      history,
      enableOtherField,
      comment_other,
      comment,
    } = this.state;

    let payload = {
      consultation_id: consultation_id,
    };
    const urlParams = new URLSearchParams(window.location.search);

    const pre_consult_id = urlParams.get("pre_consult_id")
      ? urlParams.get("pre_consult_id")
      : verifyObject(this.props.appointmentObj, "pre_consult_id", null);

    if (
      pre_consult_id &&
      pre_consult_id !== null &&
      pre_consult_id !== "null"
    ) {
      payload = {
        ...payload,
        from_pre_consultation: true,
      };
    }
    let consultation = {};
    if (this.state.history && this.handleDynamicCondition(this.state.history)) {
      consultation = {
        // consultation: {
        ...consultation,
        history,
        // },
      };
    }
    if (this.props.prev_appt_tab_key === "reviewed") {
      consultation = {
        ...consultation,
        from_review_section: true,
      };
    }

    if (
      this.state.examination &&
      this.handleDynamicCondition(this.state.examination)
    ) {
      consultation = {
        // consultation: {
        ...consultation,
        examination: isArray(examination) ? examination.join(",") : examination,
        // },
      };
    }
    if (
      this.state.managementPlan &&
      this.handleDynamicCondition(this.state.managementPlan)
    ) {
      consultation = {
        // consultation: {
        ...consultation,
        management_plan: managementPlan,
        // },
      };
    }
    if (
      this.state.prescription_plan &&
      this.handleDynamicCondition(this.state.prescription_plan)
    ) {
      if (
        verifyObject(
          this.state.consultationDetail,
          "consultation_log.prescription_plan"
        ) === null
      ) {
        consultation = {
          ...consultation,
          prescription_plan: this.state.prescription_plan,
        };
      } else if (
        this.state.prescription_plan.trim() !==
        verifyObject(
          this.state.consultationDetail,
          "consultation_log.prescription_plan",
          ""
        ).trim()
      ) {
        consultation = {
          ...consultation,
          prescription_plan: this.state.prescription_plan,
        };
      }
    }
    if (
      this.state.selectedCodesArray &&
      isArray(this.state.selectedCodesArray)
    ) {
      payload = {
        ...payload,
        snomed_codes: uniq([
          ...(this.state.selectedSnomedCodesArray || []),
          ...(this.state.selectedCodesArray || []),
        ]),
      };
    }
    if (this.state.outcome && !isEmpty(this.state.outcome)) {
      consultation = {
        ...consultation,
        outcome: {
          comment: enableOtherField
            ? // &&
              // this.props.prev_appt_tab_key === "pre_consult_reviewed"
              comment_other
            : comment,
          follow_up: this.state.followUp,
          referral: this.state.referral,
          treatment: this.state.treatment,
        },
      };
    }

    let health_summaryPayload = {
      health_summary: {},
    };
    if (pulse_per_minute && pulse_per_minute !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          pulse_per_minute,
        },
      };
    }

    if (systolic_bp && systolic_bp !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          systolic_bp,
        },
      };
    }
    if (diastolic_bp && diastolic_bp !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          diastolic_bp,
        },
      };
    }
    if (blood_oxygen_saturation && blood_oxygen_saturation !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          blood_oxygen_saturation,
        },
      };
    }
    if (body_temperature && body_temperature !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          body_temperature,
        },
      };
    }
    if (height && height !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          height,
        },
      };
    }
    if (weight && weight !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          weight,
        },
      };
    }

    if (respiratory_rate && respiratory_rate !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          respiratory_rate,
        },
      };
    }

    let healthPayload = {
      appointment_id: this.props.appointmentId,
      ...health_summaryPayload,
    };
    let reasonP = {};
    if (
      (this.props.dynamicInputs && this.props.dynamicInputs.reason_text) ||
      reason_text
    ) {
      reasonP = {
        reason: this.props.dynamicInputs.reason_text
          ? this.props.dynamicInputs.reason_text &&
            isArray(this.props.dynamicInputs.reason_text)
            ? this.props.reason_text.map((item) => item.label).join(",")
            : this.props.dynamicInputs.reason_text
          : reason_text,
      };
    }
    let payloadReason = {
      id: this.props.appointmentId,
      ...reasonP,
    };

    payload = {
      ...payload,
      consultation: {
        ...consultation,
        // appointment_id: this.props.appointmentId,
        // patient_id: this.props.patientId,
      },
    };

    // this.setState({errors: errors.errors, adding: false});
    if (
      (!history || (history && history.trim() === "")) &&
      (!examination || (examination && examination.trim() === "")) &&
      (!managementPlan || (managementPlan && managementPlan.trim() === ""))
    ) {
      this.setState({isFinshedEdit: true});
    } else {
      try {
        this.setState({updatingConsultationDetail: true});
        if (reason_text && !isEmpty(reason_text)) {
          let reasonResponse = await updateAppointmentReasonApi(payloadReason);

          successToast({
            content: verifyObject(reasonResponse, "data.message", "Success"),
          });
          if (
            healthPayload &&
            Object.keys(healthPayload.health_summary).length > 0
          ) {
            let healthResponse = await addHealthAssessmentPatientAPI(
              healthPayload
            );
            successToast({
              content: verifyObject(healthResponse, "data.message", "Success"),
            });
          }
          // payload = {
          //   ...payload,
          //   consultation: {
          //     ...consultation,
          //     // appointment_id: this.props.appointmentId,
          //     // patient_id: this.props.patientId,
          //   },
          // };

          let response = await updateConsultationDetailAPI(payload, true);

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
          if (
            typeof this.props.onSetDynamicHealthInput === "function" &&
            typeof this.props.onSetDynamicInput === "function"
          ) {
            await this.props.onSetDynamicHealthInput(null);
            await this.props.onSetDynamicInput(null);
            this.setState({updatingConsultationDetail: false});
          }
          this.handleClearUpdateDetails(response);

          store.dispatch({
            type: "SET_SYMPTOMP_FETCHED_LINKS",
            payload: null,
          });
        } else if (
          healthPayload &&
          Object.keys(healthPayload.health_summary).length > 0
        ) {
          let healthResponse = await addHealthAssessmentPatientAPI(
            healthPayload
          );
          successToast({
            content: verifyObject(healthResponse, "data.message", "Success"),
          });
          //# TODO: append text into examination here
          // payload = {
          //   ...payload,
          //   consultation: {
          //     ...consultation,
          //     // appointment_id: this.props.appointmentId,
          //     // patient_id: this.props.patientId,
          //   },
          // };

          let response = await updateConsultationDetailAPI(payload, true);

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
          if (
            typeof this.props.onSetDynamicHealthInput === "function" &&
            typeof this.props.onSetDynamicInput === "function"
          ) {
            await this.props.onSetDynamicHealthInput(null);
            await this.props.onSetDynamicInput(null);
            this.setState({updatingConsultationDetail: false});
          }
          this.handleClearUpdateDetails(response);

          store.dispatch({
            type: "SET_SYMPTOMP_FETCHED_LINKS",
            payload: null,
          });
        } else {
          // payload = {
          //   ...payload,
          //   consultation: {
          //     ...consultation,
          //     // appointment_id: this.props.appointmentId,
          //     // patient_id: this.props.patientId,
          //   },
          // };
          let response = await updateConsultationDetailAPI(payload, true);

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
          if (
            typeof this.props.onSetDynamicHealthInput === "function" &&
            typeof this.props.onSetDynamicInput === "function"
          ) {
            await this.props.onSetDynamicHealthInput(null);
            await this.props.onSetDynamicInput(null);
            this.setState({updatingConsultationDetail: false});
          }
          this.handleClearUpdateDetails(response);

          store.dispatch({
            type: "SET_SYMPTOMP_FETCHED_LINKS",
            payload: null,
          });
        }
        this.setState({updatingConsultationDetail: false});
      } catch (error) {
        const {message} = getErrorObject(error);
        this.setState({updatingConsultationDetail: false});

        errorToast({content: message});
      }
    }
  };
  async _handleUpdate(param, value) {
    let {
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;
    let payload = {
      consultation: {
        [param]: this.state[param],
      },
      consultation_id: consultation_id,
    };

    // if (param === "barcode") {
    //   payload = {
    //     consultation: {
    //       barcode: this.state.barcodeNumber,
    //     },
    //     consultation_id: consultation_id,
    //   };
    // }

    if (param === "managementPlan") {
      payload = {
        consultation: {
          management_plan: this.state.managementPlan,
        },
        consultation_id: consultation_id,
      };
    }
    if (param === "prescription_plan") {
      payload = {
        consultation: {
          prescription_plan: value,
        },
        consultation_id: consultation_id,
      };
    }

    try {
      this.setState({[`updating${param}`]: true});
      let response;
      if (param === "prescription_plan" && !value) {
      } else {
        const urlParams = new URLSearchParams(window.location.search);

        const pre_consult_id = urlParams.get("pre_consult_id")
          ? urlParams.get("pre_consult_id")
          : verifyObject(this.props.appointmentObj, "pre_consult_id", null);
        if (
          pre_consult_id &&
          pre_consult_id !== null &&
          pre_consult_id !== "null"
        ) {
          payload = {
            ...payload,
            from_pre_consultation: true,
          };
        }
        response = await updateConsultationDetailAPI(payload, true);
        if (response.data && response.data.message) {
          successToast({
            content: `Updated Consultation Record.`,
          });
          this._onRequestListPre();

          // this._getPatientConultationDetails();
          this.setState({
            ...this.state,
            [`updating${param}`]: false,

            consultationDetail: verifyObject(response, "data.data", null),
            // [state]: false,
          });
        }
      }

      // this._getPatientConultationDetails();
      this._clearIndividualData(param, value); //creating new function for individual fields save and clear
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({[`updating${param}`]: false});
      errorToast({content: message});
    }
  }
  _clearIndividualData = (param, value) => {
    if (param === "outcome") {
      return this.setState(
        {
          treatment: "",
          followUp: "",
          referral: "",
          comment: "",
          visible: false,
        },
        () => {
          store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: false});
          store.dispatch({
            type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
            payload: null,
          });
        }
      );
    } else {
      return this.setState(
        {
          [param]: "",
          [value]: false,
        },
        () => {
          // if (param === "barcode") {
          //   this.setState({barcodeNumber: ""});
          // }
          store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: false});
          store.dispatch({
            type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
            payload: null,
          });
        }
      );
    }
  };
  updateHeight = () => {
    let activePane = document.querySelector(".ant-tabs-tabpane-active");
    let sectionSCroll = activePane.querySelector(".sectionsubscroll");
    let posTop = activePane.getBoundingClientRect().top;
    setTimeout(() => {
      sectionSCroll.style.height = `calc(100vh - ${posTop + 35}px)`;
    }, 500);
  };
  handleButtonClick = () => {
    this.setState(
      (prevState) => ({
        splitSize: !prevState.splitSize,
      }),
      () => {
        // Call updateHeight in the callback to ensure it runs after the state has been updated
        this.updateHeight();
      }
    );
  };
  handleTabChange = (key) => {
    return this.setState({tabKey: key}, () => {
      store.dispatch({type: "CONSULTATION_TAB_KEY", payload: key});
      setTimeout(() => {
        this.updateHeight();
      }, 500);
    });
  };
  _markAsReviewed = async () => {
    try {
      this.setState({isReviewing: true});
      let res = await markAsReviewedAPI({
        appointment_id: this.props.appointmentId,
      });

      if (res) {
        this.setState({isReviewing: false});
      }
    } catch (e) {
      let {message} = getErrorObject(e);
      errorToast({content: message});
      this.setState({isReviewing: false});
    }
  };

  // componentWillReceiveProps(nextProps) {
  //   let {dynamicInputs} = nextProps;

  //   this.setState({
  //     prescription_plan: dynamicInputs && dynamicInputs.presriptionPlan,
  //   });
  // }
  handleSaveSnomedCode = async () => {
    return this._handleSnomedCodeUpdate();
  };
  _onRequestListPre() {
    let {patientId, preEncountersActions} = this.props;
    let {onRequest} = preEncountersActions;
    if (typeof onRequest === "function") {
      onRequest({
        patient_id: patientId ? patientId : this.props.patient_user_id,
        page: 1,
        document_type: "Prescription",
      });
    }
  }
  fetchRegions = async (fromAutoSaveEvent) => {
    try {
      this.setState({fetchingRegion: true});
      const response = await fetchRegionsAPI();
      let {dynamicInputs, dynamicHealthInputs, is_gp_informed} = this.props;
      let consentArray =
        is_gp_informed &&
        verifyObject(dynamicInputs, "isConsentGiven", []).length === 0
          ? JSON.parse(localStorage.getItem("isConsentGiven"))
            ? JSON.parse(localStorage.getItem("isConsentGiven"))
            : []
          : verifyObject(dynamicInputs, "isConsentGiven", []);
      let managementPlanLocal =
        is_gp_informed &&
        verifyObject(dynamicInputs, "managementPlan", "") === ""
          ? +consentArray[0].code === 768321000000106
            ? "Patient made aware of the importance of good medical record sharing, Consent given"
            : "Patient made aware of the importance of good medical record sharing, declined"
          : verifyObject(dynamicInputs, "managementPlan", "");

      if (fromAutoSaveEvent) {
        this.setState(
          {
            fetchingRegion: false,
            regionsData: verifyObject(response, "data.data", []),
            // ...dynamicInputs,
            // ...dynamicHealthInputs,
            // tabKey:
            //   this.props.consultation_tab_key !== null
            //     ? this.props.consultation_tab_key
            //     : "preencounters",
            // managementPlan: managementPlanLocal,
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;

            if (typeof onSetDynamicInput === "function") {
              let payload = {
                ...dynamicInputs,
                regionsData: this.state.regionsData,
                reason_text: this.state.reason_text,
              };
              onSetDynamicInput(payload);
            }
            let foundValueCheckbox = checkboxArray.find(
              (item) => item.value !== null
            );
            let foundValueHealthbox = Health_Assessment_KVP.find(
              (item) => item.value !== null
            );

            if (this.state.examination === "" && foundValueCheckbox) {
              this.handleClearCheckboxValue();
            }
            if (this.state.examination === "" && foundValueHealthbox) {
              this.handleClearHealthboxValue();
            }
          }
        );
      } else {
        this.setState(
          {
            fetchingRegion: false,
            regionsData: verifyObject(response, "data.data", []),
            ...dynamicInputs,
            ...dynamicHealthInputs,
            tabKey:
              this.props.consultation_tab_key !== null
                ? this.props.consultation_tab_key
                : "preencounters",
            managementPlan: managementPlanLocal,
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;

            if (typeof onSetDynamicInput === "function") {
              let payload = {
                ...dynamicInputs,
                regionsData: this.state.regionsData,
              };
              onSetDynamicInput(payload);
            }
            let foundValueCheckbox = checkboxArray.find(
              (item) => item.value !== null
            );
            let foundValueHealthbox = Health_Assessment_KVP.find(
              (item) => item.value !== null
            );

            if (this.state.examination === "" && foundValueCheckbox) {
              this.handleClearCheckboxValue();
            }
            if (this.state.examination === "" && foundValueHealthbox) {
              this.handleClearHealthboxValue();
            }
          }
        );
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({
        fetchingRegion: false,
      });
      errorToast({content: message});
    }
  };
  handleClearCheckboxValue = () => {
    this.setState((state) => {
      let stateItems = state.checkboxStateArray;
      stateItems.map((item, index) => {
        return (stateItems[index]["value"] = null);
      });

      return {
        checkboxStateArray: stateItems,
      };
    });
  };

  handleClearHealthboxValue = () => {
    this.setState((state) => {
      let stateItems = state.healthStateArray;
      stateItems.map((item, index) => {
        return (stateItems[index]["value"] = null);
      });

      return {
        healthStateArray: stateItems,
      };
    });
  };
  renderInputColor = (value, field) => {
    if (value && !isEmpty(value.toString())) {
      // if (_bp < 60) return "#0872FB"; //!LOW
      // if (_bp > 60 && _bp <= 80) return "#16B250"; //!NORMAL
      // if (_bp > 80 && _bp <= 90) return "#D4CA01"; //!AT RISK
      // if (_bp > 90 && _bp <= 100) return "#F48918"; //!HIGH
      // if (_bp > 100 && _bp <= 110) return "#ED1E24"; //!VERY HIGH
    } //!SEVERE

    switch (field) {
      case "diastolic_bp":
        if (value && value < 60) {
          return "ha-lowBP text-white custom-input-number";
        } else if (value >= 60 && value < 80) {
          return "ha-normalBP text-white custom-input-number";
        } else if (value >= 80 && value < 90) {
          return "ha-preHyperBP text-white custom-input-number";
        } else if (value >= 90 && value < 100) {
          return "ha-stage1BP text-white custom-input-number";
        } else if (value >= 100 && value < 120) {
          return "ha-stage2BP text-white custom-input-number";
        } else if (value >= 120) {
          return "ha-stage3BP text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      case "systolic_bp":
        if (value && value >= 70 && value < 90) {
          return "ha-lowBP text-white custom-input-number";
        } else if (value >= 90 && value < 120) {
          return "ha-normalBP text-white custom-input-number";
        } else if (value >= 120 && value < 140) {
          return "ha-preHyperBP text-white custom-input-number";
        } else if (value >= 140 && value < 160) {
          return "ha-stage1BP text-white custom-input-number";
        } else if (value >= 160 && value < 180) {
          return "ha-stage2BP text-white custom-input-number";
        } else if (value >= 180) {
          return "ha-stage3BP text-white custom-input-number";
        } else {
          return "custom-input-number";
        }

      case "pulse_per_minute":
        if (value && value >= 40 && value < 59) {
          return "bg-primary text-white custom-input-number";
        } else if (value >= 60 && value < 100) {
          return "bg-success text-white custom-input-number";
        } else if (value > 100) {
          return "bg-danger text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      case "body_temperature":
        if (value && value < 36) {
          return "bg-primary text-white custom-input-number";
        } else if (value >= 36.1 && value < 37.2) {
          return "bg-success text-white custom-input-number";
        } else if (value > 37.2) {
          return "bg-danger text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      case "blood_oxygen_saturation":
        if (value && value >= 1 && value <= 95) {
          return "bg-primary text-white custom-input-number";
        } else if (value >= 96 && value < 100) {
          return "bg-success text-white custom-input-number";
        } else if (value > 100) {
          return "bg-danger text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      default:
        return "custom-input-number";
    }
  };
  handleOnChange = (value, key, name) => {
    if (name === "Pregnancy") {
      this.setState({[key]: value.target.value});
    } else {
      this.setState({[key]: value});
    }
  };
  hanleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      this.setState(
        (prevState) => ({
          enterCount: prevState.enterCount + 1,
        }),
        async () => {
          if (this.state.enterCount === 2) {
            if (this.props.gender !== "female") {
              await this.handleGetDiagnosis();
            } else {
              await this.handleGetDiagnosisFemale();
            }
            // Call your function here
            // this.callYourFunction();
            // Reset the count
            this.setState({
              enterCount: 0,
            });
          }
        }
      );
    }
  };
  async _handleSymptomSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          searchSymptom: value,
        },
        async () => {
          await this.onGetSymptoms(value);
        }
      );
    } else {
    }
  }
  onGetSymptoms = async (value) => {
    try {
      this.setState({searchingSymptom: true});

      let response = await fetchSymptomsApi({
        search: this.state.searchSymptom,
      });

      let newSymptomsData = verifyObject(response, "data.data", []);
      if (response.data.data) {
        this.setState(
          {
            symptomsData: newSymptomsData.map((item) => {
              return {value: item.id, label: item.name};
            }),
            searchingSymptom: false,
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;
            let {dynamicInputs} = this.props;

            if (typeof onSetDynamicInput === "function") {
              let payload = {
                ...dynamicInputs,
                symptomsData: this.state.symptomsData,
              };
              onSetDynamicInput(payload);
            }
          }
        );
      }
      this.setState({searchingSymptom: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searchingSymptom: false});
      errorToast({content: message});
    }
  };
  handleGetDiagnosis = async () => {
    if (
      isArray(this.state.reason_text) &&
      this.state.reason_text.length !== 0
    ) {
      let findName = this.state.reason_text.map((item) => item.label).join(",");
      if (findName) {
        let payload = {
          patient_id: this.props.patientId
            ? this.props.patientId
            : verifyObject(this.props.patientDetail, "user.id"),
          search: findName,
          symptom_id: this.state.reason_text
            .map((item) => {
              if (isNumber(item.value)) return item.value;
            })
            .join(","),
          is_pregnant: "",
          region_id: this.state.region_id, //default region id western europe is 1
        };
        if (this.state.region_id && this.state.recentTravel) {
          payload = {
            ...payload,
            region_id: this.state.region_id,
          };
        }

        try {
          await this.setState(
            {
              searchingDiagnose: true,
              diagnosisData: [],
              selectedCodesArray: this.state.selectedCodesArray.filter(
                (item1) => {
                  return !this.state.symptompSnomedCode.some(
                    (item2) => +item2.code === +item1.code
                  );
                }
              ),
              symptompSnomedCode: [],
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  selectedCodesArray: this.state.selectedCodesArray,
                  symptompSnomedCode: this.state.symptompSnomedCode,
                };
                onSetDynamicInput(payload);
              }

              store.dispatch({
                type: "SET_SYMPTOMP_FETCHED_LINKS",
                payload: null,
              });
            }
          );

          let response = await fetchDiagnosisApi(payload);

          // next();
          if (response.data.data) {
            this.setState(
              {
                diagnosisData: response.data.data,
                searchingDiagnose: false,

                // reason_text: this.state.reason_text,
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;
                let {dynamicInputs} = this.props;

                if (typeof onSetDynamicInput === "function") {
                  let payload = {
                    ...dynamicInputs,
                    reason_text: this.state.reason_text,
                    reason_text_string: findName,
                    // region_id: this.state.region_id,
                    // recentTravel: this.state.recentTravel,
                    diagnosisData: this.state.diagnosisData,
                  };
                  onSetDynamicInput(payload);
                }
              }
            );
          }

          await this.setState(
            {
              searchingDiagnose: false,
              // reason_text: findName.map((item) => item.name).join(","),
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  reason_text: this.state.reason_text,
                  reason_text_string: findName,

                  diagnosisData: this.state.diagnosisData,
                };
                onSetDynamicInput(payload);
              }
            }
          );
        } catch (error) {
          const {message} = getErrorObject(error);
          await this.setState({searchingDiagnose: false});
          errorToast({content: message});
        }
      }
    }
  };
  handleGetDiagnosisFemale = async () => {
    if (
      isArray(this.state.reason_text) &&
      this.state.reason_text.length !== 0
    ) {
      let findName = this.state.reason_text.map((item) => item.label).join(",");
      if (findName) {
        // setFindedName(findName.map((item) => item.name).join(","));

        let payload = {
          patient_id: this.props.patientId,
          search: findName,
          symptom_id: this.state.reason_text
            .map((item) => {
              if (isNumber(item.value)) return item.value;
            })
            .join(","),
          is_pregnant: this.state.is_pregnant,
          region_id: this.state.region_id,
        };
        if (this.state.region_id && this.state.recentTravel) {
          payload = {
            ...payload,
            region_id: this.state.region_id,
          };
        }

        try {
          await this.setState(
            {
              searchingDiagnose: true,
              diagnosisData: [],
              selectedCodesArray: this.state.selectedCodesArray.filter(
                (item1) => {
                  return !this.state.symptompSnomedCode.some(
                    (item2) => +item2.code === +item1.code
                  );
                }
              ),
              symptompSnomedCode: [],
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  selectedCodesArray: this.state.selectedCodesArray,
                  symptompSnomedCode: this.state.symptompSnomedCode,
                };
                onSetDynamicInput(payload);
              }
              store.dispatch({
                type: "SET_SYMPTOMP_FETCHED_LINKS",
                payload: null,
              });
            }
          );

          let response = await fetchDiagnosisApi(payload);

          if (response.data.data) {
            this.setState(
              {
                diagnosisData: response.data.data,
                searchingDiagnose: false,

                // reason_text: findName.map((item) => item.name).join(","),
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;
                let {dynamicInputs} = this.props;

                if (typeof onSetDynamicInput === "function") {
                  let payload = {
                    ...dynamicInputs,
                    reason_text: this.state.reason_text,
                    reason_text_string: findName,

                    diagnosisData: this.state.diagnosisData,
                  };
                  onSetDynamicInput(payload);
                }
              }
            );
          }

          await this.setState(
            {
              searchingDiagnose: false,
              // reason_text: findName.map((item) => item.name).join(","),
              getDiagnosisModal: false,
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  reason_text: this.state.reason_text,
                  reason_text_string: findName,

                  diagnosisData: this.state.diagnosisData,
                };
                onSetDynamicInput(payload);
              }
            }
          );
        } catch (error) {
          const {message} = getErrorObject(error);
          await this.setState({searchingDiagnose: false});
          errorToast({content: message});
        }
      }
    }
  };
  handleClear = () => {
    let reasonStringArray = (this.state.reason_text || []).map(
      (item) => item.label || ""
    );
    let removeHistoryStringArray = this.state.history
      .split("\n")
      .filter((item1) => {
        return !reasonStringArray.some((item2) => item2 === item1);
      });

    return this.setState(
      {
        reason_text: [],
        diagnosisData: {},
        history: removeHistoryStringArray.join("\n"),
        region_id: 1,
        recentTravel: false,
        selectedCodesArray: this.state.selectedCodesArray.filter((item1) => {
          return ![
            ...(this.state.symptompSnomedCode || []),
            ...(process.env.REACT_APP === "prod"
              ? DP_QUESTIONNAIRE_LIVE
              : DP_QUESTIONNAIRE),
          ].some(
            (item2) =>
              +item2.id === +item1.id || +item2.snomed_code_id === +item1.id
          );
        }),
        symptompSnomedCode: [],
      },
      () => {
        let autoTriggerPayload = {
          reason:
            (this.state.reason_text || []).length > 0
              ? JSON.stringify(this.state.reason_text)
              : // .map((item) => item.label).join(",")
                null,
          history: this.state.history,
          snomed_code_data: this.state.selectedCodesArray,
        };

        this.handleAutoSaveConsultationTrigger(autoTriggerPayload);
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        let {onSetDynamicInput} = this.props.dynamicTabAction;
        let {dynamicInputs} = this.props;

        if (typeof onSetDynamicInput === "function") {
          let payload = {
            ...dynamicInputs,
            reason_text: this.state.reason_text,
            history: this.state.history,
            reason_text_string: "",
            recentTravel: this.state.recentTravel,
            region_id: this.state.region_id,
            diagnosisData: this.state.diagnosisData,
            selectedCodesArray: this.state.selectedCodesArray,
            selectedSnomedCodesArray: this.state.selectedCodesArray,
            symptompSnomedCode: this.state.symptompSnomedCode,
          };
          onSetDynamicInput(payload);
        }
      }
    );
  };
  customAbbrivationChange = (e) => {
    this.setState(
      {
        isCustomAbbrevation: true,
        reason_text: [
          ...this.state.reason_text,
          {
            value: e,
            label: e,
          },
        ],
        symptomsData: [
          ...this.state.symptomsData,
          {
            value: e,
            label: e,
          },
        ],
      },
      () => {
        let autoTriggerPayload = {
          reason:
            (this.state.reason_text || []).length > 0
              ? JSON.stringify(this.state.reason_text)
              : null,
        };

        this.handleAutoSaveConsultationTrigger(autoTriggerPayload);
        this.setState({custom_text: ""});
        let {onSetDynamicInput} = this.props.dynamicTabAction;
        let {dynamicInputs} = this.props;

        if (typeof onSetDynamicInput === "function") {
          let payload = {
            ...dynamicInputs,
            reason_text: this.state.reason_text,
            reason_text_string: "",
            symptomsData: this.state.symptomsData,
            diagnosisData: this.state.diagnosisData,
          };
          onSetDynamicInput(payload);
        }
      }
    );
  };
  // showPresentingInfoModal = () => {
  //   this.setState({ getPresentingInfoModal: true });
  // };
  showpresentingModal = () => {
    this.setState({getPresentingComModal: true});
  };
  handleOk = () => {
    this.setState({getPresentingComModal: false});
  };
  handleCancel = () => {
    this.setState({getPresentingComModal: false});
    // this.setState({ getPresentingInfoModal: false });
  };
  handleFormater = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
    return value.toString().slice(0, 5); // Restrict to 3 digits
  };

  handleParser = (value, allowedNegativeValue) => {
    // Remove non-digit and non-dot characters
    const cleanValue = value.replace(/[^\d.-]/g, "");

    // Split the value into integer and decimal parts
    let [integerPart, decimalPart] = cleanValue.split(".");

    // Check if the value is negative
    const isNegative = cleanValue.startsWith("-");

    // Disallow negative values if allowedNegativeValue is false
    if (!allowedNegativeValue && isNegative) {
      return "";
    }

    // Keep only the first 3 digits in the integer part
    const truncatedIntegerPart = integerPart.slice(0, 3);

    // Ensure decimalPart is at most 2 digits
    const truncatedDecimalPart = decimalPart ? decimalPart.slice(0, 2) : "";

    // Combine the truncated parts back into a valid number format
    const truncatedValue = decimalPart
      ? `${truncatedIntegerPart}.${truncatedDecimalPart}`
      : truncatedIntegerPart;

    // Add back the negative sign if it was initially present
    return truncatedValue;
  };

  scrollToBottomForTextArea = (id) => {
    let historyTextArea = document.getElementById(id);
    if (historyTextArea) {
      historyTextArea.scrollTop = historyTextArea.scrollHeight;
    }
  };
  handleKeyUp(event) {
    //key code for enter
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }
  //function for remove multiple codes in diagnosis as same
  removeDuplicates = (stateArray) => {
    const uniqueItems = {};
    const newArray = [];

    if (stateArray) {
      for (const item of stateArray) {
        const key = item.id || item.code;

        if (key !== undefined && key !== null && !uniqueItems[key]) {
          uniqueItems[key] = true;
          newArray.push(item);
        }
      }
    }

    return newArray;
  };
  //function for filter symtom checker or questionnaire reason only in presenting complaint
  filterUniqueItems = (array1, array2) => {
    const uniqueItems = array1.filter((item2) => {
      let foundSnomedCode = array2.find((item) => item.id === item2.value);

      if (foundSnomedCode) {
        this.setState(
          {
            selectedCodesArray: this.state.selectedCodesArray.filter(
              (item) => +item.id !== +foundSnomedCode.snomed_code_id
            ),
            reason_text: !array2.some((item1) => item1.id === item2.value),
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;
            let {dynamicInputs} = this.props;

            if (typeof onSetDynamicInput === "function") {
              let payload = {
                ...dynamicInputs,
                reason_text: this.state.reason_text,

                selectedCodesArray: this.state.selectedCodesArray,
                selectedSnomedCodesArray: this.state.selectedCodesArray,
              };
              onSetDynamicInput(payload);
            }
          }
        );
      }

      return !array2.some((item1) => item1.id === item2.value);
    });

    return uniqueItems;
  };
  onquestionChange = (e) => {
    this.setState({recentTravel: e.target.checked}, () => {
      // if (this.state.recentTravel) {
      let {onSetDynamicInput} = this.props.dynamicTabAction;
      let {dynamicInputs} = this.props;

      if (typeof onSetDynamicInput === "function") {
        let payload = {
          ...dynamicInputs,
          recentTravel: this.state.recentTravel,
        };
        onSetDynamicInput(payload);
      }
      // }
    });
  };
  validateDate = (dateString) => {
    // Check if the input matches the "DD/MM/YYYY" format
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!regex.test(dateString)) {
      return false; // Doesn't match the expected format
    }

    // Split the date string into day, month, and year
    const [day, month, year] = dateString.split("/").map(Number);

    // Check if the month is valid (between 01 and 12)
    if (month < 1 || month > 12) {
      return false;
    }

    // Check if the day is valid based on the month (e.g., 31 days in December)
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    // Check if the year is a valid positive number (you can add additional validation here)
    if (year < 1 || year > new Date().getFullYear()) {
      return false;
    }

    return true; // Passed all validation checks
  };

  /**
   * Handles the blur event for a checkbox item.
   * @param {object} item - The checkbox item.
   * @param {number} index - The index of the checkbox item in the array.
   */
  handleBlur = (item, index, isPregnancy) => {
    if (item.value) {
      if (
        +item.id === 611741 &&
        (item.value === "__/__/____" || !this.validateDate(item.value))
      ) {
        this._handleRemoveCheckboxStateArray(item.id, index);
      } else {
        if (item.value === "__/__/____") {
          this._handleRemoveCheckboxStateArray(item.id, index);
        } else {
          this._handleCheckboxStateArray(item.id, item.value);
        }
      }
    } else {
      this._handleRemoveCheckboxStateArray(item.id, index);
    }
  };

  _handleCheckboxStateArray = (key, val) => {
    if (val === "__/__/____") {
    } else {
      this.setState(
        (prevState) => {
          let newExamination = prevState.examination;
          let newSelectedCodesArray = [
            ...(prevState.newselectedCodesArray || []),
          ];

          let found = prevState.checkboxStateArray.find((i) => i.id === key);

          if (found) {
            let newText;

            if (typeof prevState.examination === "string") {
              newText = prevState.examination.split("\n");
            } else if (isArray(prevState.examination)) {
              newText = prevState.examination;
            } else {
              newText = []; // Default value if examination is neither a string nor an array
            }
            let newExaminationItem = newText.find((item) =>
              item.includes(found.long_text)
            );

            if (newExaminationItem) {
              newExamination = prevState.examination
                ? prevState.examination
                    .split("\n")
                    .filter((item) => !item.includes(newExaminationItem))
                    .map((item) => `${item.replace(/,$/, "")}`)
                : "";
              newExamination = [
                ...newExamination,
                `${found.long_text} [${val}]`,
              ];
            } else {
              newExamination =
                prevState.examination !== "" && prevState.examination
                  ? // &&
                    // prevState.examination.trim().length !== 0
                    // eslint-disable-next-line no-useless-concat
                    prevState.examination + "\n" + `${found.long_text}[${val}]`
                  : `${found.long_text} [${val}]`;
            }

            newSelectedCodesArray.push({
              code: found.snomed_code,
              description: found.snomed_code_description,
              id: found.id,
            });
          }

          return {
            examination: isArray(newExamination)
              ? newExamination
                  .filter(
                    (element) =>
                      element !== "undefined" || element !== undefined
                  )
                  .join("\n")
              : newExamination,
            newselectedCodesArray: this.removeDuplicates([
              ...prevState.newselectedCodesArray,
              ...newSelectedCodesArray,
            ]),
            // selectedCodesArray: [
            //   ...prevState.newselectedCodesArray,
            //   ...newSelectedCodesArray,

            // ],
            // selectedSnomedCodesArray: [
            //   ...prevState.newselectedCodesArray,
            //   ...newSelectedCodesArray,

            // ],
          };
        },
        () => {
          let {selectedCodesArray} = this.state;
          let removedDuplicatesArray =
            this.removeDuplicates(selectedCodesArray);

          this.setState(
            {
              newselectedCodesArray: this.removeDuplicates(
                this.state.newselectedCodesArray || []
              ),
              selectedCodesArray: [
                ...(removedDuplicatesArray || []),
                ...(this.state.newselectedCodesArray || []),
              ],
              selectedSnomedCodesArray: [
                ...(removedDuplicatesArray || []),
                ...(this.state.newselectedCodesArray || []),
              ],
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  examination: this.state.examination,
                  newselectedCodesArray: this.state.newselectedCodesArray,
                  selectedCodesArray: this.state.selectedCodesArray,
                  selectedSnomedCodesArray: this.state.selectedCodesArray,
                };
                onSetDynamicInput(payload);
              }
            }
          );
          this.scrollToBottomForTextArea("examination_textarea");
        }
      );
    }
  };

  _handleRemoveCheckboxStateArray = (key, index) => {
    let newText = this.state.examination
      ? isArray(this.state.examination)
        ? this.state.examination
        : this.state.examination.split("\n")
      : [];
    let removingItem = this.state.checkboxStateArray.find((i) => i.id === key);
    let newExamination =
      newText.length !== 0
        ? newText.filter((item) => !item.includes(removingItem.long_text))
        : [];

    this.setState(
      (state) => {
        let stateItems = state.checkboxStateArray;
        stateItems[index]["value"] = "";
        return {
          checkboxStateArray: stateItems,
        };
      },
      () => {
        // Scrolling to the bottom of the textarea
        this.setState(
          {
            examination: newExamination.join("\n"),
          },
          () => {
            if (this.state.newselectedCodesArray.length === 0) {
            } else {
              this.setState(
                {
                  newselectedCodesArray:
                    this.state.newselectedCodesArray.filter(
                      (d) => d.id !== key
                    ),
                },
                () => {
                  this.setState(
                    {
                      showCodeModal: false,
                      selectedCodesArray: uniq([
                        ...this.state.selectedSnomedCodesArray,
                        ...this.state.newselectedCodesArray,
                      ]).filter((d) => d.id !== key),
                      selectedSnomedCodesArray: uniq([
                        ...this.state.selectedSnomedCodesArray,
                        ...this.state.newselectedCodesArray,
                      ]).filter((d) => d.id !== key),
                    },
                    () => {
                      if (removingItem.value && removingItem.value !== "") {
                        let autoTriggerPayload = {
                          examination: this.state.examination,
                          snomed_code_data: this.state.selectedSnomedCodesArray,
                        };

                        this.handleAutoSaveConsultationTrigger(
                          autoTriggerPayload
                        );
                      }
                      let {onSetDynamicInput} = this.props.dynamicTabAction;

                      let Inputs = {
                        ...this.props.dynamicInputs,
                        newselectedCodesArray: this.state.newselectedCodesArray,
                        selectedSnomedCodesArray: this.state.selectedCodesArray,
                        selectedCodesArray: this.state.selectedCodesArray,
                      };
                      if (typeof onSetDynamicInput === "function") {
                        onSetDynamicInput(Inputs);
                      }
                    }
                  );
                }
              );
            }
          }
        );
      }
    );
  };
  handleBlurHA = (item, index) => {
    if (item.value) {
      this._handleHealthStateArray(item.id, item.value);
    } else {
      const triggerAutoSave = item && item.value !== "" && item.value !== null; //passing for handle autosave trigger
      this._handleRemoveHealthStateArray(item.id, index, triggerAutoSave);
    }
  };
  _handleHealthStateArray = (key, val) => {
    this.setState(
      (prevState) => {
        let newExamination = prevState.examination ? prevState.examination : "";

        let found = prevState.healthStateArray.find((i) => i.id === key);

        if (found) {
          let newText;

          if (typeof prevState.examination === "string") {
            newText = prevState.examination.split("\n");
          } else if (isArray(prevState.examination)) {
            newText = prevState.examination;
          } else {
            newText = []; // Default value if examination is neither a string nor an array
          }
          let newExaminationItem = newText.find((item) =>
            item.includes(found.label)
          );

          if (newExaminationItem) {
            newExamination = isArray(prevState.examination)
              ? prevState.examination
                  .filter((item) => !item.includes(newExaminationItem))
                  .map((item) => `${item.replace(/,$/, "")}`)
              : prevState.examination
                  .split("\n")
                  .filter((item) => !item.includes(newExaminationItem))
                  .map((item) => `${item.replace(/,$/, "")}`);

            newExamination = [
              ...newExamination,
              `${found.label}: ${val} ${found.unit}`,
            ];
          } else {
            newExamination =
              prevState.examination !== "" && prevState.examination
                ? // &&
                  // prevState.examination.trim().length !== 0
                  prevState.examination +
                  "\n" +
                  `${found.label}: ${val} ${found.unit}`
                : `${found.label}: ${val} ${found.unit}`;
          }
        }

        return {
          examination: isArray(newExamination)
            ? this.rearrangeHealthData(newExamination.join("\n"))
            : this.rearrangeHealthData(newExamination),

          // selectedSnomedCodesArray: this.state.selectedCodesArray,
        };
      },
      () => {
        // let autoTriggerPayload = {
        //   examination: this.state.examination,
        // };
        // console.log("MB CHECK 8-------", autoTriggerPayload);

        // if (!this.state.isTriggeredAutoSave) {
        //   this.handleAutoSaveConsultationTrigger(autoTriggerPayload);
        // }
        let {onSetDynamicInput} = this.props.dynamicTabAction;
        let {dynamicInputs} = this.props;

        if (typeof onSetDynamicInput === "function") {
          let payload = {
            ...dynamicInputs,
            examination: this.state.examination,
            // selectedCodesArray: this.state.selectedCodesArray,
            // selectedSnomedCodesArray: this.state.selectedCodesArray,
          };
          onSetDynamicInput(payload);
        }

        this.scrollToBottomForTextArea("examination_textarea");
      }
    );
  };
  rearrangeHealthData = (data) => {
    let lines;

    // Convert to array if the input is a string
    if (typeof data === "string") {
      lines = data.split("\n");
    } else if (Array.isArray(data)) {
      lines = data;
    } else {
      console.error("Error: Invalid input format. Expected string or array.");
      return data;
    }

    // Define the order in which lines should appear
    const desiredOrder = [
      "Pulse",
      "SpO2",
      "Height",
      "Weight",
      "BMI",
      "Temperature",
      "Systolic",
      "Diastolic",
      "Respiratory rate",
    ];

    // Sort lines based on their order in desiredOrder
    lines.sort((a, b) => {
      let indexA = desiredOrder.indexOf(a.split(":")[0].trim());
      let indexB = desiredOrder.indexOf(b.split(":")[0].trim());
      return indexA - indexB;
    });

    // Convert back to string if the input was a string
    if (typeof data === "string") {
      return lines.join("\n");
    } else {
      return lines;
    }
  };
  _handleRemoveHealthStateArray = (key, index, triggerAutoSave) => {
    let newText = isArray(this.state.examination)
      ? this.state.examination
      : this.state.examination
      ? this.state.examination.split("\n")
      : [];
    let removingItem = this.state.healthStateArray.find((i) => i.id === index);

    let newExamination = newText.filter(
      (item) => !item.includes(removingItem.label)
    );

    this.setState(
      (state) => {
        let stateItems = state.healthStateArray;
        stateItems[index]["value"] = "";
        return {
          healthStateArray: stateItems,
        };
      },
      () => {
        // Scrolling to the bottom of the textarea
        this.setState(
          {
            examination: this.rearrangeHealthData(newExamination.join("\n")),
            // selectedSnomedCodesArray: this.state.selectedCodesArray,
          },
          () => {
            let autoTriggerPayload = {
              examination: this.state.examination,
            };

            if (newExamination.length !== 0 && triggerAutoSave) {
              this.handleAutoSaveConsultationTrigger(autoTriggerPayload);
            }
          }
        );
      }
    );
  };
  handleHealthTextChange = (e, name) => {
    this.setState({[name]: e}, () => {
      let {
        pulse_per_minute,
        height,
        weight,
        blood_oxygen_saturation,
        body_temperature,
        systolic_bp,
        diastolic_bp,
        respiratory_rate,
        bmi,
      } = this.state;
      if (
        height &&
        height !== "" &&
        weight &&
        weight !== "" &&
        (!bmi || bmi === "" || bmi)
      ) {
        this.setState({bmi: this.calculateBMI(height, weight)}, () => {
          this.setState(
            (state) => {
              let stateItems = state.healthStateArray;
              stateItems[8]["value"] = this.state.bmi;
              return {
                healthStateArray: stateItems,
              };
            },
            () => {
              let obj = this.state.healthStateArray.find((i, index) => {
                if (i.key === "bmi") {
                  return i;
                }
              });
              let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
              if (typeof onSetDynamicHealthInput === "function") {
                let Inputs = {
                  ...this.props.dynamicHealthInputs,

                  pulse_per_minute,
                  height,
                  weight,
                  blood_oxygen_saturation,
                  body_temperature,
                  bmi: this.state.bmi,
                  systolic_bp,
                  diastolic_bp,
                  respiratory_rate,
                };
                onSetDynamicHealthInput(Inputs);
              }
              setTimeout(() => {
                this._handleHealthStateArray(obj.id, obj.value);
              }, 1200);
            }
          );
        });
      } else if (
        (!height || height === "" || !weight || weight === "") &&
        bmi
      ) {
        this.setState({bmi: null}, () => {
          this.setState(
            (state) => {
              let stateItems = state.healthStateArray;
              stateItems[8]["value"] = "";
              return {
                healthStateArray: stateItems,
              };
            },
            () => {
              let obj = this.state.healthStateArray.find((i, index) => {
                if (i.key === "bmi") {
                  return i;
                }
              });
              let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
              if (typeof onSetDynamicHealthInput === "function") {
                let Inputs = {
                  ...this.props.dynamicHealthInputs,

                  pulse_per_minute,
                  height,
                  weight,
                  blood_oxygen_saturation,
                  body_temperature,
                  bmi: this.state.bmi,
                  systolic_bp,
                  diastolic_bp,
                  respiratory_rate,
                };
                onSetDynamicHealthInput(Inputs);
              }
              setTimeout(() => {
                // this.handleBlurHA(obj, obj.id);
                // if (item.value) {

                this._handleRemoveHealthStateArray(obj.id, 8);
              }, 1200);
            }
          );
        });
      } else {
        let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
        if (typeof onSetDynamicHealthInput === "function") {
          let Inputs = {
            ...this.props.dynamicHealthInputs,

            pulse_per_minute,
            height,
            weight,
            blood_oxygen_saturation,
            body_temperature,
            bmi: this.state.bmi,
            systolic_bp,
            diastolic_bp,
            respiratory_rate,
          };
          onSetDynamicHealthInput(Inputs);
        }
      }
    });
  };
  calculateBMI = (height, weight) => {
    // Convert height to meters
    const heightInMeters = height / 100;

    // Calculate BMI
    const bmiValue = weight / (heightInMeters * heightInMeters);

    // Update state with the calculated BMI
    return bmiValue.toFixed(2);
  };
  handleKeyPress = (e, Key, index) => {
    if (e.key === "Enter" || e.key === "Tab") {
      this.handleBlur(Key, index);
    } else if (e.key === "Backspace" && !this.state[Key]) {
      this.setState((state) => {
        let stateItems = state.checkboxStateArray;
        stateItems[index]["value"] = "";
        return {
          checkboxStateArray: stateItems,
        };
      });
    }
  };
  handleKeyPressHA = (e, Key, index) => {
    if (e.key === "Enter") {
      this.handleBlurHA(Key, index);
    } else if (e.key === "Backspace" && !this.state[Key]) {
      this.setState((state) => {
        let stateItems = state.healthStateArray;
        stateItems[index]["value"] = "";
        return {
          healthStateArray: stateItems,
        };
      });
    }
  };

  handleOnChange = (value, key, name) => {
    if (name === "Pregnancy") {
      this.setState({[key]: value.target.value});
    } else {
      this.setState({[key]: value});
    }
  };
  handleAddPrescription = () => {
    // if (this.props.show_prescriotion_modal_from_notes === null) {
    // this.handleDynamicKey(97, "PrescriptionsContainer"); //?NOTE: on next frommanagement it should redirect to prescription modal open,
    return store.dispatch({
      type: "FROM_PAST_CONSULTATION_TRIGGER",
      payload: true,
    });
    // }
  };
  _onIpUserSelect = (val) => {
    console.log("VALLL", val);
    let errors = null;
    // let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors["review_by_id"];
    }
    this.setState({
      review_by_id: val,
      errors: errors,
    });
  };
  _handleTemplateChange = (v) => {
    console.log("Selected Template", v);
    this.setState({template_id: v});
  };

  render() {
    let {
      consultationDetail,
      history,
      examination,
      managementPlan,

      visible,
      searching,

      snomedCodes,

      // selectedSnomedCodesArray,
      newselectedCodesArray,
      addingDetail,

      prescription_plan,

      isLoading,
      errors,
      splitSize,
      selectedCodesArray,

      checkboxStateArray,
    } = this.state;
    console.log("STATE----", this.state);
    let secondPanelClassName = splitSize ? "up-arrow" : "down-arrow";
    let {
      // appointmentId, patientId, userId,
      appointmentObj,
      prev_appt_tab_key,
      userRole,
    } = this.props;
    let havePharmacistDesignationCondition =
      prev_appt_tab_key === "pending" ||
      prev_appt_tab_key === "pre_consult_reviewed" ||
      prev_appt_tab_key === "kiosk_reviewed"
        ? (this.props.designation &&
            this.props.designation.toLowerCase() === "pharmacist") ||
          userRole === ROLE_DOCTOR ||
          userRole === ROLE_CLINICAL_PHARMACIST
        : this.props.userRole !== ROLE_SUPERINTENDENT_PHARMACIST;
    let {
      // data,
      isLoading: isLoadingPreEncounter,
      pagination: paginationPreEncounter,
    } = this.props.preEnccounter;
    let {
      // data,
      isLoading: isLoadingPrescription,
      pagination: paginationPrescription,
    } = this.props.prescriptions;
    const urlParams = new URLSearchParams(window.location.search);
    const pre_consult_id = urlParams.get("pre_consult_id")
      ? urlParams.get("pre_consult_id")
      : verifyObject(this.props.appointmentObj, "pre_consult_id", null);
    // const fromPreviousAppointment = urlParams.get("fromPreviousAppointment"); //commented as due to this showing pre-consult if pre_consult_tag is null
    // console.log("fromPreviousAppointment",fromPreviousAppointment)
    // let editViewCondition =
    //   window.location.pathname === "/patient/consultation_detail" ||
    //   window.location.pathname === "/doctor/walkin-appointment" ||
    //   window.location.pathname === "/doctor/video-appointment";

    // const urlParams = new URLSearchParams(window.location.search);
    // const isReview = urlParams.get("isReview");
    if (isLoading) {
      return <CommonLoader />;
    }

    let showNewConsultationInputs =
      !this.state.isFinshedEdit ||
      ((this.props.prev_appt_tab_key === "finished" ||
        this.props.prev_appt_tab_key === "pre_consult_reviewed" ||
        this.props.prev_appt_tab_key === "reviewed" ||
        this.props.prev_appt_tab_key === "kiosk_reviewed") &&
        consultationDetail === null);
    return (
      <Fragment>
        <div className="">
          <Toaster position="bottom-right" reverseOrder={false} />
          <div className="selection_container">
            <div className="patientdetailsmain padding-15-lr consultaiondetail-main">
              <div
                className={`mainappoinmentwrap ${
                  this.props.prev_appt_tab_key === "pre_consult_reviewed" ||
                  this.props.prev_appt_tab_key === "unfinished" ||
                  "reviewed"
                    ? "showreasonfield"
                    : appointmentObj &&
                      verifyObject(appointmentObj, "reason_text", false)
                    ? "showreasonfield"
                    : "hidereasonfield"
                } previous-${
                  this.props.prev_appt_tab_key === "unfinished"
                    ? "unfinished"
                    : "finished"
                }-view`}
              >
                <SplitPane split="horizontal" onChange={this.updateHeight}>
                  <Pane>
                    <PerfectScrollbar>
                      {/* new integration start */}
                      {/* {consultationDetail !== null && ( */}
                      <div className="sectionscroll pre_encountersecscroll">
                        <div className="push-10-b padding-bottom-20 padding-left-10 padding-right-10">
                          <div className="sectionscroll pre_encountersecscroll">
                            <div className="push-20-b walkin_appnt_wrapper padding-15-t">
                              <div className="selection_container container-fluid padding-left-10 padding-right-10">
                                <div className="form-group relative push-10-b">
                                  {(this.props.userRole === ROLE_DOCTOR ||
                                    this.props.userRole ===
                                      ROLE_CLINICAL_PHARMACIST ||
                                    this.props.userRole ===
                                      ROLE_SUPERINTENDENT_PHARMACIST) &&
                                    (this.props.prev_appt_tab_key ===
                                      "finished" ||
                                      this.props.prev_appt_tab_key ===
                                        "kiosk_reviewed" ||
                                      this.props.prev_appt_tab_key ===
                                        "pre_consult_reviewed" ||
                                      this.props.prev_appt_tab_key ===
                                        "reviewed") &&
                                    consultationDetail !== null && (
                                      <div className=" d-flex justify-content-end">
                                        {this.state.isFinshedEdit ? (
                                          <Button
                                            onClick={
                                              () =>
                                                this.setState(
                                                  {
                                                    isFinshedEdit: false,
                                                  },
                                                  () => {
                                                    let {onSetDynamicInput} =
                                                      this.props
                                                        .dynamicTabAction;

                                                    let Inputs = {
                                                      ...this.props
                                                        .dynamicInputs,
                                                      isFinshedEdit:
                                                        this.state
                                                          .isFinshedEdit,
                                                    };
                                                    if (
                                                      typeof onSetDynamicInput ===
                                                      "function"
                                                    ) {
                                                      onSetDynamicInput(Inputs);
                                                    }
                                                    this.handleRegionHAfunction();
                                                  }
                                                )
                                              // this._handleEdit("editPrescriptionPlan")
                                            }
                                            className="add-coding cursor font-12 weight-400 btn btn-custom btn-blue font-12 weight-500 submitBtn width-100px height-30 p-0 d-flex justify-content-center align-items-center"
                                          >
                                            <EditOutlined /> Edit
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={() =>
                                              this.setState(
                                                {
                                                  isFinshedEdit: true,
                                                },
                                                () => {
                                                  let {onSetDynamicInput} =
                                                    this.props.dynamicTabAction;

                                                  let Inputs = {
                                                    ...this.props.dynamicInputs,
                                                    isFinshedEdit:
                                                      this.state.isFinshedEdit,
                                                  };
                                                  if (
                                                    typeof onSetDynamicInput ===
                                                    "function"
                                                  ) {
                                                    onSetDynamicInput(Inputs);
                                                  }
                                                  this.handleClearUpdateDetails();
                                                }
                                              )
                                            }
                                            className="add-coding cursor font-12 weight-400 btn btn-custom btn-blue font-12 weight-500 submitBtn width-100px height-30 p-0 d-flex justify-content-center align-items-center"
                                          >
                                            <CloseCircleOutlined /> Close
                                          </Button>
                                        )}
                                      </div>
                                    )}
                                  <div className="form-row">
                                    <div
                                      className={`col-md-${
                                        showNewConsultationInputs ? "7" : "12"
                                      } maincol`}
                                    >
                                      {/* {this.props.fromOngoingConsultation && (  commenting as ADD-10528*/}
                                      {showNewConsultationInputs && (
                                        <div className="reason_wrapper">
                                          <div className="d-flex justify-content-between align-items-center push-5-b">
                                            <div className="labelicon d-flex align-items-center">
                                              <p className="font-12 weight-400 text-left text-blue in-block margin-0 text-capitalize">
                                                Presenting Complaint
                                              </p>
                                              {verifyObject(
                                                this.props,
                                                "appointmentOb.reason_image"
                                              ) ? (
                                                <div className="pres_icon ml-3">
                                                  <Button
                                                    className="imagebtn test"
                                                    onClick={
                                                      this.showpresentingModal
                                                    }
                                                  >
                                                    <PictureFilled />
                                                  </Button>

                                                  <ModalPopUp
                                                    title={
                                                      "Presenting Complaint"
                                                    }
                                                    className="presentingcomp_popup"
                                                    visible={
                                                      this.state
                                                        .getPresentingComModal
                                                    }
                                                    handleOk={this.handleOk}
                                                    handleCancel={
                                                      this.handleCancel
                                                    }
                                                    okText="Okay"
                                                    cancelText="Cancel"
                                                    bodyStyle={{height: 400}}
                                                  >
                                                    {this.props.appointmentObj
                                                      .reason_image !== "" &&
                                                    this.props.appointmentObj
                                                      .reason_image !==
                                                      undefined ? (
                                                      <Image
                                                        src={
                                                          this.props
                                                            .appointmentObj
                                                            .reason_image !== ""
                                                            ? this.props
                                                                .appointmentObj
                                                                .reason_image
                                                            : ""
                                                        }
                                                        className="cursor-pointer"
                                                        alt="Patient"
                                                        width="100%"
                                                      ></Image>
                                                    ) : (
                                                      <img
                                                        src={
                                                          require("../../assets/images/common/img-placeholder.png")
                                                            .default
                                                        }
                                                        alt="Patient"
                                                        className="cursor-pointer"
                                                      ></img>
                                                    )}
                                                  </ModalPopUp>
                                                </div>
                                              ) : null}
                                              <div className="infoicon ml-2">
                                                <Popover
                                                  content={presentingpopover}
                                                  title="Info"
                                                >
                                                  <Button
                                                    className="infobtn"
                                                    // onClick={
                                                    //   this.showPresentingInfoModal
                                                    // }
                                                  >
                                                    <InfoCircleOutlined />
                                                  </Button>
                                                </Popover>
                                              </div>
                                            </div>
                                            {this.props.patientDetail &&
                                              this.props.patientDetail
                                                ?.gender === "female" && (
                                                <div className="ispregnentbox">
                                                  {this.props.patientDetail
                                                    ?.age > 13 &&
                                                    this.props.patientDetail
                                                      ?.age < 65 && (
                                                      <div className="checkboxgroup">
                                                        <span className="d-flex checkboxmaintitle">
                                                          Is the patient
                                                          pregnant?
                                                        </span>
                                                        <Radio.Group
                                                          value={
                                                            this.state
                                                              .is_pregnant
                                                          }
                                                          onChange={(e) => {
                                                            this.setState(
                                                              {
                                                                is_pregnant:
                                                                  e.target
                                                                    .value,
                                                              },
                                                              () => {
                                                                if (
                                                                  this.state
                                                                    .is_pregnant ===
                                                                  "y"
                                                                ) {
                                                                  this.setState(
                                                                    {
                                                                      selectedCodesArray:
                                                                        [
                                                                          ...this
                                                                            .state
                                                                            .selectedCodesArray,
                                                                          {
                                                                            id:
                                                                              process
                                                                                .env
                                                                                .REACT_APP_ENV ===
                                                                                "dev" ||
                                                                              process
                                                                                .env
                                                                                .REACT_APP_ENV ===
                                                                                "stage"
                                                                                ? 1719078
                                                                                : 47928,
                                                                            code: 169571009,
                                                                            description:
                                                                              "Patient pregnant NOS",
                                                                          },
                                                                        ],
                                                                    },
                                                                    () => {
                                                                      let autoTriggerPayload =
                                                                        {
                                                                          snomed_code_data:
                                                                            this
                                                                              .state
                                                                              .selectedCodesArray,
                                                                        };

                                                                      this.handleAutoSaveConsultationTrigger(
                                                                        autoTriggerPayload
                                                                      );
                                                                      let {
                                                                        onSetDynamicInput,
                                                                      } =
                                                                        this
                                                                          .props
                                                                          .dynamicTabAction;
                                                                      let {
                                                                        dynamicInputs,
                                                                      } =
                                                                        this
                                                                          .props;

                                                                      if (
                                                                        typeof onSetDynamicInput ===
                                                                        "function"
                                                                      ) {
                                                                        let payload =
                                                                          {
                                                                            ...dynamicInputs,
                                                                            selectedCodesArray:
                                                                              this
                                                                                .state
                                                                                .selectedCodesArray,
                                                                            selectedSnomedCodesArray:
                                                                              this
                                                                                .state
                                                                                .selectedCodesArray,
                                                                            is_pregnant:
                                                                              this
                                                                                .state
                                                                                .is_pregnant,
                                                                          };
                                                                        onSetDynamicInput(
                                                                          payload
                                                                        );
                                                                      }
                                                                    }
                                                                  );
                                                                } else {
                                                                  this.setState(
                                                                    {
                                                                      selectedCodesArray:
                                                                        this.state.selectedCodesArray.filter(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.id !==
                                                                            169571009
                                                                        ),
                                                                      selectedSnomedCodesArray:
                                                                        this.state.selectedSnomedCodesArray.filter(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.id !==
                                                                            169571009
                                                                        ),
                                                                    },
                                                                    () => {
                                                                      let autoTriggerPayload =
                                                                        {
                                                                          snomed_code_data:
                                                                            this
                                                                              .state
                                                                              .selectedCodesArray,
                                                                        };

                                                                      this.handleAutoSaveConsultationTrigger(
                                                                        autoTriggerPayload
                                                                      );
                                                                      let {
                                                                        onSetDynamicInput,
                                                                      } =
                                                                        this
                                                                          .props
                                                                          .dynamicTabAction;
                                                                      let {
                                                                        dynamicInputs,
                                                                      } =
                                                                        this
                                                                          .props;

                                                                      if (
                                                                        typeof onSetDynamicInput ===
                                                                        "function"
                                                                      ) {
                                                                        let payload =
                                                                          {
                                                                            ...dynamicInputs,
                                                                            selectedCodesArray:
                                                                              this
                                                                                .state
                                                                                .selectedCodesArray,
                                                                            selectedSnomedCodesArray:
                                                                              this
                                                                                .state
                                                                                .selectedCodesArray,
                                                                            is_pregnant:
                                                                              this
                                                                                .state
                                                                                .is_pregnant,
                                                                          };
                                                                        onSetDynamicInput(
                                                                          payload
                                                                        );
                                                                      }
                                                                    }
                                                                  );
                                                                }
                                                              }
                                                            );
                                                          }}
                                                        >
                                                          <Radio value="y">
                                                            Yes
                                                          </Radio>
                                                          <Radio value="n">
                                                            No
                                                          </Radio>
                                                          {/* <Radio value="">Other</Radio> */}
                                                        </Radio.Group>
                                                      </div>
                                                    )}
                                                </div>
                                              )}
                                            {/* {this.props.fromOngoingConsultation && ( */}
                                            <div className="d-flex align-items-center">
                                              <a
                                                className="font-12 weight-500 text-right text-blue in-block mr-2 text-capitalize search_symtoms"
                                                onClick={() => {
                                                  if (
                                                    this.props.gender !==
                                                    "female"
                                                  ) {
                                                    this.handleGetDiagnosis();
                                                  } else {
                                                    this.handleGetDiagnosisFemale();
                                                  }
                                                  // this._SaveComponentStateToRedux();
                                                }}
                                                disabled={
                                                  (isArray(
                                                    this.state.reason_text
                                                  ) &&
                                                    this.state.reason_text
                                                      .length === 0) ||
                                                  this.state.searchingDiagnose
                                                }
                                                loading={
                                                  this.state.searchingDiagnose
                                                }
                                              >
                                                Get Results
                                              </a>
                                              <a
                                                disabled={
                                                  isArray(
                                                    this.state.reason_text
                                                  ) &&
                                                  this.state.reason_text
                                                    .length === 0
                                                }
                                                onClick={this.handleClear}
                                                className="font-12 weight-500 text-right text-blue in-block text-capitalize search_symtoms"
                                              >
                                                Clear
                                              </a>
                                            </div>
                                            {/* )} */}
                                          </div>
                                          <div className="">
                                            <div className="d-flex full-width align-items-center">
                                              <CreatableSelect
                                                isMulti
                                                options={
                                                  this.state.symptomsData
                                                }
                                                isValidNewOption={() => false}
                                                allowCreateWhileLoading={false}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={
                                                  // this.state.reason_text === ""
                                                  //   ? []
                                                  //   :
                                                  this.state.reason_text
                                                }
                                                onKeyDown={(e) =>
                                                  this.hanleEnterKeyPress(e)
                                                }
                                                ref={this.creatableRef}
                                                onChange={(
                                                  value,
                                                  actionMeta
                                                ) => {
                                                  if (
                                                    value &&
                                                    value.length > 0
                                                  ) {
                                                    this.setState(
                                                      {
                                                        reason_text:
                                                          this.filterUniqueItems(
                                                            value,
                                                            process.env
                                                              .REACT_APP ===
                                                              "prod"
                                                              ? DP_QUESTIONNAIRE_LIVE
                                                              : DP_QUESTIONNAIRE
                                                          ),
                                                      },
                                                      () => {
                                                        if (
                                                          actionMeta.action ===
                                                          "remove-value"
                                                        ) {
                                                          let newArray = [];

                                                          let {dynamicInputs} =
                                                            this.props;
                                                          let removeStringArray =
                                                            dynamicInputs?.reason_text?.filter(
                                                              (item) => {
                                                                return !value.some(
                                                                  (item2) =>
                                                                    item2.label ===
                                                                    item.label
                                                                );
                                                              }
                                                            ) || [];
                                                          let historyStringArray =
                                                            this.state.history
                                                              .split("\n")
                                                              .filter(
                                                                (item) =>
                                                                  item !==
                                                                  removeStringArray[0]
                                                                    .label
                                                              );

                                                          newArray =
                                                            historyStringArray;

                                                          this.setState(
                                                            {
                                                              history:
                                                                newArray.join(
                                                                  "\n"
                                                                ),
                                                            },
                                                            () => {
                                                              let autoTriggerPayload =
                                                                {
                                                                  history:
                                                                    this.state
                                                                      .history,
                                                                  reason:
                                                                    (
                                                                      this.state
                                                                        .reason_text ||
                                                                      []
                                                                    ).length > 0
                                                                      ? JSON.stringify(
                                                                          this
                                                                            .state
                                                                            .reason_text
                                                                        )
                                                                      : // .map(
                                                                        //   (
                                                                        //     item
                                                                        //   ) =>
                                                                        //     item.label
                                                                        // )
                                                                        // .join(
                                                                        //   ","
                                                                        // )
                                                                        null,
                                                                  snomed_code_data:
                                                                    this.state
                                                                      .selectedCodesArray,
                                                                };

                                                              this.handleAutoSaveConsultationTrigger(
                                                                autoTriggerPayload
                                                              );
                                                              let {
                                                                onSetDynamicInput,
                                                              } =
                                                                this.props
                                                                  .dynamicTabAction;
                                                              let {
                                                                dynamicInputs,
                                                              } = this.props;

                                                              if (
                                                                typeof onSetDynamicInput ===
                                                                "function"
                                                              ) {
                                                                let payload = {
                                                                  ...dynamicInputs,
                                                                  history:
                                                                    this.state
                                                                      .history,
                                                                  reason_text:
                                                                    this.state
                                                                      .reason_text,
                                                                };
                                                                onSetDynamicInput(
                                                                  payload
                                                                );
                                                              }
                                                            }
                                                          );
                                                        } else {
                                                          let newArray = [];

                                                          let newHistory =
                                                            this.state
                                                              .history !== "" &&
                                                            this.state.history
                                                              ? this.state
                                                                  .history +
                                                                "\n" +
                                                                value
                                                                  .map(
                                                                    (item) =>
                                                                      item.label
                                                                  )
                                                                  .join("\n")
                                                              : value
                                                                  .map(
                                                                    (item) =>
                                                                      item.label
                                                                  )
                                                                  .join("\n");

                                                          const uniqueItems =
                                                            {};
                                                          for (const item of newHistory.split(
                                                            "\n"
                                                          )) {
                                                            if (
                                                              !uniqueItems[item]
                                                            ) {
                                                              uniqueItems[
                                                                item
                                                              ] = true;
                                                              newArray.push(
                                                                item
                                                              );
                                                            }
                                                          }

                                                          this.setState(
                                                            {
                                                              history:
                                                                newArray.join(
                                                                  "\n"
                                                                ),
                                                            },
                                                            () => {
                                                              let autoTriggerPayload =
                                                                {
                                                                  history:
                                                                    this.state
                                                                      .history,
                                                                  reason:
                                                                    (
                                                                      this.state
                                                                        .reason_text ||
                                                                      []
                                                                    ).length > 0
                                                                      ? JSON.stringify(
                                                                          this
                                                                            .state
                                                                            .reason_text
                                                                        )
                                                                      : // .map(
                                                                        //   (
                                                                        //     item
                                                                        //   ) =>
                                                                        //     item.label
                                                                        // )
                                                                        // .join(
                                                                        //   ","
                                                                        // )
                                                                        null,
                                                                  snomed_code_data:
                                                                    this.state
                                                                      .selectedCodesArray,
                                                                };

                                                              this.handleAutoSaveConsultationTrigger(
                                                                autoTriggerPayload
                                                              );
                                                              let {
                                                                onSetDynamicInput,
                                                              } =
                                                                this.props
                                                                  .dynamicTabAction;
                                                              let {
                                                                dynamicInputs,
                                                              } = this.props;

                                                              if (
                                                                typeof onSetDynamicInput ===
                                                                "function"
                                                              ) {
                                                                let payload = {
                                                                  ...dynamicInputs,
                                                                  history:
                                                                    this.state
                                                                      .history,
                                                                  reason_text:
                                                                    this.state
                                                                      .reason_text,
                                                                };
                                                                onSetDynamicInput(
                                                                  payload
                                                                );
                                                              }
                                                            }
                                                          );
                                                        }
                                                      }
                                                    );
                                                  } else {
                                                    let reasonStringArray = this
                                                      .state.reason_text
                                                      ? this.state.reason_text.map(
                                                          (item) => item.label
                                                        )
                                                      : [];
                                                    let removeHistoryStringArray =
                                                      this.state.history
                                                        ? this.state.history
                                                            .split("\n")
                                                            .filter((item1) => {
                                                              return !reasonStringArray.some(
                                                                (item2) =>
                                                                  item2 ===
                                                                  item1
                                                              );
                                                            })
                                                        : [];

                                                    this.setState(
                                                      {
                                                        reason_text: value,
                                                        history:
                                                          removeHistoryStringArray.join(
                                                            "\n"
                                                          ),
                                                        diagnosisData: [],
                                                        selectedCodesArray:
                                                          this.state.selectedCodesArray.filter(
                                                            (item1) => {
                                                              return !this.state.symptompSnomedCode.some(
                                                                (item2) =>
                                                                  +item2.code ===
                                                                  +item1.code
                                                              );
                                                            }
                                                          ),
                                                        symptompSnomedCode: [],
                                                      },
                                                      () => {
                                                        let autoTriggerPayload =
                                                          {
                                                            history:
                                                              this.state
                                                                .history,
                                                            reason:
                                                              (
                                                                this.state
                                                                  .reason_text ||
                                                                []
                                                              ).length > 0
                                                                ? JSON.stringify(
                                                                    this.state
                                                                      .reason_text
                                                                  )
                                                                : // .map(
                                                                  //   (item) =>
                                                                  //     item.label
                                                                  // )
                                                                  // .join(",")
                                                                  null,
                                                            snomed_code_data:
                                                              this.state
                                                                .selectedCodesArray,
                                                          };

                                                        this.handleAutoSaveConsultationTrigger(
                                                          autoTriggerPayload
                                                        );
                                                        store.dispatch({
                                                          type: "SET_SYMPTOMP_FETCHED_LINKS",
                                                          payload: null,
                                                        });
                                                        let {
                                                          onSetDynamicInput,
                                                        } =
                                                          this.props
                                                            .dynamicTabAction;
                                                        let {dynamicInputs} =
                                                          this.props;

                                                        if (
                                                          typeof onSetDynamicInput ===
                                                          "function"
                                                        ) {
                                                          let payload = {
                                                            ...dynamicInputs,
                                                            reason_text:
                                                              this.state
                                                                .reason_text,
                                                            history:
                                                              this.state
                                                                .history,
                                                            reason_text_string:
                                                              "",
                                                            symptomsData:
                                                              this.state
                                                                .symptomsData,
                                                            diagnosisData:
                                                              this.state
                                                                .diagnosisData,
                                                            selectedCodesArray:
                                                              this.state
                                                                .selectedCodesArray,
                                                            selectedSnomedCodesArray:
                                                              this.state
                                                                .selectedCodesArray,
                                                            symptompSnomedCode:
                                                              this.state
                                                                .symptompSnomedCode,
                                                          };
                                                          onSetDynamicInput(
                                                            payload
                                                          );
                                                        }
                                                      }
                                                    );
                                                  }
                                                  if (
                                                    actionMeta &&
                                                    actionMeta.action ===
                                                      "select-option"
                                                  ) {
                                                    if (
                                                      this.creatableRef &&
                                                      this.creatableRef.current
                                                    ) {
                                                      if (
                                                        typeof this.creatableRef
                                                          .current.select.select
                                                          .inputRef.focus ===
                                                        "function"
                                                      ) {
                                                        this.creatableRef.current.select.select.inputRef.focus();
                                                      }
                                                    }
                                                  }
                                                }}
                                                placeholder="Search and select"
                                                noOptionsMessage={() =>
                                                  "No matching options"
                                                }
                                                // onCreateOption={
                                                //   this.customAbbrivationChange
                                                // }
                                                filterOption={() => true}
                                                components={{
                                                  DropdownIndicator: null,
                                                }}
                                                isLoading={
                                                  this.state.searchingSymptom ||
                                                  this.state.searchingDiagnose
                                                }
                                                onInputChange={(value) => {
                                                  this.setState({
                                                    custom_text: value,
                                                  });
                                                  this.searchUpdateSymptom(
                                                    value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {/* {pre_consult_id &&
                                        fromPreviousAppointment === "true" && (
                                          <Fragment>
                                            {this.state
                                              .fetchingSubmittedQuestionnaire && (
                                              <p className="text-center">
                                                <p>
                                                  <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                                                    Fetching Submitted
                                                    Questionnaire...
                                                  </p>
                                                  <Spinner />
                                                </p>
                                              </p>
                                            )}
                                            {!this.state
                                              .fetchingSubmittedQuestionnaire && (
                                              <PreConsultSubmittedQuestionnaire
                                                submitedQuestionnairesArray={
                                                  this.state
                                                    .submitedQuestionnairesArray
                                                }
                                                selectedCodesArray={
                                                  this.state.selectedCodesArray
                                                }
                                                symptompSnomedCode={
                                                  this.state.symptompSnomedCode
                                                }
                                                history={this.state.history}
                                                dynamicInputs={verifyObject(
                                                  this.props,
                                                  "dynamicInputs",
                                                  null
                                                )}
                                                gender={this.props.gender}
                                                handlePreconsultNoteChange={(
                                                  preconsultNoteString
                                                ) => {
                                                  this.setState(
                                                    {
                                                      pre_consult_note:
                                                        preconsultNoteString,
                                                      diagnosisData: null,
                                                    },
                                                    () => {
                                                      this.scrollToBottomForTextArea(
                                                        "pre_consult_note"
                                                      );

                                                      let {
                                                        onSetDynamicInput,
                                                      } =
                                                        this.props
                                                          .dynamicTabAction;
                                                      let { dynamicInputs } =
                                                        this.props;

                                                      if (
                                                        typeof onSetDynamicInput ===
                                                        "function"
                                                      ) {
                                                        let payload = {
                                                          ...dynamicInputs,
                                                          history:
                                                            this.state.history,
                                                          pre_consult_note:
                                                            this.state
                                                              .pre_consult_note,
                                                          symptomsData:
                                                            this.state
                                                              .symptomsData,
                                                          diagnosisData: null,
                                                          selectedCodesArray:
                                                            this.state
                                                              .selectedCodesArray,
                                                          selectedSnomedCodesArray:
                                                            this.state
                                                              .selectedCodesArray,
                                                        };
                                                        onSetDynamicInput(
                                                          payload
                                                        );
                                                      }
                                                      resizeTextArea(
                                                        "#pre_consult_note"
                                                      );
                                                    }
                                                  );
                                                }}
                                              />
                                            )}
                                            {((!this.state
                                              .fetchingSubmittedQuestionnaire &&
                                              verifyObject(
                                                this.props.appointmentObj,
                                                "pre_consult_id",
                                                null
                                              ) !== null) ||
                                              fromPreviousAppointment ===
                                                "true") && (
                                              <div className="form-row push-10-b">
                                                <div className="col-md-12 consultant_review">
                                                  <p
                                                    id="pre_consult_lbl"
                                                    className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-high-dark-blue-imp"
                                                  >
                                                    Pre Consult
                                                  </p>

                                                  <AutoTextArea
                                                    autoSize={true}
                                                    type="text"
                                                    className={`form-control-textarea input-bg-f9f9f9 form-control ${
                                                      errors &&
                                                      errors.pre_consult_note &&
                                                      "danger-border"
                                                    } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus`}
                                                    name="pre_consult_note"
                                                    value={
                                                      this.state
                                                        .pre_consult_note
                                                    }
                                                    disabled={true}
                                                    onChange={
                                                      this._handleTextChange
                                                    }
                                                    placeholder="Add your pre consult text"
                                                    ref={this.scollRef}
                                                    id="pre_consult_note"
                                                    scrollToBottomForTextArea={() =>
                                                      this.scrollToBottomForTextArea(
                                                        "pre_consult_note"
                                                      )
                                                    }
                                                  />

                                                </div>
                                              </div>
                                            )}
                                          </Fragment>
                                        )} */}
                                      {showNewConsultationInputs &&
                                        pre_consult_id === null &&
                                        pre_consult_id !== "null" && (
                                          <QuestionnaireFillUp
                                            selectedCodesArray={
                                              this.state.selectedCodesArray
                                            }
                                            symptompSnomedCode={
                                              this.state.symptompSnomedCode
                                            }
                                            history={this.state.history}
                                            gender={this.props.gender}
                                            handleExaminationChange={(
                                              examinationString
                                            ) => {
                                              this.setState(
                                                {
                                                  history: isEmpty(
                                                    this.state.history
                                                  )
                                                    ? examinationString
                                                    : this.state.history +
                                                      "\n" +
                                                      examinationString,
                                                  diagnosisData: null,
                                                },
                                                () => {
                                                  this.scrollToBottomForTextArea(
                                                    "history_textarea"
                                                  );
                                                  let {onSetDynamicInput} =
                                                    this.props.dynamicTabAction;
                                                  let {dynamicInputs} =
                                                    this.props;

                                                  if (
                                                    typeof onSetDynamicInput ===
                                                    "function"
                                                  ) {
                                                    let payload = {
                                                      ...dynamicInputs,
                                                      history:
                                                        this.state.history,
                                                      // reason_text: this.state.reason_text,
                                                      // reason_text_string: "",
                                                      symptomsData:
                                                        this.state.symptomsData,
                                                      diagnosisData: null,
                                                      selectedCodesArray:
                                                        this.state
                                                          .selectedCodesArray,
                                                      selectedSnomedCodesArray:
                                                        this.state
                                                          .selectedCodesArray,
                                                    };
                                                    onSetDynamicInput(payload);
                                                  }
                                                }
                                              );
                                            }}
                                            updateReasonText={(reason) => {
                                              this.setState(
                                                {
                                                  symptomsData: [
                                                    // ...this.state.searchSymptom,
                                                    reason,
                                                  ],
                                                  diagnosisData: null,
                                                  reason_text: [
                                                    // ...this.state.reason_text,
                                                    reason,
                                                  ],
                                                  recentTravel: false,
                                                  region_id: 1,
                                                },
                                                () => {}
                                              );
                                            }}
                                            updateSnomedCodes={(
                                              found,
                                              removeOldSnomedCodeArray,
                                              removeOldSymptomSnomedCode
                                            ) => {
                                              this.setState(
                                                {
                                                  selectedCodesArray:
                                                    removeOldSnomedCodeArray
                                                      .concat({
                                                        id: found.snomed_code_id,
                                                        code: found.snomed_code,
                                                        description:
                                                          found.snomed_code_title,
                                                      })
                                                      .filter((item1) => {
                                                        return !this.state.symptompSnomedCode.some(
                                                          (item2) =>
                                                            +item2.id ===
                                                            +item1.id
                                                        );
                                                      }),
                                                  symptompSnomedCode: [],
                                                  // removeOldSymptomSnomedCode.concat({
                                                  //   id: found.snomed_code_id,
                                                  //   code: found.snomed_code,
                                                  //   description:
                                                  //     found.snomed_code_title,
                                                  // }),
                                                  diagnosisData: null,
                                                  recentTravel: false,
                                                  region_id: 1,
                                                },
                                                () => {
                                                  let autoTriggerPayload = {
                                                    reason:
                                                      (
                                                        this.state
                                                          .reason_text || []
                                                      ).length > 0
                                                        ? JSON.stringify(
                                                            this.state
                                                              .reason_text
                                                          )
                                                        : // .map(
                                                          //   (item) =>
                                                          //     item.label
                                                          // )
                                                          // .join(",")
                                                          null,
                                                    history: this.state.history,
                                                    snomed_code_data:
                                                      this.state
                                                        .selectedCodesArray,
                                                  };

                                                  this.handleAutoSaveConsultationTrigger(
                                                    autoTriggerPayload
                                                  );
                                                  store.dispatch({
                                                    type: "SET_SYMPTOMP_FETCHED_LINKS",
                                                    payload: null,
                                                  });
                                                  let {onSetDynamicInput} =
                                                    this.props.dynamicTabAction;
                                                  let {dynamicInputs} =
                                                    this.props;

                                                  if (
                                                    typeof onSetDynamicInput ===
                                                    "function"
                                                  ) {
                                                    let payload = {
                                                      ...dynamicInputs,
                                                      history:
                                                        this.state.history,
                                                      reason_text:
                                                        this.state.reason_text,
                                                      recentTravel:
                                                        this.state.recentTravel,
                                                      region_id:
                                                        this.state.region_id,
                                                      reason_text_string: "",
                                                      symptomsData:
                                                        this.state.symptomsData,
                                                      diagnosisData: null,
                                                      selectedCodesArray:
                                                        this.state
                                                          .selectedCodesArray,
                                                      selectedSnomedCodesArray:
                                                        this.state
                                                          .selectedCodesArray,
                                                      symptompSnomedCode:
                                                        this.state
                                                          .symptompSnomedCode,
                                                    };
                                                    onSetDynamicInput(payload);
                                                  }
                                                }
                                              );
                                            }}
                                          />
                                        )}

                                      {(((this.props.prev_appt_tab_key ===
                                        "finished" ||
                                        this.props.prev_appt_tab_key ===
                                          "kiosk_reviewed" ||
                                        this.props.prev_appt_tab_key ===
                                          "pre_consult_reviewed") &&
                                        (verifyObject(
                                          consultationDetail,
                                          "pre_consult_tag",
                                          null
                                        ) === "hypertensive_case_finding" ||
                                          verifyObject(
                                            consultationDetail,
                                            "pre_consult_tag",
                                            null
                                          ) === "pharmacy_first")) ||
                                        (this.props.prev_appt_tab_key ===
                                          "unfinished" &&
                                          this.state
                                            .isAutoSaveConsultationData &&
                                          this.state.pre_consult_note)) && (
                                        //  ||
                                        // fromPreviousAppointment === "true" //commented as due to this showing pre-consult if pre_consult_tag is null
                                        <div className="consultant_review push-10-b">
                                          <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                                            Pre Consult
                                          </p>

                                          <div className="div-border consultant_data input-bg-f9f9f9">
                                            {consultationDetail ? (
                                              verifyObject(
                                                consultationDetail,
                                                "consultation_log.pre_consult_note",
                                                null
                                              ) ? (
                                                <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext word-break">
                                                  {verifyObject(
                                                    consultationDetail,
                                                    "consultation_log.pre_consult_note",
                                                    "-"
                                                  )}
                                                </p>
                                              ) : (
                                                "-"
                                              )
                                            ) : this.state
                                                .isAutoSaveConsultationData ? (
                                              <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext word-break">
                                                {this.state.pre_consult_note
                                                  ? this.state.pre_consult_note
                                                  : "-"}
                                              </p>
                                            ) : (
                                              "-"
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      <div className="consultant_review push-10-b">
                                        <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                                          History
                                        </p>
                                        {/* //ON GOING TASK START */}

                                        <div className="div-border consultant_data input-bg-f9f9f9">
                                          {consultationDetail !== null && (
                                            <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext word-break">
                                              {verifyObject(
                                                consultationDetail,
                                                "consultation_log.history",
                                                null
                                              )
                                                ? verifyObject(
                                                    consultationDetail,
                                                    "consultation_log.history",
                                                    "-"
                                                  )
                                                : // : this.state
                                                  //     .isAutoSaveConsultationData
                                                  // ? verifyObject(
                                                  //     consultationDetail,
                                                  //     "history",
                                                  //     "-"
                                                  //   )
                                                  "-"}
                                            </p>
                                          )}
                                          {verifyObject(
                                            consultationDetail,
                                            "consultation_log.edited_history",
                                            []
                                          ).map((k, index) => {
                                            if (k.history !== null) {
                                              return (
                                                <div
                                                  className="highlightedtext push-5-t normal-lineheight padding-5-t"
                                                  key={index}
                                                >
                                                  {" "}
                                                  <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 prelinetext">
                                                    {k.history}
                                                  </p>
                                                  <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                                    Edited by {k.edited_by} On{" "}
                                                    {moment(k.date).format(
                                                      DATE_FORMAT_24_HR
                                                    )}
                                                  </p>
                                                </div>
                                              );
                                            }
                                            return null;
                                          })}
                                        </div>

                                        {/* //ON GOING TASK END */}

                                        {showNewConsultationInputs && (
                                          <AutoTextArea
                                            autoSize={true}
                                            type="text"
                                            className={`form-control-textarea input-bg-f9f9f9 form-control push-10-t ${
                                              errors &&
                                              errors.history &&
                                              "danger-border"
                                            } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus min-height-92`}
                                            name="history"
                                            value={history}
                                            onChange={this._handleTextChange}
                                            placeholder="Write patient history here"
                                            rows="2"
                                            id="history_textarea"
                                            scrollToBottomForTextArea={() =>
                                              this.scrollToBottomForTextArea(
                                                "history_textarea"
                                              )
                                            }
                                          />
                                        )}
                                        {errors && (
                                          <span className="validate-danger font-14">
                                            {errors.history}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    {showNewConsultationInputs && (
                                      <div className="col-md-5 consultantion-info-table-wrapper">
                                        {/* {this.props.fromOngoingConsultation && ( */}

                                        <div className="form-group">
                                          <div className="form-row">
                                            <div className="col-md-12 religon_drop">
                                              <div className="walkingquestions">
                                                <div className="hideshowcheckbox">
                                                  <Checkbox
                                                    checked={
                                                      this.state.recentTravel
                                                    }
                                                    onChange={
                                                      this.onquestionChange
                                                    }
                                                  >
                                                    <label htmlFor="sel1 text-high-dark-blue-imp">
                                                      Any recent foreign travel?
                                                    </label>
                                                  </Checkbox>
                                                </div>
                                              </div>
                                              {/* {this.state.recentTravel && ( */}
                                              <Select
                                                // showSearch
                                                getPopupContainer={() =>
                                                  document.querySelector(
                                                    ".consultantion-info-table-wrapper"
                                                  )
                                                }
                                                disabled={
                                                  !this.state.recentTravel ||
                                                  this.state.fetchingRegion
                                                }
                                                loading={
                                                  this.state.fetchingRegion
                                                }
                                                className="form-control margin-0 form-control-textarea input-bg-f9f9f9 full-width custom-input font-12 weight-400 placeholder-10"
                                                placeholder="Select region"
                                                optionFilterProp="children"
                                                value={
                                                  !this.state.recentTravel
                                                    ? this.state.fetchingRegion
                                                      ? null
                                                      : 1
                                                    : this.state.fetchingRegion
                                                    ? null
                                                    : this.state.region_id
                                                }
                                                onChange={(value) =>
                                                  this.setState(
                                                    {
                                                      region_id: value,
                                                    },
                                                    async () => {
                                                      let {onSetDynamicInput} =
                                                        this.props
                                                          .dynamicTabAction;
                                                      let {dynamicInputs} =
                                                        this.props;

                                                      if (
                                                        typeof onSetDynamicInput ===
                                                        "function"
                                                      ) {
                                                        let payload = {
                                                          ...dynamicInputs,
                                                          region_id:
                                                            this.state
                                                              .region_id,
                                                        };
                                                        onSetDynamicInput(
                                                          payload
                                                        );
                                                      }
                                                      // TODO:417
                                                      if (
                                                        this.props.gender !==
                                                        "female"
                                                      ) {
                                                        await this.handleGetDiagnosis();
                                                      } else {
                                                        await this.handleGetDiagnosisFemale();
                                                      }
                                                    }
                                                  )
                                                }
                                                filterOption={(input, option) =>
                                                  option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                      input.toLowerCase()
                                                    ) >= 0
                                                }
                                              >
                                                {this.state.regionsData.length >
                                                  0 &&
                                                  this.state.regionsData.map(
                                                    (region) => {
                                                      return (
                                                        <Select.Option
                                                          value={region.nhs_id}
                                                        >{`${region.name} `}</Select.Option>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                              {/* )} */}
                                            </div>
                                          </div>
                                        </div>
                                        {/* )} */}
                                        {
                                          // this.props.fromOngoingConsultation &&
                                          this.state.diagnosisData !== null && (
                                            <div className="">
                                              {this.state.diagnosisData &&
                                                !isEmpty(
                                                  this.state.diagnosisData
                                                ) && (
                                                  // this.state.diagnosisData.length > 0 &&
                                                  <div className="tabledata push-10-b">
                                                    {this.state
                                                      .searchingDiagnose ? (
                                                      <span className="d-flex justify-content-center align-items-center">
                                                        <Spin />
                                                      </span>
                                                    ) : (
                                                      <SymmtomDiagnosisTabs
                                                        gender={
                                                          this.props.gender
                                                        }
                                                        diagnosisData={
                                                          this.state
                                                            .diagnosisData
                                                        }
                                                        loading={
                                                          this.state
                                                            .searchingDiagnose
                                                        }
                                                        patient_id={
                                                          this.props
                                                            .patientId ||
                                                          this.props.patient_id
                                                        }
                                                        updateSnomedCodes={(
                                                          found
                                                        ) => {
                                                          this.setState(
                                                            {
                                                              selectedCodesArray:
                                                                uniqBy(
                                                                  [
                                                                    ...selectedCodesArray,
                                                                    {
                                                                      id: found.id,
                                                                      code: found.code,
                                                                      description:
                                                                        found.description,
                                                                    },
                                                                  ],
                                                                  "code"
                                                                ).filter(
                                                                  (item) => {
                                                                    if (item) {
                                                                      return !this.state.symptompSnomedCode.some(
                                                                        (
                                                                          item2
                                                                        ) =>
                                                                          +item2.id ===
                                                                          item.id
                                                                      );
                                                                    }
                                                                  }
                                                                ),
                                                              newselectedCodesArray:
                                                                uniqBy(
                                                                  [
                                                                    ...(newselectedCodesArray ||
                                                                      []),
                                                                    {
                                                                      id: found.id,
                                                                      code: found.code,
                                                                      description:
                                                                        found.description,
                                                                    },
                                                                  ],
                                                                  "code"
                                                                ).filter(
                                                                  (item) => {
                                                                    return !this.state.symptompSnomedCode.some(
                                                                      (item2) =>
                                                                        +item2.id ===
                                                                        item.id
                                                                    );
                                                                  }
                                                                ),
                                                              symptompSnomedCode:
                                                                [
                                                                  // ...this.state
                                                                  //   .symptompSnomedCode,
                                                                  {
                                                                    id: found.id,
                                                                    code: found.code,
                                                                    description:
                                                                      found.description,
                                                                  },
                                                                ],
                                                            },
                                                            () => {
                                                              let autoTriggerPayload =
                                                                {
                                                                  snomed_code_data:
                                                                    this.state
                                                                      .selectedCodesArray,
                                                                };

                                                              this.handleAutoSaveConsultationTrigger(
                                                                autoTriggerPayload
                                                              );
                                                              let {
                                                                onSetDynamicInput,
                                                              } =
                                                                this.props
                                                                  .dynamicTabAction;
                                                              let {
                                                                dynamicInputs,
                                                              } = this.props;

                                                              if (
                                                                typeof onSetDynamicInput ===
                                                                "function"
                                                              ) {
                                                                let payload = {
                                                                  ...dynamicInputs,
                                                                  history:
                                                                    this.state
                                                                      .history,
                                                                  reason_text:
                                                                    this.state
                                                                      .reason_text,
                                                                  reason_text_string:
                                                                    "",
                                                                  symptomsData:
                                                                    this.state
                                                                      .symptomsData,
                                                                  diagnosisData:
                                                                    this.state
                                                                      .diagnosisData,
                                                                  selectedCodesArray:
                                                                    this.state
                                                                      .selectedCodesArray,
                                                                  selectedSnomedCodesArray:
                                                                    this.state
                                                                      .selectedCodesArray,
                                                                  symptompSnomedCode:
                                                                    this.state
                                                                      .symptompSnomedCode,
                                                                  newselectedCodesArray:
                                                                    this.state
                                                                      .newselectedCodesArray,
                                                                };
                                                                onSetDynamicInput(
                                                                  payload
                                                                );
                                                              }
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                )}
                                            </div>
                                          )
                                        }
                                      </div>
                                    )}
                                  </div>

                                  <div className="form-row">
                                    <div
                                      className={`col-md-${
                                        showNewConsultationInputs ? "7" : "12"
                                      }`}
                                    >
                                      <div className="push-10-b">
                                        <div className="consultant_review">
                                          <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-high-dark-blue-imp">
                                            Examination
                                          </p>
                                          {/* EXAMINATION START */}
                                          <div className="div-border consultant_data input-bg-f9f9f9">
                                            {consultationDetail !== null && (
                                              <p
                                                dangerouslySetInnerHTML={{
                                                  __html: verifyObject(
                                                    consultationDetail,
                                                    "consultation_log.examination",
                                                    "-"
                                                  )
                                                    ? verifyObject(
                                                        consultationDetail,
                                                        "consultation_log.examination",
                                                        "-"
                                                      )
                                                    : "-",
                                                }}
                                                className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext word-break"
                                              ></p>
                                            )}
                                            {verifyObject(
                                              consultationDetail,
                                              "consultation_log.edited_examination",
                                              []
                                            ).map((k, index) => {
                                              if (k.examination !== null) {
                                                return (
                                                  <div
                                                    className="highlightedtext push-5-t normal-lineheight padding-5-t"
                                                    key={index}
                                                  >
                                                    {" "}
                                                    <p
                                                      dangerouslySetInnerHTML={{
                                                        __html: k.examination,
                                                      }}
                                                      className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 prelinetext"
                                                    ></p>
                                                    <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                                      Edited by {k.edited_by} On{" "}
                                                      {moment(k.date).format(
                                                        DATE_FORMAT_24_HR
                                                      )}
                                                    </p>
                                                  </div>
                                                );
                                              }
                                              return null;
                                            })}
                                          </div>
                                          {/* EXAMINATION END */}
                                          {showNewConsultationInputs && (
                                            <div className="push-10-t">
                                              <AutoTextArea
                                                autoSize={true}
                                                type="text"
                                                className={`form-control-textarea input-bg-f9f9f9 form-control ${
                                                  errors &&
                                                  errors.history &&
                                                  "danger-border"
                                                } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus min-height-92`}
                                                name="examination"
                                                value={examination}
                                                onChange={
                                                  this._handleTextChange
                                                }
                                                placeholder="Write examination detail here"
                                                rows="2"
                                                id="examination_textarea"
                                                scrollToBottomForTextArea={() =>
                                                  this.scrollToBottomForTextArea(
                                                    "examination_textarea"
                                                  )
                                                }
                                              />
                                              {/* <ExaminationEditor
                                                _handleTextChange={(e) =>
                                                  this._handleTextChange(
                                                    e,
                                                    "examination"
                                                  )
                                                }
                                                errors={errors}
                                                setState={this.setState}
                                                examination={
                                                  !showNewConsultationInputs
                                                    ? verifyObject(
                                                        consultationDetail,
                                                        "consultation_log.examination",
                                                        "-"
                                                      )
                                                      ? verifyObject(
                                                          consultationDetail,
                                                          "consultation_log.examination",
                                                          "-"
                                                        )
                                                      : "-"
                                                    : examination
                                                }
                                              /> */}
                                            </div>
                                            //   <AutoTextArea
                                            //     autoSize={true}
                                            //     type="text"
                                            //     className={`form-control-textarea input-bg-f9f9f9 form-control push-10-t word-break ${
                                            //       errors &&
                                            //       errors.examination &&
                                            //       "danger-border"
                                            //     } custom-input font-12 weight-400 placeholder-10  no-focus min-height-92`}
                                            //     name="examination"
                                            //     value={examination}
                                            //     onChange={this._handleTextChange}
                                            //     placeholder="Write examination detail here"
                                            //     id="examination_textarea"
                                            //     scrollToBottomForTextArea={() =>
                                            //       this.scrollToBottomForTextArea(
                                            //         "examination_textarea"
                                            //       )
                                            //     }
                                            //   />
                                          )}
                                          {errors && (
                                            <span className="validate-danger font-14">
                                              {errors.examination}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      {showNewConsultationInputs && (
                                        <div className={`checkboxgroup`}>
                                          <div className="form-row m-0">
                                            {checkboxStateArray.map(
                                              (item, index) => {
                                                return (
                                                  <div
                                                    className={`col p-0 ${
                                                      item.short_text ===
                                                      "Pregnancy"
                                                        ? "preg_input"
                                                        : item.short_text ===
                                                          "Stroke"
                                                        ? "highlighted-stroke"
                                                        : item.short_text ===
                                                          "Sepsis"
                                                        ? "highlighted-sepsis"
                                                        : item.short_text ===
                                                          "PHQ-9"
                                                        ? "highlighted-phq9"
                                                        : item.short_text ===
                                                          "GAD-7"
                                                        ? "highlighted-gad7"
                                                        : item.short_text ===
                                                          "Centor throat"
                                                        ? "highlighted_throat"
                                                        : item.short_text ===
                                                          "Wells DVT"
                                                        ? "highlighted_dvt"
                                                        : ""
                                                    }`}
                                                    key={item.snomed_code}
                                                  >
                                                    <div className="inputbox highlightedtext d-flex align-items-center">
                                                      <a
                                                        href={item.link}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        {item.short_text}
                                                      </a>
                                                      <div className="numberinput">
                                                        {item.short_text !==
                                                          "Pregnancy" && (
                                                          <InputNumber
                                                            className="custom-input-number"
                                                            formatter={
                                                              this
                                                                .handleFormater
                                                            }
                                                            parser={(value) =>
                                                              this.handleParser(
                                                                value,
                                                                item.allowNegativeValue
                                                              )
                                                            }
                                                            onChange={(e) => {
                                                              this.setState(
                                                                (state) => {
                                                                  let stateItems =
                                                                    state.checkboxStateArray;
                                                                  stateItems[
                                                                    index
                                                                  ]["value"] =
                                                                    e;
                                                                  return {
                                                                    checkboxStateArray:
                                                                      stateItems,
                                                                  };
                                                                },
                                                                () => {}
                                                              );
                                                            }}
                                                            value={
                                                              item.value || ""
                                                            }
                                                            type="number"
                                                            onBlur={(e) => {
                                                              const val =
                                                                e.target.value;

                                                              if (
                                                                val !==
                                                                item.value
                                                              ) {
                                                                this.handleBlur(
                                                                  item,
                                                                  index
                                                                );
                                                              }
                                                            }}
                                                            onKeyPress={(e) =>
                                                              this.handleKeyPress(
                                                                e,
                                                                item,
                                                                index
                                                              )
                                                            }
                                                            precision={2}
                                                            onKeyUp={
                                                              this.handleKeyUp
                                                            }
                                                          />
                                                        )}
                                                        {item.short_text ===
                                                          "Pregnancy" && (
                                                          <div>
                                                            <InputMask
                                                              mask={
                                                                "99/99/9999"
                                                              }
                                                              placeholder="DD/MM/YYYY"
                                                              // className="custom-input-number"
                                                              {...this.props}
                                                              onChange={(e) => {
                                                                const {value} =
                                                                  e.target;

                                                                this.setState(
                                                                  (state) => {
                                                                    let stateItems =
                                                                      state.checkboxStateArray;
                                                                    stateItems[
                                                                      index
                                                                    ]["value"] =
                                                                      e.target.value;
                                                                    return {
                                                                      checkboxStateArray:
                                                                        stateItems,
                                                                    };
                                                                  },
                                                                  () => {}
                                                                );
                                                              }}
                                                              value={item.value}
                                                              onBlur={() => {
                                                                this.handleBlur(
                                                                  item,
                                                                  index,
                                                                  true
                                                                );
                                                              }}
                                                              onKeyDown={(e) =>
                                                                this.handleKeyPress(
                                                                  e,
                                                                  item,
                                                                  index,
                                                                  true
                                                                )
                                                              }
                                                              onKeyUp={() =>
                                                                this.handleKeyUp(
                                                                  true
                                                                )
                                                              }
                                                            >
                                                              {(inputProps) => (
                                                                <input
                                                                  {...inputProps}
                                                                  type="text"
                                                                  className="custom-input-number"
                                                                  name={
                                                                    this.props
                                                                      .name
                                                                  }
                                                                />
                                                              )}
                                                            </InputMask>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      <div className="push-10-b diagnosisdropwn">
                                        <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize diagnosisdrop">
                                          Diagnosis / Clinical Impressions
                                        </p>
                                        <div>
                                          {!showNewConsultationInputs &&
                                            selectedCodesArray &&
                                            isArray(selectedCodesArray) &&
                                            selectedCodesArray.map(
                                              (code, index) => {
                                                return (
                                                  <div
                                                    className="padding-5 margin-right-10 input-bg-f9f9f9 in-block div-border line-1 consultant_data"
                                                    key={index}
                                                  >
                                                    <p className="margin-bottom-0 font-11 weight-500 in-block text-light-black margin-right-5">
                                                      <span className="ng-binding ng-scope">
                                                        {verifyObject(
                                                          code,
                                                          "code",
                                                          null
                                                        ) !== null
                                                          ? code.code
                                                          : "--"}
                                                      </span>
                                                    </p>
                                                    <p className="margin-0 font-11 weight-400 in-block text-dark-gray">
                                                      {code.description}
                                                    </p>
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                        <div className="consultaiondetail-main">
                                          {/* //commented poup flow, extarct to main screen */}

                                          <div className="in-block d-flex">
                                            {/* //commented poup flow, extarct to main screen */}

                                            {showNewConsultationInputs && (
                                              <form className="createtableform">
                                                <div className="form-group push-10-b">
                                                  <div className="form-row">
                                                    <div className="col-md-12">
                                                      <CreatableSelect
                                                        // isMulti
                                                        // className={`form-control margin-0 ${
                                                        //   errors && errors.snomedCodeId && "danger-border"
                                                        // } indication-dropdown`}

                                                        placeholder="Search and select..."
                                                        className="disselectfield"
                                                        classNamePrefix="react-select"
                                                        isClearable
                                                        onChange={(value) =>
                                                          this._handleDropDownChange(
                                                            value,
                                                            "snomedCodeId"
                                                          )
                                                        }
                                                        components={{
                                                          DropdownIndicator:
                                                            null,
                                                        }}
                                                        onInputChange={(
                                                          value
                                                        ) => {
                                                          this.setState({
                                                            snomedCodeId: value,
                                                          });
                                                          this.searchUpdate(
                                                            value
                                                          );
                                                        }}
                                                        onCreateOption={
                                                          this
                                                            ._handleCustomCodeAdd
                                                        }
                                                        value={
                                                          this.state
                                                            .snomedCodeId
                                                            ? this.state
                                                                .snomedCodeId
                                                            : ""
                                                        }
                                                        inputValue={
                                                          this.state
                                                            .snomedCodeId
                                                            ? this.state
                                                                .snomedCodeId
                                                            : ""
                                                        }
                                                        name="snomedCodeId"
                                                        options={
                                                          snomedCodes.length > 0
                                                            ? snomedCodes
                                                            : []
                                                        }
                                                        isLoading={searching}
                                                        loadingMessage={() => (
                                                          <span>
                                                            Fetching Snomed ...
                                                          </span>
                                                        )}
                                                        onMenuScrollToBottom={
                                                          this.handleScroll
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-xs-12 bg-white coding_modal_table push-10-t">
                                                  <table className="table live-talk2-table">
                                                    <thead>
                                                      <tr>
                                                        <th className="font-12 weight-500 text-black">
                                                          Code
                                                        </th>
                                                        <th className="font-12 weight-500 text-black">
                                                          Description
                                                        </th>
                                                        <th className="font-12 weight-500 text-black"></th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {selectedCodesArray
                                                        ? selectedCodesArray.length ===
                                                            0 && (
                                                            <tr className="ng-hide">
                                                              <td
                                                                colSpan="3"
                                                                className="text-center text-dark-gray font-12 weight-400 default-padding"
                                                              >
                                                                No codes
                                                                selected
                                                              </td>
                                                            </tr>
                                                          )
                                                        : null}
                                                      {selectedCodesArray
                                                        ? selectedCodesArray.length >
                                                            0 &&
                                                          isArray(
                                                            selectedCodesArray
                                                          ) &&
                                                          this.removeDuplicates(
                                                            selectedCodesArray
                                                          ).map((code) => {
                                                            let ids = map(
                                                              this.state
                                                                .newselectedCodesArray ??
                                                                [],
                                                              "id"
                                                            );

                                                            let disabledClass =
                                                              ids.includes(
                                                                code?.id
                                                              )
                                                                ? ""
                                                                : "disabled-view";

                                                            return (
                                                              <tr>
                                                                <td className="font-12 weight-400 default-padding text-light-black">
                                                                  {verifyObject(
                                                                    code,
                                                                    "code",
                                                                    ""
                                                                  )}
                                                                </td>
                                                                <td className="font-12 weight-400 default-padding text-light-black">
                                                                  {verifyObject(
                                                                    code,
                                                                    "description",
                                                                    ""
                                                                  )}
                                                                </td>
                                                                <td
                                                                  className={`font-12 weight-400 default-padding text-light-black text-right relative ${disabledClass}`}
                                                                >
                                                                  {" "}
                                                                  <span
                                                                    onClick={() =>
                                                                      this._removeCode(
                                                                        code
                                                                      )
                                                                    }
                                                                    className="text-right text-dark-blue weight-400 hide_outline"
                                                                    ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                                                                    role="button"
                                                                    tabIndex="0"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        deleteImage
                                                                      }
                                                                      alt="Add Coding"
                                                                      height="12"
                                                                      width="12"
                                                                    />
                                                                  </span>
                                                                </td>
                                                              </tr>
                                                            );
                                                          })
                                                        : null}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </form>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {showNewConsultationInputs && (
                                      <div className="col-md-5 consultantion-info-table-wrapper p-t-0">
                                        <div className="table-wrapper maintable">
                                          <div className="border">
                                            <div className="bg-gray tablehead">
                                              <div className="d-flex w-50">
                                                <div className="padding-5 w-40">
                                                  <b>Key</b>
                                                </div>
                                                <div className="padding-5 w-20">
                                                  <b>Value</b>
                                                </div>
                                                <div className="padding-5 flex-1"></div>
                                              </div>
                                            </div>
                                            <div className="tablebodymain">
                                              <div className="tablerow d-flex">
                                                <div className="highlightedtext d-flex">
                                                  <div className="keytd">
                                                    Pulse
                                                  </div>
                                                  <div className="valuetd">
                                                    <Tooltip
                                                      placement="top"
                                                      title={` ${
                                                        verifyObject(
                                                          this.state
                                                            .pulseObject,
                                                          "created_at",
                                                          ""
                                                        ) !== ""
                                                          ? moment(
                                                              verifyObject(
                                                                this.state
                                                                  .pulseObject,
                                                                "created_at",
                                                                ""
                                                              )
                                                            ).format(
                                                              DATE_FORMAT_24_HR
                                                            )
                                                          : ""
                                                      }`}
                                                    >
                                                      {verifyObject(
                                                        this.state.pulseObject,
                                                        "value",
                                                        ""
                                                      ) !== ""
                                                        ? verifyObject(
                                                            this.state
                                                              .pulseObject,
                                                            "value",
                                                            ""
                                                          )
                                                        : "-"}
                                                    </Tooltip>
                                                  </div>
                                                  <div className="inputtd">
                                                    <div className="d-flex align-items-center">
                                                      <InputNumber
                                                        // className="custom-input-number"
                                                        className={this.renderInputColor(
                                                          this.state
                                                            .pulse_per_minute,
                                                          "pulse_per_minute"
                                                        )}
                                                        min={30}
                                                        max={200}
                                                        type="number"
                                                        value={
                                                          this.state
                                                            .pulse_per_minute
                                                        }
                                                        onChange={(e) => {
                                                          this.setState(
                                                            (state) => {
                                                              let stateItems =
                                                                state.healthStateArray;
                                                              stateItems[0][
                                                                "value"
                                                              ] = e;
                                                              return {
                                                                healthStateArray:
                                                                  stateItems,
                                                              };
                                                            },
                                                            () => {
                                                              this.handleHealthTextChange(
                                                                e,
                                                                "pulse_per_minute"
                                                              );
                                                            }
                                                          );
                                                        }}
                                                        onBlur={() => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "pulse_per_minute"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );

                                                          this.handleBlurHA(
                                                            obj,
                                                            0
                                                          );
                                                        }}
                                                        onKeyPress={(e) => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "pulse_per_minute"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );
                                                          this.handleKeyPressHA(
                                                            e,
                                                            obj,
                                                            0
                                                          );
                                                        }}
                                                        onKeyUp={
                                                          this.handleKeyUp
                                                        }
                                                      />
                                                      {errors && (
                                                        <span className="validate-danger font-14">
                                                          {
                                                            errors.pulse_per_minute
                                                          }
                                                        </span>
                                                      )}

                                                      <span className="ml-1">
                                                        bpm
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="highlightedtext d-flex">
                                                  <div className="keytd">
                                                    SpO2
                                                  </div>
                                                  <div className="valuetd">
                                                    <Tooltip
                                                      placement="top"
                                                      title={` ${
                                                        verifyObject(
                                                          this.state
                                                            .blood_oxygen_saturationObject,
                                                          "created_at",
                                                          ""
                                                        ) !== ""
                                                          ? moment(
                                                              verifyObject(
                                                                this.state
                                                                  .blood_oxygen_saturationObject,
                                                                "created_at",
                                                                ""
                                                              )
                                                            ).format(
                                                              DATE_FORMAT_24_HR
                                                            )
                                                          : ""
                                                      }`}
                                                    >
                                                      {verifyObject(
                                                        this.state
                                                          .blood_oxygen_saturationObject,
                                                        "value",
                                                        ""
                                                      ) !== ""
                                                        ? verifyObject(
                                                            this.state
                                                              .blood_oxygen_saturationObject,
                                                            "value",
                                                            ""
                                                          )
                                                        : "-"}
                                                    </Tooltip>
                                                  </div>
                                                  <div className="inputtd">
                                                    <div className="d-flex align-items-center">
                                                      <InputNumber
                                                        // className="custom-input-number"
                                                        className={this.renderInputColor(
                                                          this.state
                                                            .blood_oxygen_saturation,
                                                          "blood_oxygen_saturation"
                                                        )}
                                                        min={50}
                                                        max={100}
                                                        type="number"
                                                        value={
                                                          this.state
                                                            .blood_oxygen_saturation
                                                        }
                                                        onChange={(e) => {
                                                          this.setState(
                                                            (state) => {
                                                              let stateItems =
                                                                state.healthStateArray;
                                                              stateItems[3][
                                                                "value"
                                                              ] = e;
                                                              return {
                                                                healthStateArray:
                                                                  stateItems,
                                                              };
                                                            },
                                                            () => {
                                                              this.handleHealthTextChange(
                                                                e,
                                                                "blood_oxygen_saturation"
                                                              );
                                                            }
                                                          );
                                                        }}
                                                        onBlur={() => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "blood_oxygen_saturation"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );

                                                          this.handleBlurHA(
                                                            obj,
                                                            3
                                                          );
                                                        }}
                                                        onKeyPress={(e) => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "blood_oxygen_saturation"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );
                                                          this.handleKeyPressHA(
                                                            e,
                                                            obj,
                                                            3
                                                          );
                                                        }}
                                                        onKeyUp={
                                                          this.handleKeyUp
                                                        }
                                                      />
                                                      {errors && (
                                                        <span className="validate-danger font-14">
                                                          {
                                                            errors.blood_oxygen_saturation
                                                          }
                                                        </span>
                                                      )}

                                                      <span className="ml-1">
                                                        %
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="tablerow d-flex">
                                                <div className="highlightedtext d-flex">
                                                  <div className="keytd">
                                                    Height{" "}
                                                  </div>
                                                  <div className="valuetd">
                                                    <Tooltip
                                                      placement="top"
                                                      title={` ${
                                                        verifyObject(
                                                          this.state
                                                            .heightObject,
                                                          "created_at",
                                                          ""
                                                        ) !== ""
                                                          ? moment(
                                                              verifyObject(
                                                                this.state
                                                                  .heightObject,
                                                                "created_at",
                                                                ""
                                                              )
                                                            ).format(
                                                              DATE_FORMAT_24_HR
                                                            )
                                                          : ""
                                                      }`}
                                                    >
                                                      {verifyObject(
                                                        this.state.heightObject,
                                                        "value",
                                                        ""
                                                      ) !== ""
                                                        ? verifyObject(
                                                            this.state
                                                              .heightObject,
                                                            "value",
                                                            ""
                                                          ) * 0.1 //multiplied by 0.1 as per BE for convert to cm from milimeter, same need to do while passing to API
                                                        : "-"}
                                                    </Tooltip>
                                                  </div>
                                                  <div className="inputtd">
                                                    <div className="d-flex align-items-center">
                                                      <InputNumber
                                                        className="custom-input-number"
                                                        min={100}
                                                        max={250}
                                                        type="number"
                                                        value={
                                                          this.state.height
                                                        }
                                                        onChange={(e) => {
                                                          const height = e;
                                                          const weight =
                                                            this.state.weight;

                                                          // Update the height in state
                                                          this.setState(
                                                            (state) => {
                                                              let stateItems =
                                                                state.healthStateArray;
                                                              stateItems[5][
                                                                "value"
                                                              ] = height;
                                                              if (
                                                                weight !==
                                                                  null &&
                                                                weight !== "" &&
                                                                weight !==
                                                                  "null" &&
                                                                height !==
                                                                  null &&
                                                                height !== "" &&
                                                                height !==
                                                                  "null"
                                                              ) {
                                                                stateItems[8][
                                                                  "value"
                                                                ] =
                                                                  this.calculateBMI(
                                                                    height,
                                                                    weight
                                                                  );
                                                              }
                                                              return {
                                                                height,
                                                                healthStateArray:
                                                                  stateItems,
                                                                bmi: this.calculateBMI(
                                                                  height,
                                                                  weight
                                                                ), // Calculate BMI
                                                              };
                                                            },
                                                            () => {
                                                              this.handleHealthTextChange(
                                                                e,
                                                                "height"
                                                              );
                                                            }
                                                          );
                                                        }}
                                                        onBlur={() => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "height"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );

                                                          this.handleBlurHA(
                                                            obj,
                                                            5
                                                          );
                                                        }}
                                                        onKeyPress={(e) => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "height"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );
                                                          this.handleKeyPressHA(
                                                            e,
                                                            obj,
                                                            5
                                                          );
                                                        }}
                                                        onKeyUp={
                                                          this.handleKeyUp
                                                        }
                                                      />
                                                      {errors && (
                                                        <span className="validate-danger font-14">
                                                          {errors.height}
                                                        </span>
                                                      )}

                                                      <span className="ml-1">
                                                        cm
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="highlightedtext d-flex bigfields">
                                                  <div className="keytd">
                                                    Weight
                                                  </div>
                                                  <div className="valuetd">
                                                    <Tooltip
                                                      placement="top"
                                                      title={` ${
                                                        verifyObject(
                                                          this.state
                                                            .weightObject,
                                                          "created_at",
                                                          ""
                                                        ) !== ""
                                                          ? moment(
                                                              verifyObject(
                                                                this.state
                                                                  .weightObject,
                                                                "created_at",
                                                                ""
                                                              )
                                                            ).format(
                                                              DATE_FORMAT_24_HR
                                                            )
                                                          : ""
                                                      }`}
                                                    >
                                                      {verifyObject(
                                                        this.state.weightObject,
                                                        "value",
                                                        ""
                                                      ) !== ""
                                                        ? verifyObject(
                                                            this.state
                                                              .weightObject,
                                                            "value",
                                                            ""
                                                          )
                                                        : "-"}
                                                    </Tooltip>
                                                  </div>
                                                  <div className="inputtd">
                                                    <div className="d-flex align-items-center">
                                                      <InputNumber
                                                        className="custom-input-number"
                                                        min={25}
                                                        max={250}
                                                        type="number"
                                                        value={
                                                          this.state.weight
                                                        }
                                                        onChange={(e) => {
                                                          const weight = e;
                                                          const height =
                                                            this.state.height;

                                                          // Update the weight in state
                                                          this.setState(
                                                            (state) => {
                                                              let stateItems =
                                                                state.healthStateArray;
                                                              stateItems[6][
                                                                "value"
                                                              ] = weight;
                                                              if (
                                                                weight !==
                                                                  null &&
                                                                weight !== "" &&
                                                                weight !==
                                                                  "null" &&
                                                                height !==
                                                                  null &&
                                                                height !== "" &&
                                                                height !==
                                                                  "null"
                                                              ) {
                                                                stateItems[8][
                                                                  "value"
                                                                ] =
                                                                  this.calculateBMI(
                                                                    height,
                                                                    weight
                                                                  );
                                                              }
                                                              return {
                                                                weight,
                                                                healthStateArray:
                                                                  stateItems,
                                                                bmi: this.calculateBMI(
                                                                  height,
                                                                  weight
                                                                ), // Calculate BMI
                                                              };
                                                            },
                                                            () => {
                                                              this.handleHealthTextChange(
                                                                e,
                                                                "weight"
                                                              );
                                                            }
                                                          );
                                                        }}
                                                        onBlur={() => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "weight"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );

                                                          this.handleBlurHA(
                                                            obj,
                                                            6
                                                          );
                                                        }}
                                                        onKeyPress={(e) => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "weight"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );
                                                          this.handleKeyPressHA(
                                                            e,
                                                            obj,
                                                            6
                                                          );
                                                        }}
                                                        onKeyUp={
                                                          this.handleKeyUp
                                                        }
                                                      />
                                                      {errors && (
                                                        <span className="validate-danger font-14">
                                                          {errors.weight}
                                                        </span>
                                                      )}

                                                      <span className="ml-1">
                                                        kg
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* BMI CHANGES START */}
                                              <div className="tablerow d-flex">
                                                <div className="highlightedtext d-flex">
                                                  <div className="keytd">
                                                    BMI{" "}
                                                  </div>
                                                  <div className="valuetd">
                                                    <Tooltip
                                                      placement="top"
                                                      title={` ${
                                                        verifyObject(
                                                          this.state.bmiObject,
                                                          "created_at",
                                                          ""
                                                        ) !== ""
                                                          ? moment(
                                                              verifyObject(
                                                                this.state
                                                                  .bmiObject,
                                                                "created_at",
                                                                ""
                                                              )
                                                            ).format(
                                                              DATE_FORMAT_24_HR
                                                            )
                                                          : ""
                                                      }`}
                                                    >
                                                      {verifyObject(
                                                        this.state.bmiObject,
                                                        "value",
                                                        ""
                                                      ) !== ""
                                                        ? verifyObject(
                                                            this.state
                                                              .bmiObject,
                                                            "value",
                                                            ""
                                                          )
                                                        : // * 0.1 //multiplied by 0.1 as per BE for convert to cm from milimeter, same need to do while passing to API
                                                          "-"}
                                                    </Tooltip>
                                                  </div>
                                                  <div className="inputtd">
                                                    <div className="d-flex align-items-center">
                                                      <InputNumber
                                                        className="custom-input-number"
                                                        // min={100}
                                                        // max={250}
                                                        type="number"
                                                        readOnly
                                                        value={this.state.bmi}
                                                        // onChange={(e) =>
                                                        //   this.setState(
                                                        //     (state) => {
                                                        //       let stateItems =
                                                        //         state.healthStateArray;
                                                        //       stateItems[5]["value"] = e;
                                                        //       return {
                                                        //         healthStateArray:
                                                        //           stateItems,
                                                        //       };
                                                        //     },
                                                        //     () => {
                                                        //       this.handleHealthTextChange(
                                                        //         e,
                                                        //         "height"
                                                        //       );
                                                        //     }
                                                        //   )
                                                        // }
                                                        // onBlur={() => {
                                                        //   let obj =
                                                        //     this.state.healthStateArray.find(
                                                        //       (i, index) => {
                                                        //         if (i.key === "height") {
                                                        //           return i;
                                                        //         }
                                                        //       }
                                                        //     );

                                                        //   this.handleBlurHA(obj, 5);
                                                        // }}
                                                        // onKeyPress={(e) => {
                                                        //   let obj =
                                                        //     this.state.healthStateArray.find(
                                                        //       (i, index) => {
                                                        //         if (i.key === "height") {
                                                        //           return i;
                                                        //         }
                                                        //       }
                                                        //     );
                                                        //   this.handleKeyPressHA(
                                                        //     e,
                                                        //     obj,
                                                        //     5
                                                        //   );
                                                        // }}
                                                        // onKeyUp={this.handleKeyUp}
                                                      />
                                                      {/* {errors && (
                                                      <span className="validate-danger font-14">
                                                        {errors.bmi}
                                                      </span>
                                                    )} */}

                                                      {/* <span className="ml-1">cm</span> */}
                                                      <span className="ml-1"></span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="highlightedtext d-flex bigfields">
                                                  <div className="keytd">
                                                    Temp
                                                  </div>
                                                  <div className="valuetd">
                                                    <Tooltip
                                                      placement="top"
                                                      title={` ${
                                                        verifyObject(
                                                          this.state
                                                            .body_temperatureObject,
                                                          "created_at",
                                                          ""
                                                        ) !== ""
                                                          ? moment(
                                                              verifyObject(
                                                                this.state
                                                                  .body_temperatureObject,
                                                                "created_at",
                                                                ""
                                                              )
                                                            ).format(
                                                              DATE_FORMAT_24_HR
                                                            )
                                                          : ""
                                                      }`}
                                                    >
                                                      {verifyObject(
                                                        this.state
                                                          .body_temperatureObject,
                                                        "value",
                                                        ""
                                                      ) !== ""
                                                        ? verifyObject(
                                                            this.state
                                                              .body_temperatureObject,
                                                            "value",
                                                            ""
                                                          )
                                                        : "-"}
                                                    </Tooltip>
                                                  </div>
                                                  <div className="inputtd">
                                                    <div className="d-flex align-items-center">
                                                      <InputNumber
                                                        // className="custom-input-number"
                                                        className={this.renderInputColor(
                                                          this.state
                                                            .body_temperature,
                                                          "body_temperature"
                                                        )}
                                                        min={25}
                                                        max={60}
                                                        type="number"
                                                        value={
                                                          this.state
                                                            .body_temperature
                                                        }
                                                        onChange={(e) => {
                                                          this.setState(
                                                            (state) => {
                                                              let stateItems =
                                                                state.healthStateArray;
                                                              stateItems[4][
                                                                "value"
                                                              ] = e;
                                                              return {
                                                                healthStateArray:
                                                                  stateItems,
                                                              };
                                                            },
                                                            () => {
                                                              this.handleHealthTextChange(
                                                                e,
                                                                "body_temperature"
                                                              );
                                                            }
                                                          );
                                                        }}
                                                        onBlur={() => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "body_temperature"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );

                                                          this.handleBlurHA(
                                                            obj,
                                                            4
                                                          );
                                                        }}
                                                        onKeyPress={(e) => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "body_temperature"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );
                                                          this.handleKeyPressHA(
                                                            e,
                                                            obj,
                                                            4
                                                          );
                                                        }}
                                                        onKeyUp={
                                                          this.handleKeyUp
                                                        }
                                                      />
                                                      {errors && (
                                                        <span className="validate-danger font-14">
                                                          {
                                                            errors.body_temperature
                                                          }
                                                        </span>
                                                      )}

                                                      <span className="ml-1">
                                                        °C
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* BMI CHANGES END */}

                                              <div className="tablerow d-flex">
                                                <div className="highlightedtext d-flex bpfields bigfields">
                                                  <div className="keytd">
                                                    BP (systolic) /(diastolic)
                                                  </div>
                                                  <div className="valuetd">
                                                    <Tooltip
                                                      placement="top"
                                                      title={` ${
                                                        verifyObject(
                                                          this.state
                                                            .systolic_bpObject,
                                                          "created_at",
                                                          ""
                                                        ) !== ""
                                                          ? moment(
                                                              verifyObject(
                                                                this.state
                                                                  .systolic_bpObject,
                                                                "created_at",
                                                                ""
                                                              )
                                                            ).format(
                                                              DATE_FORMAT_24_HR
                                                            )
                                                          : ""
                                                      }`}
                                                    >
                                                      {verifyObject(
                                                        this.state
                                                          .systolic_bpObject,
                                                        "value",
                                                        ""
                                                      ) !== ""
                                                        ? `${verifyObject(
                                                            this.state
                                                              .systolic_bpObject,
                                                            "value",
                                                            ""
                                                          )} /`
                                                        : "-"}
                                                    </Tooltip>
                                                    <br />
                                                    <Tooltip
                                                      placement="top"
                                                      title={` ${
                                                        verifyObject(
                                                          this.state
                                                            .diastolic_bpObject,
                                                          "created_at",
                                                          ""
                                                        ) !== ""
                                                          ? moment(
                                                              verifyObject(
                                                                this.state
                                                                  .diastolic_bpObject,
                                                                "created_at",
                                                                ""
                                                              )
                                                            ).format(
                                                              DATE_FORMAT_24_HR
                                                            )
                                                          : ""
                                                      }`}
                                                    >
                                                      {verifyObject(
                                                        this.state
                                                          .diastolic_bpObject,
                                                        "value",
                                                        ""
                                                      ) !== ""
                                                        ? verifyObject(
                                                            this.state
                                                              .diastolic_bpObject,
                                                            "value",
                                                            ""
                                                          )
                                                        : "-"}
                                                    </Tooltip>
                                                  </div>
                                                  <div className="inputtd">
                                                    <div className="d-flex align-items-center two_input">
                                                      <div className="input-wrapper">
                                                        <InputNumber
                                                          // className="custom-input-number"
                                                          className={this.renderInputColor(
                                                            this.state
                                                              .systolic_bp,
                                                            "systolic_bp"
                                                          )}
                                                          min={40}
                                                          max={300}
                                                          type="number"
                                                          value={
                                                            this.state
                                                              .systolic_bp
                                                          }
                                                          onChange={(e) => {
                                                            this.setState(
                                                              (state) => {
                                                                let stateItems =
                                                                  state.healthStateArray;
                                                                stateItems[2][
                                                                  "value"
                                                                ] = e;
                                                                return {
                                                                  healthStateArray:
                                                                    stateItems,
                                                                };
                                                              },
                                                              () => {
                                                                this.handleHealthTextChange(
                                                                  e,
                                                                  "systolic_bp"
                                                                );
                                                              }
                                                            );
                                                          }}
                                                          onBlur={() => {
                                                            let obj =
                                                              this.state.healthStateArray.find(
                                                                (i, index) => {
                                                                  if (
                                                                    i.key ===
                                                                    "systolic_bp"
                                                                  ) {
                                                                    return i;
                                                                  }
                                                                }
                                                              );

                                                            this.handleBlurHA(
                                                              obj,
                                                              2
                                                            );
                                                          }}
                                                          onKeyPress={(e) => {
                                                            let obj =
                                                              this.state.healthStateArray.find(
                                                                (i, index) => {
                                                                  if (
                                                                    i.key ===
                                                                    "systolic_bp"
                                                                  ) {
                                                                    return i;
                                                                  }
                                                                }
                                                              );
                                                            this.handleKeyPressHA(
                                                              e,
                                                              obj,
                                                              2
                                                            );
                                                          }}
                                                          onKeyUp={
                                                            this.handleKeyUp
                                                          }
                                                        />
                                                        {errors && (
                                                          <span className="validate-danger font-14">
                                                            {errors.systolic_bp}
                                                          </span>
                                                        )}

                                                        <span>/</span>
                                                        <InputNumber
                                                          // className="custom-input-number"
                                                          className={this.renderInputColor(
                                                            this.state
                                                              .diastolic_bp,
                                                            "diastolic_bp"
                                                          )}
                                                          min={20}
                                                          max={200}
                                                          type="number"
                                                          value={
                                                            this.state
                                                              .diastolic_bp
                                                          }
                                                          onChange={(e) => {
                                                            this.setState(
                                                              (state) => {
                                                                let stateItems =
                                                                  state.healthStateArray;
                                                                stateItems[1][
                                                                  "value"
                                                                ] = e;
                                                                return {
                                                                  healthStateArray:
                                                                    stateItems,
                                                                };
                                                              },
                                                              () => {
                                                                this.handleHealthTextChange(
                                                                  e,
                                                                  "diastolic_bp"
                                                                );
                                                              }
                                                            );
                                                          }}
                                                          onBlur={() => {
                                                            let obj =
                                                              this.state.healthStateArray.find(
                                                                (i, index) => {
                                                                  if (
                                                                    i.key ===
                                                                    "diastolic_bp"
                                                                  ) {
                                                                    return i;
                                                                  }
                                                                }
                                                              );

                                                            this.handleBlurHA(
                                                              obj,
                                                              1
                                                            );
                                                          }}
                                                          onKeyPress={(e) => {
                                                            let obj =
                                                              this.state.healthStateArray.find(
                                                                (i, index) => {
                                                                  if (
                                                                    i.key ===
                                                                    "diastolic_bp"
                                                                  ) {
                                                                    return i;
                                                                  }
                                                                }
                                                              );
                                                            this.handleKeyPressHA(
                                                              e,
                                                              obj,
                                                              1
                                                            );
                                                          }}
                                                          onKeyUp={
                                                            this.handleKeyUp
                                                          }
                                                        />
                                                        {errors && (
                                                          <span className="validate-danger font-14">
                                                            {
                                                              errors.diastolic_bp
                                                            }
                                                          </span>
                                                        )}
                                                      </div>
                                                      <span className="ml-1">
                                                        mmHg
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="tablerow d-flex">
                                                <div className="highlightedtext d-flex">
                                                  <div className="keytd w-60">
                                                    Respiratory rate
                                                  </div>
                                                  <div className="inputtd">
                                                    <div className="d-flex align-items-center">
                                                      <InputNumber
                                                        className="custom-input-number"
                                                        min={10}
                                                        max={60}
                                                        type="number"
                                                        value={
                                                          this.state
                                                            .respiratory_rate
                                                        }
                                                        onChange={(e) => {
                                                          this.setState(
                                                            (state) => {
                                                              let stateItems =
                                                                state.healthStateArray;
                                                              stateItems[7][
                                                                "value"
                                                              ] = e;
                                                              return {
                                                                healthStateArray:
                                                                  stateItems,
                                                              };
                                                            },
                                                            () => {
                                                              this.handleHealthTextChange(
                                                                e,
                                                                "respiratory_rate"
                                                              );
                                                            }
                                                          );
                                                        }}
                                                        onBlur={() => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "respiratory_rate"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );

                                                          this.handleBlurHA(
                                                            obj,
                                                            7
                                                          );
                                                        }}
                                                        onKeyPress={(e) => {
                                                          let obj =
                                                            this.state.healthStateArray.find(
                                                              (i, index) => {
                                                                if (
                                                                  i.key ===
                                                                  "respiratory_rate"
                                                                ) {
                                                                  return i;
                                                                }
                                                              }
                                                            );
                                                          this.handleKeyPressHA(
                                                            e,
                                                            obj,
                                                            7
                                                          );
                                                        }}
                                                        onKeyUp={
                                                          this.handleKeyUp
                                                        }
                                                      />
                                                      {errors && (
                                                        <span className="validate-danger font-14">
                                                          {
                                                            errors.respiratory_rate
                                                          }
                                                        </span>
                                                      )}

                                                      <span className="ml-1">
                                                        bpm
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="form-row push-10-b">
                                  <div className="col-md-12 consultant_review managmentplan">
                                    <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-high-dark-blue-imp">
                                      Management Plan
                                    </p>
                                    {/* MGMT START */}
                                    <div className="div-border consultant_data input-bg-f9f9f9">
                                      {consultationDetail !== null && (
                                        <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                          {verifyObject(
                                            consultationDetail,
                                            "consultation_log.management_plan",
                                            null
                                          )
                                            ? verifyObject(
                                                consultationDetail,
                                                "consultation_log.management_plan",
                                                "-"
                                              )
                                            : // : this.state
                                              //     .isAutoSaveConsultationData
                                              // ? verifyObject(
                                              //     consultationDetail,
                                              //     "management_plan",
                                              //     "-"
                                              //   )
                                              "-"}
                                        </p>
                                      )}
                                      {verifyObject(
                                        consultationDetail,
                                        "consultation_log.edited_managment_plan",
                                        []
                                      ).map((k, index) => {
                                        if (k.management_plan !== null) {
                                          return (
                                            <div
                                              className="highlightedtext push-5-t normal-lineheight padding-5-t"
                                              key={index}
                                            >
                                              {" "}
                                              <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70">
                                                {k.management_plan}
                                              </p>
                                              <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                                Edited by {k.edited_by} On{" "}
                                                {moment(k.date).format(
                                                  DATE_FORMAT_24_HR
                                                )}
                                              </p>
                                            </div>
                                          );
                                        }
                                        return null;
                                      })}
                                    </div>
                                    {/* MGMT END */}
                                    {showNewConsultationInputs && (
                                      <AutoTextArea
                                        autoSize={true}
                                        type="text"
                                        className={`form-control-textarea input-bg-f9f9f9 form-control push-10-t ${
                                          errors &&
                                          errors.managementPlan &&
                                          "danger-border"
                                        } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus`}
                                        name="managementPlan"
                                        value={managementPlan}
                                        onChange={this._handleTextChange}
                                        placeholder="Add your Management Plan"
                                        ref={this.scollRef}
                                        id="management_textarea"
                                        scrollToBottomForTextArea={() =>
                                          this.scrollToBottomForTextArea(
                                            "management_textarea"
                                          )
                                        }
                                      />
                                    )}
                                    {errors && (
                                      <span className="validate-danger font-14">
                                        {errors.managementPlan}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                {/* )} */}
                                {/* {presriptionPlan && presriptionPlan !== "" && ( */}
                                {(((this.props.prev_appt_tab_key ===
                                  "finished" ||
                                  this.props.prev_appt_tab_key ===
                                    "unfinished" ||
                                  this.props.prev_appt_tab_key ===
                                    "pre_consult_reviewed" ||
                                  this.props.prev_appt_tab_key === "reviewed" ||
                                  this.props.prev_appt_tab_key ===
                                    "kiosk_reviewed") &&
                                  // consultationDetail !== null &&
                                  ((this.state.presriptionPlan !== "" &&
                                    this.state.presriptionPlan) ||
                                    this.state.prescriptionPlan)) ||
                                  (verifyObject(
                                    consultationDetail,
                                    "consultation_log.edited_prescription_plan",
                                    []
                                  ) &&
                                    verifyObject(
                                      consultationDetail,
                                      "consultation_log.edited_prescription_plan",
                                      []
                                    ).length > 0)) && (
                                  <div
                                    className="form-row push-10-b"
                                    // ref={this.scollRef}
                                  >
                                    <div className="col-md-12 consultant_review">
                                      {(((this.props.prev_appt_tab_key ===
                                        "finished" ||
                                        this.props.prev_appt_tab_key ===
                                          "unfinished" ||
                                        this.props.prev_appt_tab_key ===
                                          "pre_consult_reviewed" ||
                                        this.props.prev_appt_tab_key ===
                                          "reviewed" ||
                                        this.props.prev_appt_tab_key ===
                                          "kiosk_reviewed") &&
                                        // consultationDetail !== null &&
                                        ((this.state.presriptionPlan !== "" &&
                                          this.state.presriptionPlan) ||
                                          this.state.prescriptionPlan)) ||
                                        (verifyObject(
                                          consultationDetail,
                                          "consultation_log.edited_prescription_plan",
                                          []
                                        ) &&
                                          verifyObject(
                                            consultationDetail,
                                            "consultation_log.edited_prescription_plan",
                                            []
                                          ).length > 0)) && (
                                        <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                                          Prescription Plan{" "}
                                          {verifyObject(
                                            consultationDetail,
                                            "prescription_code",
                                            null
                                          )
                                            ? `(${verifyObject(
                                                consultationDetail,
                                                "prescription_code",
                                                null
                                              )})`
                                            : ""}
                                        </p>
                                      )}
                                      {/* PRISCRIPTION START */}
                                      <div className="div-border consultant_data input-bg-f9f9f9">
                                        {(this.state.presriptionPlan !== "" ||
                                          this.state.presriptionPlan !==
                                            null) &&
                                        (this.props.prev_appt_tab_key ===
                                          "unfinished" ||
                                          this.props.prev_appt_tab_key ===
                                            "finished") &&
                                        !consultationDetail ? (
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                            {this.state.presriptionPlan
                                              ? this.state.presriptionPlan !==
                                                "null"
                                                ? this.state.presriptionPlan
                                                : "-"
                                              : this.state
                                                  .isAutoSaveConsultationData
                                              ? null
                                              : "-"}
                                          </p>
                                        ) : null}
                                        {consultationDetail ? (
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                            {prescription_plan
                                              ? prescription_plan
                                              : verifyObject(
                                                  consultationDetail,
                                                  "consultation_log.prescription_plan",
                                                  null
                                                )
                                              ? verifyObject(
                                                  consultationDetail,
                                                  "consultation_log.prescription_plan",
                                                  null
                                                ) !== "null"
                                                ? verifyObject(
                                                    consultationDetail,
                                                    "consultation_log.prescription_plan",
                                                    null
                                                  )
                                                : "-"
                                              : "-"}
                                          </p>
                                        ) : this.state
                                            .isAutoSaveConsultationData ? (
                                          this.state.prescriptionPlan ? (
                                            <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                              {this.state.prescriptionPlan !==
                                              "null"
                                                ? this.state.prescriptionPlan
                                                : "-"}
                                            </p>
                                          ) : this.state
                                              .presriptionPlan ? null : null
                                        ) : null}

                                        {verifyObject(
                                          consultationDetail,
                                          "consultation_log.edited_prescription_plan",
                                          []
                                        ).map((k, index) => {
                                          if (
                                            k.prescription_plan !== null &&
                                            k.prescription_plan !== "null"
                                          ) {
                                            return (
                                              <div
                                                className="highlightedtext push-5-t normal-lineheight padding-5-t"
                                                key={index}
                                              >
                                                {" "}
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 prelinetext">
                                                  {k.prescription_plan}
                                                </p>
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                                  Edited by {k.edited_by} On{" "}
                                                  {moment(k.date).format(
                                                    DATE_FORMAT_24_HR
                                                  )}
                                                </p>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                      </div>
                                      {/* PRISCRIPTION END */}
                                      {/* <AutoTextArea
                                      disabled={
                                        !this.state.isFinshedEdit &&
                                        this.props.prev_appt_tab_key ===
                                          "finished"
                                      }
                                      autoSize={true}
                                      type="text"
                                      className={`form-control-textarea input-bg-f9f9f9 form-control ${
                                        errors &&
                                        errors.managementPlan &&
                                        "danger-border"
                                      } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus`}
                                      name="presriptionPlan"
                                      value={presriptionPlan}
                                      // onChange={this._handleTextChange}
                                      // disabled
                                      placeholder="Add your Prescription Plan"
                                      id="prescription_textarea"
                                      scrollToBottomForTextArea={() =>
                                        this.scrollToBottomForTextArea(
                                          "prescription_textarea"
                                        )
                                      }
                                    /> */}
                                      {errors && (
                                        <span className="validate-danger font-14">
                                          {errors.presriptionPlan}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                )}
                                {/* )} */}
                                {consultationDetail !== null &&
                                  (this.props.prev_appt_tab_key ===
                                    "finished" ||
                                    this.props.prev_appt_tab_key ===
                                      "pre_consult_reviewed" ||
                                    this.props.prev_appt_tab_key ===
                                      "reviewed" ||
                                    this.props.prev_appt_tab_key ===
                                      "kiosk_reviewed") && (
                                    <div className="form-group push-10-b relative">
                                      <div className="form-row">
                                        <div className="col-md-12 consultant_review">
                                          <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                                            Informational Outcomes
                                          </p>
                                          {showNewConsultationInputs && (
                                            <a
                                              onClick={() =>
                                                this._handleEdit("editOutcome")
                                              }
                                              className="add-coding cursor font-12 weight-400"
                                            >
                                              {" "}
                                              <EditOutlined /> Edit
                                            </a>
                                          )}
                                          <div className="div-border consultant_data input-bg-f9f9f9">
                                            {this.props.prev_appt_tab_key !==
                                              "pre_consult_reviewed" &&
                                              this.props.prev_appt_tab_key !==
                                                "kiosk_reviewed" &&
                                              !verifyObject(
                                                this.props.appointmentObj,
                                                "pre_consult_id",
                                                null
                                              ) && (
                                                <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                                  Treatment :{" "}
                                                  {verifyObject(
                                                    consultationDetail,
                                                    "consultation_log.outcome.treatment",
                                                    "-"
                                                  )}
                                                </p>
                                              )}
                                            {this.props.prev_appt_tab_key !==
                                              "pre_consult_reviewed" &&
                                              this.props.prev_appt_tab_key !==
                                                "kiosk_reviewed" &&
                                              !verifyObject(
                                                this.props.appointmentObj,
                                                "pre_consult_id",
                                                null
                                              ) && (
                                                <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                                  Follow Up :{" "}
                                                  {verifyObject(
                                                    consultationDetail,
                                                    "consultation_log.outcome.follow_up",
                                                    "-"
                                                  )}
                                                </p>
                                              )}
                                            {this.props.prev_appt_tab_key !==
                                              "pre_consult_reviewed" &&
                                              this.props.prev_appt_tab_key !==
                                                "kiosk_reviewed" &&
                                              !verifyObject(
                                                this.props.appointmentObj,
                                                "pre_consult_id",
                                                null
                                              ) && (
                                                <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                                  Referral :{" "}
                                                  {verifyObject(
                                                    consultationDetail,
                                                    "consultation_log.outcome.referral",
                                                    "-"
                                                  )}
                                                </p>
                                              )}
                                            <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                              Comment :{" "}
                                              {verifyObject(
                                                consultationDetail,
                                                "consultation_log.outcome.comment",
                                                "-"
                                              )}
                                            </p>
                                          </div>

                                          <div className="div-border consultant_data push-10-t input-bg-f9f9f9">
                                            {verifyObject(
                                              consultationDetail,
                                              "consultation_log.edited_outcome",
                                              []
                                            ).map((k, index) => {
                                              if (
                                                Object.keys(k.outcome)
                                                  .length !== 0
                                              ) {
                                                return (
                                                  <div
                                                    className="edited-outcome"
                                                    key={index}
                                                  >
                                                    {" "}
                                                    {this.props
                                                      .prev_appt_tab_key !==
                                                      "pre_consult_reviewed" &&
                                                      this.props
                                                        .prev_appt_tab_key !==
                                                        "kiosk_reviewed" &&
                                                      !verifyObject(
                                                        this.props
                                                          .appointmentObj,
                                                        "pre_consult_id",
                                                        null
                                                      ) && (
                                                        <p className="margin-0 font-11 weight-400 pink-text normal-lineheight">
                                                          Treatment :{" "}
                                                          {verifyObject(
                                                            k,
                                                            "outcome.treatment",
                                                            "-"
                                                          )}
                                                        </p>
                                                      )}
                                                    {this.props
                                                      .prev_appt_tab_key !==
                                                      "pre_consult_reviewed" &&
                                                      this.props
                                                        .prev_appt_tab_key !==
                                                        "kiosk_reviewed" &&
                                                      !verifyObject(
                                                        this.props
                                                          .appointmentObj,
                                                        "pre_consult_id",
                                                        null
                                                      ) && (
                                                        <p className="margin-0 font-11 weight-400 pink-text normal-lineheight">
                                                          Follow Up :{" "}
                                                          {verifyObject(
                                                            k,
                                                            "outcome.follow_up",
                                                            "-"
                                                          )}
                                                        </p>
                                                      )}
                                                    {this.props
                                                      .prev_appt_tab_key !==
                                                      "pre_consult_reviewed" &&
                                                      this.props
                                                        .prev_appt_tab_key !==
                                                        "kiosk_reviewed" &&
                                                      !verifyObject(
                                                        this.props
                                                          .appointmentObj,
                                                        "pre_consult_id",
                                                        null
                                                      ) && (
                                                        <p className="margin-0 font-11 weight-400 pink-text normal-lineheight">
                                                          Referral :{" "}
                                                          {verifyObject(
                                                            k,
                                                            "outcome.referral",
                                                            "-"
                                                          )}
                                                        </p>
                                                      )}
                                                    <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 margin-top--5-imp">
                                                      Comment :{" "}
                                                      {verifyObject(
                                                        k,
                                                        "outcome.comment",
                                                        "-"
                                                      )}
                                                    </p>
                                                    <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5 margin-top--5-imp">
                                                      Edited by {k.edited_by} On{" "}
                                                      {moment(k.date).format(
                                                        DATE_FORMAT_24_HR
                                                      )}
                                                    </p>
                                                  </div>
                                                );
                                              }
                                              return null;
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                <div className="push-10-t d-flex">
                                  {/* {(this.props
                                      .show_prescriotion_modal_from_notes ||
                                      this.props
                                        .show_prescriotion_modal_from_notes ===
                                        null) && ( */}
                                  {((this.props.prev_appt_tab_key ===
                                    "finished" ||
                                    this.props.prev_appt_tab_key ===
                                      "pre_consult_reviewed" ||
                                    this.props.prev_appt_tab_key ===
                                      "reviewed" ||
                                    this.props.prev_appt_tab_key ===
                                      "kiosk_reviewed") &&
                                    !this.state.isFinshedEdit &&
                                    verifyObject(
                                      consultationDetail,
                                      "consultation_log.prescription_plan",
                                      null
                                    ) === null &&
                                    verifyObject(
                                      consultationDetail,
                                      "consultation_log.edited_prescription_plan",
                                      null
                                    ) === null) ||
                                  consultationDetail === null ||
                                  verifyObject(
                                    consultationDetail,
                                    "consultation_log.prescription_plan",
                                    null
                                  ) === "" ? (
                                    havePharmacistDesignationCondition &&
                                    (!this.state.presriptionPlan ||
                                      this.state.presriptionPlan === "") ? (
                                      <button
                                        onClick={this.handleAddPrescription}
                                        disabled={
                                          this.state.updatingConsultationDetail
                                        }
                                        className="btn btn-custom btn-blue font-12 weight-500 submitBtn width-100px height-30 p-0 mr-1"
                                      >
                                        Add Prescription
                                      </button>
                                    ) : null
                                  ) : null}
                                  {/* )} */}
                                  {(this.props.userRole === ROLE_DOCTOR ||
                                    this.props.userRole ===
                                      ROLE_CLINICAL_PHARMACIST ||
                                    this.props.userRole ===
                                      ROLE_SUPERINTENDENT_PHARMACIST) &&
                                    (this.props.prev_appt_tab_key ===
                                      "unfinished" ||
                                      ((this.props.prev_appt_tab_key ===
                                        "finished" ||
                                        this.props.prev_appt_tab_key ===
                                          "pre_consult_reviewed" ||
                                        this.props.prev_appt_tab_key ===
                                          "reviewed") &&
                                        consultationDetail === null)) && (
                                      // (isShowSubmit || this.props.show_submit_from_notes) &&
                                      <div className="">
                                        <button
                                          onClick={() =>
                                            this._handleSubmit(false)
                                          }
                                          className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-255px height-30"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    )}
                                  {(this.props.userRole === ROLE_DOCTOR ||
                                    this.props.userRole ===
                                      ROLE_CLINICAL_PHARMACIST ||
                                    this.props.userRole ===
                                      ROLE_SUPERINTENDENT_PHARMACIST) &&
                                    (this.props.prev_appt_tab_key ===
                                      "finished" ||
                                      this.props.prev_appt_tab_key ===
                                        "pre_consult_reviewed" ||
                                      this.props.prev_appt_tab_key ===
                                        "reviewed" ||
                                      this.props.prev_appt_tab_key ===
                                        "kiosk_reviewed") &&
                                    !this.state.isFinshedEdit && (
                                      // (isShowSubmit || this.props.show_submit_from_notes) &&
                                      <div className="">
                                        <Button
                                          onClick={
                                            this.handleUpdateConsultation
                                          }
                                          className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-255px height-30 p-0 mr-1"
                                          disabled={
                                            this.state
                                              .updatingConsultationDetail
                                          }
                                          loading={
                                            this.state
                                              .updatingConsultationDetail
                                          }
                                        >
                                          Update Consultation
                                        </Button>
                                      </div>
                                    )}
                                  {(this.props.userRole === ROLE_DOCTOR ||
                                    this.props.userRole ===
                                      ROLE_CLINICAL_PHARMACIST ||
                                    this.props.userRole ===
                                      ROLE_SUPERINTENDENT_PHARMACIST) &&
                                    this.props.prev_appt_tab_key ===
                                      "unfinished" &&
                                    !verifyObject(
                                      this.props.appointmentObj,
                                      "pre_consult_id",
                                      null
                                    ) && (
                                      // !this.props.is_pharmsmart_user &&
                                      // (isShowSubmit || this.props.show_submit_from_notes) &&
                                      <div className="push-60-b">
                                        <button
                                          onClick={() =>
                                            this._handleSubmit(true)
                                          }
                                          className="btn btn-custom btn-blue font-12 weight-500 submitBtn width-100px height-30 p-0 ml-1"
                                        >
                                          Submit For Review
                                        </button>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <InformationalOutcomeModal
                              _handleSubmit={this._handleModalSubmission}
                              _hideModal={this._hideModal}
                              visible={visible}
                              changing={addingDetail}
                              submitting={this.state.addingDetail}
                              _handleTextChange={this._handleTextChange}
                              _handleUpdateoutcome={this._handleUpdateoutcome}
                              fromPreConsult={
                                this.props.prev_appt_tab_key ===
                                  "pre_consult_reviewed" ||
                                this.props.prev_appt_tab_key ===
                                  "kiosk_reviewed" ||
                                (verifyObject(
                                  this.props.appointmentObj,
                                  "pre_consult_id",
                                  null
                                )
                                  ? true
                                  : false)
                              }
                              prev_appt_tab_key={this.props.prev_appt_tab_key}
                              _handleReviewerChange={this._handleReviewerChange}
                              onIpUserSelect={this._onIpUserSelect}
                              current_corporate={this.props.current_corporate}
                              {...this.state}
                              fromConsultation={
                                (this.props.userRole === ROLE_DOCTOR ||
                                  this.props.userRole ===
                                    ROLE_CLINICAL_PHARMACIST ||
                                  this.props.userRole ===
                                    ROLE_SUPERINTENDENT_PHARMACIST) &&
                                (this.props.prev_appt_tab_key ===
                                  "unfinished" ||
                                  this.props.prev_appt_tab_key ===
                                    "finished") &&
                                consultationDetail === null
                              }
                              template_id={this.state.template_id}
                            />
                          </div>
                        </div>
                      </div>
                      {/* )} */}
                      {/* new integration end */}
                    </PerfectScrollbar>
                  </Pane>
                  <Pane initialSize={splitSize ? "53px" : "495px"}>
                    <div
                      className={`sectionscroll walkingappointmentsecscroll ${secondPanelClassName}`}
                    >
                      <div className="dragbtn">
                        <a
                          className="d-flex btn-updown"
                          onClick={this.handleButtonClick}
                        >
                          <span className="uprrow">
                            <CaretUpOutlined />
                          </span>
                          <span className="downarrow">
                            <CaretDownOutlined />
                          </span>
                        </a>
                      </div>
                      <div className="padding-left-10 padding-right-10">
                        <Fragment>
                          <Tabs
                            onChange={this.handleTabChange}
                            defaultActiveKey="finished"
                            activeKey={this.state.tabKey}
                            destroyInactiveTabPane={true}
                          >
                            <Tabs.TabPane
                              tab={
                                <div>
                                  <span className="icons_wrapper">
                                    <span className="icons_wrapper_img_box"></span>
                                    <span className="icons_wrapper_title">
                                      Prev.Encounters
                                      {this.state.tabKey ===
                                        "preencounters" && (
                                        <span className="in-block">
                                          {isLoadingPreEncounter
                                            ? ""
                                            : `(${verifyObject(
                                                paginationPreEncounter,
                                                "total",
                                                ""
                                              )})`}
                                        </span>
                                      )}
                                    </span>
                                  </span>
                                </div>
                              }
                              key={"preencounters"}
                              disabled={isLoading}
                            >
                              <PreEncountersContainer
                                _getPatientConultationDetails={
                                  this._getPatientConultationDetails
                                }
                              />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                              tab={
                                <div>
                                  <span className="icons_wrapper">
                                    <span className="icons_wrapper_img_box"></span>
                                    <span className="icons_wrapper_title">
                                      Prescriptions{" "}
                                      {this.state.tabKey ===
                                        "prescriptions" && (
                                        <span className="in-block">
                                          {isLoadingPrescription
                                            ? ""
                                            : `(${verifyObject(
                                                paginationPrescription,
                                                "total",
                                                ""
                                              )})`}
                                        </span>
                                      )}
                                    </span>
                                  </span>
                                </div>
                              }
                              key={"prescriptions"}
                              disabled={isLoading}
                            >
                              <PrescriptionsContainer
                                triggeredFromPast={this.state.triggered}
                                prev_appt_tab_key={this.props.prev_appt_tab_key}
                                consultation_obj={this.props.consultation_obj}
                              />
                            </Tabs.TabPane>{" "}
                            <Tabs.TabPane
                              tab={
                                <div>
                                  <span className="icons_wrapper">
                                    <span className="icons_wrapper_img_box"></span>
                                    <span className="icons_wrapper_title">
                                      Sick Note{" "}
                                      {this.state.tabKey === "sicknote" && (
                                        <span className="in-block">
                                          {isLoadingPrescription
                                            ? ""
                                            : `(${verifyObject(
                                                paginationPrescription,
                                                "total",
                                                ""
                                              )})`}
                                        </span>
                                      )}
                                    </span>
                                  </span>
                                </div>
                              }
                              key={"sicknote"}
                              disabled={isLoading}
                            >
                              <SickNotesContainer />
                            </Tabs.TabPane>{" "}
                            <Tabs.TabPane
                              tab={
                                <div>
                                  <span className="icons_wrapper">
                                    <span className="icons_wrapper_img_box"></span>
                                    <span className="icons_wrapper_title">
                                      Referral Note{" "}
                                      {this.state.tabKey === "referralnote" && (
                                        <span className="in-block">
                                          {isLoadingPrescription
                                            ? ""
                                            : `(${verifyObject(
                                                paginationPrescription,
                                                "total",
                                                ""
                                              )})`}
                                        </span>
                                      )}
                                    </span>
                                  </span>
                                </div>
                              }
                              key={"referralnote"}
                              disabled={isLoading}
                            >
                              <ReferralNotesContainer />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                              tab={
                                <div>
                                  <span className="icons_wrapper">
                                    <span className="icons_wrapper_img_box"></span>
                                    <span className="icons_wrapper_title">
                                      Screeenshots
                                    </span>
                                  </span>
                                </div>
                              }
                              key={"screenshots"}
                              disabled={isLoading}
                            >
                              <CallScreenShotContainer
                                fromConsultation={true}
                                // requestFirst={
                                //   dynamicInputs === null && history === ""
                                //     ? true
                                //     : false
                                // }
                              />
                            </Tabs.TabPane>
                          </Tabs>
                        </Fragment>
                        {/* <InformationalOutcomeModal
                          _handleSubmit={this._handleModalSubmission}
                          _hideModal={this._hideModal}
                          visible={visible}
                          changing={addingDetail}
                          _handleTextChange={this._handleTextChange}
                          _handleUpdateoutcome={this._handleUpdateoutcome}
                          {...this.state}
                        /> */}
                        {/*NOTE: commented add code as it's taken on screen, if requir in popup again need to uncomment popup and need some code revert for on dropdown selection of snomed code save button click functionality performed directly on selction */}
                      </div>
                    </div>
                  </Pane>
                </SplitPane>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    is_clicked_view: verifyObject(state, "localStore.is_clicked_view", false),
    preEnccounter: verifyObject(state, "preEnccounter", []),
    prescriptions: verifyObject(state, "referralNotes", []),
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", false),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    selectedSidebarKey: verifyObject(state, "dynamicTab.sidebar_key", ["1"]),

    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    isPrescriptionAdded: verifyObject(
      state.localStore,
      "is_prescription_added.is_presription_added",
      null
    ),
    isOwnPharamcy: verifyObject(
      state.localStore,
      "is_prescription_added.is_own_pharamcy",
      false
    ),
    prescriptionCode: verifyObject(
      state.localStore,
      "is_prescription_added.prescription_code",
      null
    ),
    patientDOB: verifyObject(state.localStore, "patient_detail_obj", null),

    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    appointment_tag: verifyObject(
      state.localStore,
      "appointment_obj.appointment_tag",
      null
    ),
    userRole: verifyObject(state, "localStore.user.role", null),
    userId: verifyObject(state, "localStore.user.id", null),
    is_from_patient_search_listing: verifyObject(
      state.localStore,
      "is_from_patient_search_listing",
      null
    ),
    patientDetail: verifyObject(state.localStore, "patient_detail_obj", null),
    is_gp_informed: verifyObject(state, "localStore.is_gp_informed", null),
    prescriptionAbbreviation: verifyObject(
      state,
      "prescriptionAbbreviation",
      []
    ),

    dynamicHealthInputs: verifyObject(
      state,
      "dynamicTab.dynamicHealthInputs",
      null
    ),
    systolic_bp: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.systolic_bp",
      ""
    ),
    diastolic_bp: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.diastolic_bp",
      ""
    ),
    pulse_per_minute: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.pulse_per_minute",
      ""
    ),
    respiratory_rate: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.respiratory_rate",
      ""
    ),
    blood_oxygen_saturation: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.blood_oxygen_saturation",
      ""
    ),
    body_temperature: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.body_temperature",
      ""
    ),
    height: verifyObject(state.dynamicTab, "dynamicHealthInputs.height", ""),
    weight: verifyObject(state.dynamicTab, "dynamicHealthInputs.weight", ""),
    bmi: verifyObject(state.dynamicTab, "dynamicHealthInputs.bmi", ""),

    patient_id: verifyObject(
      state.localStore,
      "patient_detail_obj.user.id",
      ""
    ),
    submitedPreConsultQuestionnaire: verifyObject(
      state.localStore,
      "dynamicInputs.submitedQuestionnairesArray",
      []
    ),
    reason_text: verifyObject(
      state,
      "dynamicTab.dynamicInputs.reason_text",
      ""
    ),
    reason_text_string: verifyObject(
      state,
      "dynamicTab.dynamicInputs.reason_text_string",
      ""
    ),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
    is_pharmsmart_user: verifyObject(
      state,
      "localStore.user.is_pharmsmart_user",
      null
    ),
    current_corporate: verifyObject(
      state,
      "localStore.user.corporate_id",
      null
    ),
    consultation_obj: verifyObject(state, "localStore.consultation_obj", null),
    patient_user_id: verifyObject(state, "localStore.patientId", null),
    gender: verifyObject(state.localStore, "patient_detail_obj.gender", ""),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {
        onRequestSetComponent,
        onRequestSetComponentID,
        onSetDynamicInput,
        onSetSidebarKey,
        onSetDynamicHealthInput,
      },
      dispatch
    ),
    navigationAction: bindActionCreators({navigateTo}, dispatch),
    preEncountersActions: bindActionCreators(preEncountersActions, dispatch),
    prescriptionAbbreviationActions: bindActionCreators(
      prescriptionAbbreviationActions,
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientConsultationContainer);

export default ConnectedComponent;
