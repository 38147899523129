import {
  ROLE_DOCTOR,
  ROLE_CLINICAL_PHARMACIST,
  ROLE_SUPERINTENDENT_PHARMACIST,
} from "constants/common";
import {get, post, patch, put} from "./index";

export const getPatients = (params, cancelToken) => {
  const request = {
    subUrl: `/doctor/patients.json`,
    params,
    cancelToken: cancelToken,
  };
  return get(request);
};

export const getpostalCodesData = (params) => {
  const request = {
    subUrl: `https://api.ideal-postcodes.co.uk/v1/postcodes/${params.search_code}?api_key=ak_ja9fkguyeUscgSFot7Wv6BsInQZyg`,
    data: params,
    isOnlyURL: true,
  };
  return get(request);
};
export const getOrganizationApi2 = (params) => {
  const request = {
    subUrl: `/organizations.json`,
    params,
  };
  return get(request);
};

export const getOrganizationApi = (params) => {
  const request = {
    subUrl: `/corporate_organizations/list_of_organizations.json`,
    params,
  };
  return get(request);
};

export const getSurgeriesApi = (params) => {
  const request = {
    subUrl: `/surgeries/search.json`,
    params,
  };
  return get(request);
};

export const addPatientData = (params) => {
  let bodyFromData = new FormData();
  if (params && params.is_child_account) {
    params.is_child_account &&
      bodyFromData.set("patient[is_child_account]", params.is_child_account);
    params.guardian_name &&
      bodyFromData.set("patient[guardian_name]", params.guardian_name);
    params.guardian_email &&
      bodyFromData.set("patient[guardian_email]", params.guardian_email);
    params.guardian_phone &&
      bodyFromData.set("patient[guardian_phone]", params.guardian_phone);
  }
  params.nhs_number &&
    bodyFromData.set("patient[nhs_number]", params.nhs_number);
  params.consentGiven &&
    bodyFromData.set("patient[is_consent]", params.consentGiven);
  params.emergency_number !== null &&
    params.emergency_number !== undefined &&
    params.emergency_number !== "" &&
    bodyFromData.set("patient[emergency_number]", params.emergency_number);
  params.first_name !== null &&
    params.first_name !== "" &&
    bodyFromData.set("user[first_name]", params.first_name);
  params.last_name !== null &&
    params.last_name !== "" &&
    bodyFromData.set("user[last_name]", params.last_name);
  params.email !== null &&
    params.email &&
    params.email !== "" &&
    bodyFromData.set("user[email]", params.email);
  // params.reason !== null &&
  //   params.reason !== "" &&
  //   bodyFromData.set("user[reason]", params.reason);
  params.reason_for_phone !== null &&
    params.reason_for_phone !== undefined &&
    params.reason_for_phone !== "" &&
    bodyFromData.set("user[reason_for_phone]", params.reason_for_phone);
  params.reason_for_email !== null &&
    params.reason_for_email !== undefined &&
    params.reason_for_email !== "" &&
    bodyFromData.set("user[reason_for_email]", params.reason_for_email);
  params.gender !== null &&
    params.gender !== "" &&
    bodyFromData.set("user[gender]", params.gender);
  params.phone !== null &&
    params.phone &&
    params.phone !== "" &&
    bodyFromData.set("user[phone]", params.phone);
  params.image !== null &&
    params.image !== "" &&
    bodyFromData.set("user[image]", params.image);
  params.date_of_birth !== null &&
    params.date_of_birth !== "" &&
    bodyFromData.set("patient[date_of_birth]", params.date_of_birth);
  params.alcohol !== null &&
    params.alcohol !== "" &&
    bodyFromData.set("patient[alcohol]", params.alcohol);
  params.blood_pressure !== null &&
    params.blood_pressure !== "" &&
    bodyFromData.set("patient[blood_pressure]", params.blood_pressure);
  params.bmi !== null &&
    params.bmi !== "" &&
    bodyFromData.set("patient[bmi]", params.bmi);
  params.height_category !== null &&
    params.height_category !== "" &&
    bodyFromData.set("patient[height_category]", params.height_category);
  params.height_value1 !== null &&
    params.height_value1 !== "" &&
    bodyFromData.set("patient[height_value1]", params.height_value1);
  params.height_value2 !== null &&
    params.height_value2 !== "" &&
    bodyFromData.set("patient[height_value2]", params.height_value2);
  params.weight_category !== null &&
    params.weight_category !== "" &&
    bodyFromData.set("patient[weight_category]", params.weight_category);
  params.weight_value1 !== null &&
    params.weight_value1 !== "" &&
    bodyFromData.set("patient[weight_value1]", params.weight_value1);
  params.weight_value2 !== null &&
    params.weight_value2 !== "" &&
    bodyFromData.set("patient[weight_value2]", params.weight_value2);
  params.smoke !== null &&
    params.smoke !== "" &&
    bodyFromData.set("patient[smoke]", params.smoke);
  params.address_name !== null &&
    params.address_name !== "" &&
    bodyFromData.set("address[name]", params.address_name);
  params.line1 !== null &&
    params.line1 !== "" &&
    bodyFromData.set("address[line1]", params.line1);
  params.line2 !== null &&
    params.line2 !== "" &&
    bodyFromData.set("address[line2]", params.line2);
  params.line3 !== null &&
    params.line3 !== "" &&
    bodyFromData.set("address[line3]", params.line3);
  params.town !== null &&
    params.town !== "" &&
    bodyFromData.set("address[town]", params.town);
  params.country !== null &&
    params.country !== "" &&
    bodyFromData.set("address[country]", params.country);
  params.county !== null &&
    params.county !== "" &&
    bodyFromData.set("address[county]", params.county);
  // params.organization_id !==null&&
  //   bodyFromData.set("patient[organization_id]", params.organization_id);
  params.security_question !== null &&
    params.security_question !== "" &&
    bodyFromData.set("patient[security_question]", params.security_question);
  params.security_answer !== null &&
    params.security_answer !== "" &&
    bodyFromData.set("patient[security_answer]", params.security_answer);
  params.pincode !== null &&
    params.pincode !== "" &&
    bodyFromData.set("address[pincode]", params.pincode);
  params.alcohol !== null &&
    params.alcohol !== "" &&
    bodyFromData.set("patient[alcohol]", params.alcohol);
  params.blood_pressure !== null &&
    params.blood_pressure !== "" &&
    bodyFromData.set("patient[blood_pressure]", params.blood_pressure);
  params.bmi !== null &&
    params.bmi !== "" &&
    bodyFromData.set("patient[bmi]", params.bmi);
  params.height_category !== null &&
    params.height_category !== "" &&
    bodyFromData.set("patient[height_category]", params.height_category);
  params.height_value1 !== null &&
    params.height_value1 !== "" &&
    bodyFromData.set("patient[height_value1]", params.height_value1);
  params.height_value2 !== null &&
    params.height_value2 !== "" &&
    bodyFromData.set("patient[height_value2]", params.height_value2);
  params.weight_category !== null &&
    params.weight_category !== "" &&
    bodyFromData.set("patient[weight_category]", params.weight_category);
  params.weight_value1 !== null &&
    params.weight_value1 !== "" &&
    bodyFromData.set("patient[weight_value1]", params.weight_value1);
  params.weight_value2 !== null &&
    params.weight_value2 !== "" &&
    bodyFromData.set("patient[weight_value2]", params.weight_value2);
  params.smoke !== null &&
    params.smoke !== "" &&
    bodyFromData.set("patient[smoke]", params.smoke);
  params.organization_id !== null &&
    params.organization_id !== "" &&
    bodyFromData.set("patient[organization_id]", params.organization_id);
  if (params.surgery_id) {
    params.surgery_id !== "" &&
      bodyFromData.set("surgery_id", params.surgery_id);
  }
  if (params.documents !== null && params.documents.length !== 0) {
    params.documents.map((doc, index) => {
      bodyFromData.set(`document[${index}][file]`, doc.originFileObj);
      return null;
    });
  }
  if (params.surgery_id) {
    bodyFromData.set("surgery_id", params.surgery_id);
  }
  if (params.is_pds_user) {
    bodyFromData.set("is_pds_user", params.is_pds_user);
  }
  const request = {
    subUrl: `/${
      params.userRole === ROLE_CLINICAL_PHARMACIST ||
      params.userRole === ROLE_SUPERINTENDENT_PHARMACIST ||
      params.userRole === ROLE_DOCTOR
        ? "prescribers"
        : "doctor"
    }/patients.json`,
    data: bodyFromData,
  };
  return post(request);
};
export const updatePatientData = (params, userRole) => {
  let bodyFromData = new FormData();

  Object.keys(params).forEach((key) => {
    const value = params[key];
    switch (key) {
      case "first_name":
        bodyFromData.set("user[first_name]", value);
        break;
      case "last_name":
        bodyFromData.set("user[last_name]", value);
        break;
      case "email":
        bodyFromData.set("user[email]", value);
        break;
      case "phone":
        bodyFromData.set("user[phone]", value);
        break;
      case "dob":
        bodyFromData.set("patient[date_of_birth]", value);
        break;
      case "gender":
        bodyFromData.set("user[gender]", value);
        break;

      case "is_child_account":
        bodyFromData.set("patient[is_child_account]", value);
        break;
      case "guardian_name":
        bodyFromData.set("patient[guardian_name]", value);
        break;
      case "guardian_email":
        bodyFromData.set("patient[guardian_email]", value);
        break;
      case "gender_preference":
        bodyFromData.set("patient[gender_preference]", value);
        break;
      case "ethnicity":
        bodyFromData.set("patient[ethnicity]", value);
        break;
      case "guardian_phone":
        bodyFromData.set("patient[guardian_phone]", value);
        break;
      case "emergency_number":
        bodyFromData.set("patient[emergency_number]", value);
        break;
      case "address_line_1":
        bodyFromData.set("address[line1]", value);
        break;
      case "address_line_2":
        bodyFromData.set("address[line2]", value);
        break;
      case "address_line_3":
        bodyFromData.set("address[line3]", value);
        break;
      case "city":
        bodyFromData.set("address[town]", value);
        break;
      case "country":
        bodyFromData.set("address[country]", value);
        break;
      case "county":
        bodyFromData.set("address[county]", value);
        break;
      case "organization_id":
        bodyFromData.set("patient[organization_id]", value);
        break;
      case "postal_code":
        bodyFromData.set("address[pincode]", value);
        break;
      case "surgery_id":
        bodyFromData.set("surgery_id", value === undefined ? null : value);
        break;
      case "reasonPhoneOption":
        bodyFromData.set(
          "user[reason_for_phone]",
          params.reasonPhoneOption === "other"
            ? params.reasonPhoneText
            : params.reasonPhoneOption
        );
        break;
      case "reasonEmailOption":
        bodyFromData.set(
          "user[reason_for_email]",
          params.reasonEmailOption === "other"
            ? params.reasonEmailText
            : params.reasonEmailOption
        );
        break;

      default:
        break;
    }
  });
  // params.is_child_account &&
  //   bodyFromData.set("patient[is_child_account]", params.is_child_account);
  // params.guardian_name &&
  //   bodyFromData.set("patient[guardian_name]", params.guardian_name);
  // params.guardian_email &&
  //   bodyFromData.set("patient[guardian_email]", params.guardian_email);
  // params.guardian_phone &&
  //   bodyFromData.set("patient[guardian_phone]", params.guardian_phone);

  // params.emergency_number !== null &&
  //   params.emergency_number !== undefined &&
  //   params.emergency_number !== "" &&
  //   bodyFromData.set("patient[emergency_number]", params.emergency_number);
  // params.first_name &&
  //   params.first_name !== null &&
  //   params.first_name !== "" &&
  //   bodyFromData.set("user[first_name]", params.first_name);
  // params.last_name &&
  //   params.last_name !== null &&
  //   params.last_name !== "" &&
  //   bodyFromData.set("user[last_name]", params.last_name);
  // params.email &&
  //   params.email !== null &&
  //   params.email !== "" &&
  //   bodyFromData.set("user[email]", params.email);
  // params.gender &&
  //   params.gender !== null &&
  //   params.gender !== "" &&
  //   bodyFromData.set("user[gender]", params.gender);
  // params.gender_preference &&
  //   params.gender_preference !== null &&
  //   params.gender_preference !== "" &&
  //   bodyFromData.set("patient[gender_preference]", params.gender_preference);
  // params.ethnicity &&
  //   params.ethnicity !== null &&
  //   params.ethnicity !== "" &&
  //   bodyFromData.set("patient[ethnicity]", params.ethnicity);
  // params.phone &&
  //   params.phone !== null &&
  //   params.phone !== "" &&
  //   bodyFromData.set("user[phone]", params.phone);
  // // bodyFromData.set("user[image]", params.image);
  // params.dob &&
  //   params.dob !== null &&
  //   params.dob !== "" &&
  //   bodyFromData.set("patient[date_of_birth]", params.dob);
  // params.address_line_1 &&
  //   params.address_line_1 !== null &&
  //   params.address_line_1 !== "" &&
  //   bodyFromData.set("address[line1]", params.address_line_1);
  // params.address_line_2 &&
  //   params.address_line_2 !== null &&
  //   params.address_line_2 !== "" &&
  //   bodyFromData.set("address[line2]", params.address_line_2);
  // params.address_line_3 &&
  //   params.address_line_3 !== null &&
  //   params.address_line_3 !== "" &&
  //   bodyFromData.set("address[line3]", params.address_line_3);
  // params.city && bodyFromData.set("address[town]", params.city);
  // // bodyFromData.set("address[city]", params.city);
  // params.country &&
  //   params.country !== null &&
  //   params.country !== "" &&
  //   bodyFromData.set("address[country]", params.country);
  // params.county &&
  //   params.county !== null &&
  //   params.county !== "" &&
  //   bodyFromData.set("address[county]", params.county);

  // params.organization_id &&
  //   params.organization_id !== null &&
  //   params.organization_id !== "" &&
  //   bodyFromData.set("patient[organization_id]", params.organization_id);
  // // params.security_question !== "" !==null&&
  // //   params.security_question !==null&&
  // //   bodyFromData.set("patient[security_question]", params.security_question);
  // // params.security_answer !== "" !==null&&
  // //   params.security_answer !==null&&
  // //   bodyFromData.set("patient[security_answer]", params.security_answer);
  // params.postal_code &&
  //   params.postal_code !== null &&
  //   params.postal_code !== "" &&
  //   bodyFromData.set("address[pincode]", params.postal_code);
  // // if (params.documents !==null&& params.documents.length !== 0) {
  // //   params.documents.map((doc, index) => {
  // //     bodyFromData.set(`document[${index}][file]`, doc.originFileObj);
  // //     return null;
  // //   });
  // // }
  // // params.alcohol &&
  // //   params.alcohol !== null &&
  // //   params.alcohol !== "" &&
  // //   bodyFromData.set("patient[alcohol]", params.alcohol);
  // // params.blood_pressure &&
  // //   params.blood_pressure !== null &&
  // //   params.blood_pressure !== "" &&
  // //   bodyFromData.set("patient[blood_pressure]", params.blood_pressure);
  // // params.bmi &&
  // //   params.bmi !== null &&
  // //   params.bmi !== "" &&
  // //   bodyFromData.set("patient[bmi]", params.bmi);
  // // params.height_category &&
  // //   params.height_category !== null &&
  // //   params.height_category !== "" &&
  // //   bodyFromData.set("patient[height_category]", params.height_category);
  // // params.height_value1 &&
  // //   params.height_value1 !== null &&
  // //   params.height_value1 !== "" &&
  // //   bodyFromData.set("patient[height_value1]", params.height_value1);
  // // params.height_value2 &&
  // //   params.height_value2 !== null &&
  // //   params.height_value2 !== "" &&
  // //   bodyFromData.set("patient[height_value2]", params.height_value2);
  // // params.weight_category &&
  // //   params.weight_category !== null &&
  // //   params.weight_category !== "" &&
  // //   bodyFromData.set("patient[weight_category]", params.weight_category);
  // // params.weight_value1 &&
  // //   params.weight_value1 !== null &&
  // //   params.weight_value1 !== "" &&
  // //   bodyFromData.set("patient[weight_value1]", params.weight_value1);
  // // params.weight_value2 &&
  // //   params.weight_value2 !== null &&
  // //   params.weight_value2 !== "" &&
  // //   bodyFromData.set("patient[weight_value2]", params.weight_value2);
  // // params.smoke &&
  // //   params.smoke !== null &&
  // //   params.smoke !== "" &&
  // //   bodyFromData.set("patient[smoke]", params.smoke);

  // if (params.surgery_id) {
  //   params.surgery_id !== "" &&
  //     bodyFromData.set("surgery_id", params.surgery_id);
  // }
  // params.reasonPhoneOption &&
  //   bodyFromData.set(
  //     "user[reason_for_phone]",
  //     params.reasonPhoneOption === "other"
  //       ? params.reasonPhoneText
  //       : params.reasonPhoneOption
  //   );
  // params.reasonEmailOption &&
  //   bodyFromData.set(
  //     "user[reason_for_email]",
  //     params.reasonEmailOption === "other"
  //       ? params.reasonEmailText
  //       : params.reasonEmailOption
  //   );
  const request = {
    subUrl: `${
      userRole === ROLE_CLINICAL_PHARMACIST ||
      userRole === ROLE_SUPERINTENDENT_PHARMACIST ||
      userRole === ROLE_DOCTOR
        ? `/prescribers/patients/${params.id}.json`
        : `/priory/doctors/patients/${params.id}.json`
    }`,
    data: bodyFromData,
  };
  return patch(request);
};

export const updatePatientDataForGP = (params) => {
  let bodyFromData = new FormData();

  params.surgery_id !== "" && bodyFromData.set("surgery_id", params.surgery_id);

  const request = {
    subUrl: `${
      params.userRole === ROLE_CLINICAL_PHARMACIST ||
      params.userRole === ROLE_SUPERINTENDENT_PHARMACIST ||
      params.userRole === ROLE_DOCTOR
        ? `/prescribers/patients/${params.id}.json`
        : `/priory/doctors/patients/${params.id}.json`
    }`,
    data: bodyFromData,
  };
  return patch(request);
};

export const updateMarketingOptions = (data) => {
  const request = {
    subUrl: `/users/notification_setting.json`,
    data,
  };
  return put(request);
};
export const sendOtpRequestPatient = (params) => {
  const request = {
    subUrl: `/doctor/patients/send_otp_for_patient_access.json`,
    params,
  };
  return get(request);
};
export const verifyOtpRequestPatient = (data) => {
  const request = {
    subUrl: `/doctor/patients/verify_otp_for_patient_access.json`,
    data: data,
  };
  return post(request);
};
export const addCallNotesAPI = (data) => {
  const request = {
    subUrl: `/customer_issue_notes.json`,
    data,
  };
  return post(request);
};

export const verifyPatientAPI = (params, patient_id) => {
  const request = {
    subUrl: `/users/${patient_id}/status`,
    data: params,
  };
  return put(request);
};

export const searchViaNHSNumberAPI = (params) => {
  const request = {
    subUrl: `/doctor/patients/pds_search_patients.json`,
    params,
  };
  return get(request);
};

export const searchViaPDSAPI = (params) => {
  const request = {
    subUrl: `/doctor/patients/pds_seach_patients.json`,
    params,
  };
  return get(request);
};
