import React, { useEffect } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import OpenFullScreen from "assets/images/common/open-full-screen.svg";
import CloseFullScreen from "assets/images/common/close-full-screen.svg";
import store from "../../app/store";
import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";

function FullScreenHook({
  children,
  isOutFromFullscreen,
  CloseFullScreen,
  toggleFullScreen,
}) {
  const handle = useFullScreenHandle();

  useEffect(() => {
    // if (handle.active) {
    handle.exit();
    // }
  }, [isOutFromFullscreen]);

  return (
    <div className="full-screen-wrapper">
      <span className="calendercontrols full-screen-parent d-inline-flex">
        <button
          type="button"
          className="ant-btn full-screen-button"
          title="Enter Full Screen"
          onClick={() => {
            store.dispatch({
              type: "OUT_FULL_SCREEN",
              payload: false,
            });
            handle.enter();
            CloseFullScreen();
            toggleFullScreen();
          }}
        >
          <FullscreenOutlined />
          {/* <img

            className=""
            src={OpenFullScreen}
            alt="Enter Full Screen"
          /> */}
        </button>
      </span>
      <FullScreen handle={handle}>
        {handle.active && (
          <span className="calendercontrols full-screen-parent full-exit-screen-parent d-inline-flex">
            <button
              type="button"
              className="ant-btn full-screen-button"
              title="Exit Full Screen"
              onClick={() => {
                handle.exit();
                toggleFullScreen();
              }}
            >
              <FullscreenExitOutlined />
              {/* <img

              className=""
              src={OpenFullScreen}
              alt="Enter Full Screen"
            /> */}
            </button>
            {/* <img
            onClick={() => {
              handle.exit();
            }}
            className="full-screen-btn"
            style={{ marginTop: 3 }}
            src={CloseFullScreen}
            alt="Enter Full Screen"
          /> */}
          </span>
        )}
        {children}
      </FullScreen>
    </div>
  );
}

export default FullScreenHook;