/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
// import header_logo from "assets/images/header/logo-circle.png";
import { connect } from "react-redux";
import {
  logOut,
  navigateToCustomerSelectionViaOTP,
} from "../../reducers/session";

import { bindActionCreators } from "redux";
import {
  Menu,
  Dropdown,
  Layout,
  Tag,
  Button,
  // Typography,
  message,
} from "antd";
import { Dropdown as HeaderDropDown } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { navigateTo, appRoutesConst } from "../../app/navigation";
import {
  errorToast,
  generateTokenFromJWT,
  getErrorObject,
  isValidJSONString,
  successToast,
  verifyObject,
} from "../../utilities/utils";
import { actions as actionCableActions } from "reducers/actionCableData";
import store from "../../app/store";
import { MenuOutlined, CaretDownFilled } from "@ant-design/icons";
import actionCable from "actioncable";
import {
  ROLE_CS_EXECUTIVE,
  ROLE_DOCTOR,
  ROLE_HCSW,
  ROLE_NURSE,
  ROLE_CLINICAL_PHARMACIST,
  ROLE_SUPERINTENDENT_PHARMACIST,
} from "../../constants/common";
import { onCollapseExpandSidebar } from "../../reducers/dynamicTab";
import { baseWebSocketURL } from "../../services";
import ModalPopUp from "components/common/ModalPopUp";
import PDFViewer from "components/PdfViewer/PDFViewer";
import GuidePDF from "../../assets/files/Guide.pdf";
import { Tooltip } from "@material-ui/core";
import SessionExpirationModal from "components/session/SessionExpirationModal";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  fetchQRCodesAPI,
  getNotificationsApi,
  markAsReadNotificationApi,
} from "services/notifications";
import { Spinner } from "components/common";
import {
  fetchCorporatesFromEmail,
  redirectLoginToDP,
  switchCorporateApi,
} from "services/session";
import ConsultSmartLogo from "../../assets/images/header/consultsmart-logo.svg";
import {
  onSetDynamicInput,
  onSetDynamicHealthInput,
} from "../../reducers/dynamicTab";
// import {PrinterFilled} from "@ant-design/icons";
// import printJS from "print-js";
import PrintComponent from "./PDFToPrint";
import { isArray } from "lodash";
// import {isEmpty} from "lodash";
// import socketIO from 'socket.io-client';

// const socket = socketIO.connect('http://localhost:4000');
// const {Text} = Typography;
class Header extends React.Component {
  // = ({ user, sessionActions })
  constructor(props) {
    super(props);
    this.state = {
      scriptReviewCount: null,
      questionnaireCount: null,
      reportsCount: null,
      appointmentCounter: null,
      loading: false,
      isOpen: false,
      markingRead: false,
      page: 1,
      ids: [],
      data: {},
      pagination: null,
      readMarked: false,
      isLoading: false,
      isShow: false,
      total: localStorage.getItem("notificationTotal")
        ? localStorage.getItem("notificationTotal")
        : null,
      total_pages: null,
      isSidebarCollapsed: false,
      notifications: [],
      consultationReviewCount: null,
      pending_pre_consult_count: null,
      organization_orders_count: null,

      not_started_pre_consult_count: null,
      in_review_pre_consult_count: null,
      reviewed_pre_consult_count: null,
      fetchingQrCode: false,
      qrCodeData: [],
      showDropdown: false,
      showCorporateDropdown: false,
      corporateList: [],
      currentCorporateId: null,
      switchingCorporate: false,
      fetchingCorporate: false,
    };
    ["_renderMenuItems", "_onLogOut", "_handleNavigation"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.counterChannle = actionCable.createConsumer(
      `${baseWebSocketURL}/cable`
    );
  }

  componentDidMount() {
    /* receive */
    window.addEventListener("storage", this.handleInvalidToken);
    // let {
    //   // PendingQuestionnaireChannel,
    //   // AppointmentCounterChannel,
    //   // ScriptreviewCounterChannel,
    // } = this.props.actionCableActions;
    // if (typeof PendingQuestionnaireChannel === "function") {
    //   PendingQuestionnaireChannel();
    // }
    // if (typeof AppointmentCounterChannel === "function") {
    //   AppointmentCounterChannel();
    // }
    // if (typeof ScriptreviewCounterChannel === "function") {
    //   ScriptreviewCounterChannel();
    // }
    const { user } = this.props;
    if (user?.corporate_id) {
      this.setState({
        currentCorporateId: user?.corporate_id,
        switchingCorporate: false,
      });
    }
    this.connectWaitingRoomChannel();
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleInvalidToken);
  }
  handleInvalidToken = (e) => {
    console.log("Header line no 154 handleInvalidToken", e?.newValue);
    console.log("Header line no 155 handleInvalidToken", e?.oldValue);
    // var bc = new BroadcastChannel("test_channel");

    // bc.postMessage("This is a test message."); /* send */

    if (e.key === "login_message") {
      localStorage.removeItem("login_message");
    }

    if (
      e?.newValue &&
      isValidJSONString(e?.newValue) &&
      e.key !== "login_message"
    ) {
      let lcn = JSON.parse(e?.newValue)?.localStore;
      if (lcn?.token === null) {
        window.location.reload();
      }
    } else {
      console.log("Invalid JSON from localstorage");
    }
  };
  connectWaitingRoomChannel = async (parmas) => {
    // const appointmentChannle = await this.counterChannle.subscriptions.create(
    //   {
    //     channel: "AppointmentCounterChannel",
    //     auth_token: store.getState().localStore.token,
    //     ...parmas,
    //   },
    //   {
    //     connected: (d) => {
    //       console.log("------- AppointmentChannel Socket connected-------");
    //     },
    //     received: (data) => {
    //       console.log("-------AppointmentChannel Received-------", data);

    //       if (data && data.appointment_count) {
    //         this.setState({ appointmentCounter: data.appointment_count });
    //       }
    //     },
    //   }
    // );
    const scriptReviewChannle = await this.counterChannle.subscriptions.create(
      {
        channel: "ScriptreviewCounterChannel",
        auth_token: store.getState().localStore.token,
        ...parmas,
      },
      {
        connected: (d) => {
          console.log("------- scriptreivew_count Socket connected-------");
        },
        received: (data) => {
          if (data) {
            console.log(
              "-------consultation_review_count Received-------",
              data
            );

            if (
              data?.scriptreivew_count &&
              !data?.consultation_review_count &&
              !data?.pending_pre_consult_count &&
              !data?.organization_orders_count
            ) {
              console.log(
                "-------scriptreivew_count Received-------",
                data?.scriptreivew_count
              );
              this.setState({
                scriptReviewCount: verifyObject(data, "scriptreivew_count", ""),
              });
            }
            // if (
            //   !data?.scriptreivew_count &&
            //   data?.consultation_review_count &&
            //   !data?.pending_pre_consult_count &&
            //   !data?.organization_orders_count
            // ) {
            //   console.log(
            //     "-------consultation_review_count Received-------",
            //     data?.consultation_review_count
            //   );
            //   this.setState({
            //     consultationReviewCount: verifyObject(
            //       data,
            //       "consultation_review_count",
            //       ""
            //     ),
            //   });
            // }
            if (
              !data?.scriptreivew_count &&
              !data?.consultation_review_count &&
              data?.organization_orders_count &&
              !data?.pending_pre_consult_count
            ) {
              console.log(
                "-------organization_orders_count Received-------",
                data?.organization_orders_count
              );
              this.setState({
                organization_orders_count: verifyObject(
                  data,
                  "organization_orders_count",
                  ""
                ),
              });
            }
            // if (
            //   !data?.scriptreivew_count &&
            //   !data?.consultation_review_count &&
            //   data?.pending_pre_consult_count &&
            //   !data?.organization_orders_count
            // ) {
            //   console.log(
            //     "-------pending_pre_consult_count Received-------",
            //     data?.pending_pre_consult_count
            //   );
            //   this.setState({
            //     pending_pre_consult_count: verifyObject(
            //       data,
            //       "pending_pre_consult_count",
            //       ""
            //     ),
            //   });
            // }

            // pending_pre_consult_count

            if (
              data?.pending_pre_consult_count === null ||
              data?.pending_pre_consult_count === 0
            ) {
              this.setState(
                {
                  pending_pre_consult_count: 0,
                },
                () => {
                  store.dispatch({
                    type: "PENDING_PRE_CONSULT_COUNT",
                    payload: 0,
                  });
                }
              );
            } else if (data?.pending_pre_consult_count) {
              this.setState(
                {
                  pending_pre_consult_count: verifyObject(
                    data,
                    "pending_pre_consult_count",
                    0
                  ),
                },
                () => {
                  store.dispatch({
                    type: "PENDING_PRE_CONSULT_COUNT",
                    payload: data?.pending_pre_consult_count,
                  });
                }
              );
            }

            // not_started_pre_consult_count

            if (
              data?.not_started_pre_consult_count === null ||
              data?.not_started_pre_consult_count === 0
            ) {
              this.setState(
                {
                  not_started_pre_consult_count: 0,
                },
                () => {
                  store.dispatch({
                    type: "NOT_STARTED_PRE_CONSULT_COUNT",
                    payload: 0,
                  });
                }
              );
            } else if (data?.not_started_pre_consult_count) {
              this.setState(
                {
                  not_started_pre_consult_count: verifyObject(
                    data,
                    "not_started_pre_consult_count",
                    0
                  ),
                },
                () => {
                  store.dispatch({
                    type: "NOT_STARTED_PRE_CONSULT_COUNT",
                    payload: data?.not_started_pre_consult_count,
                  });
                }
              );
            }

            // in_review_pre_consult_count
            if (
              data?.in_review_pre_consult_count === null ||
              data?.in_review_pre_consult_count === 0
            ) {
              this.setState(
                {
                  in_review_pre_consult_count: 0,
                },
                () => {
                  store.dispatch({
                    type: "IN_REVIEW_PRE_CONSULT_COUNT",
                    payload: 0,
                  });
                }
              );
            } else if (data?.in_review_pre_consult_count) {
              this.setState(
                {
                  in_review_pre_consult_count: verifyObject(
                    data,
                    "in_review_pre_consult_count",
                    0
                  ),
                },
                () => {
                  store.dispatch({
                    type: "IN_REVIEW_PRE_CONSULT_COUNT",
                    payload: data?.in_review_pre_consult_count,
                  });
                }
              );
            }
            // reviewed_pre_consult_count

            if (
              data?.reviewed_pre_consult_count === null ||
              data?.reviewed_pre_consult_count === 0
            ) {
              this.setState(
                {
                  reviewed_pre_consult_count: 0,
                },
                () => {
                  store.dispatch({
                    type: "REVIEWED_PRE_CONSULT_COUNT",
                    payload: 0,
                  });
                }
              );
            } else if (data?.reviewed_pre_consult_count) {
              this.setState(
                {
                  reviewed_pre_consult_count: verifyObject(
                    data,
                    "reviewed_pre_consult_count",
                    0
                  ),
                },
                () => {
                  store.dispatch({
                    type: "REVIEWED_PRE_CONSULT_COUNT",
                    payload: data?.reviewed_pre_consult_count,
                  });
                }
              );
            }

            console.log("ALL COUNTS----", this.state);
          }
        },
      }
    );
    const ConsultationReviewCounterChannel =
      await this.counterChannle.subscriptions.create(
        {
          channel: "ConsultationReviewCounterChannel",
          auth_token: store.getState().localStore.token,
          ...parmas,
        },
        {
          connected: (d) => {
            console.log(
              "------- ConsultationReviewCounterChannel Socket connected-------"
            );
          },
          received: (data) => {
            if (data) {
              console.log(
                "-------ConsultationReviewCounterChannel Received-------",
                data
              );

              if (data?.consultation_review_count) {
                console.log(
                  "-------consultation_review_count Received-------",
                  data?.consultation_review_count
                );
                this.setState({
                  consultationReviewCount: verifyObject(
                    data,
                    "consultation_review_count",
                    ""
                  ),
                });
              }
            }
          },
        }
      );

    const pquestionnaireChannle =
      await this.counterChannle.subscriptions.create(
        {
          channel: "PendingQuestionnaireChannel",
          auth_token: store.getState().localStore.token,
          ...parmas,
        },
        {
          connected: (d) => {
            // console.log(
            //   "------- PendingQuestionnaireChannel Socket connected-------"
            // );
          },
          received: (data) => {
            // console.log(
            //   "-------PendingQuestionnaireChannel Received-------",
            //   data
            // );

            if (data) {
              this.setState({
                questionnaireCount: verifyObject(
                  data,
                  "pending_questionnaire",
                  ""
                ),
              });
            }
            if (data) {
              // console.log("data.pending_report", data.pending_report);
              this.setState({
                reportsCount: verifyObject(data, "pending_report", ""),
              });
            }
          },
        }
      );
    // getConsultationRefferalNotes.lo
    console.log("pquestionnaireChannle", pquestionnaireChannle);
    console.log("scriptReviewChannle", scriptReviewChannle);
    console.log(
      "ConsultationReviewCounterChannel",
      ConsultationReviewCounterChannel
    );
  };

  _onLogOut() {
    let {
      sessionActions: { logOut },
    } = this.props;

    if (typeof logOut === "function") {
      logOut();
      // document.oncontextmenu = document.oncontextmenu = function () {
      //   return true;
      // };
      // document.oncopy = document.oncopy = function () {
      //   return true;
      // };
      // document.ondragstart = document.ondragstart = function (e) {
      //   e.preventDefault();
      // };
    }
    localStorage.removeItem("scriptReviewStatus");
  }

  _renderMenuItems() {
    let { userRole, user } = this.props;
    const isPharmSmartUser = user?.is_pharmsmart_user;
    return (
      <Menu className="usermenu_dropdown width-190">
        <Menu.Item
          onClick={() => this._handleNavigation(appRoutesConst.profile)}
        >
          My Profile
        </Menu.Item>
        {(userRole !== ROLE_CLINICAL_PHARMACIST ||
          userRole === ROLE_CLINICAL_PHARMACIST ||
          userRole === ROLE_SUPERINTENDENT_PHARMACIST) && (
          <Menu.Item
            onClick={() => this._handleNavigation(appRoutesConst.rota)}
          >
            Rota
          </Menu.Item>
        )}
        {userRole !== ROLE_HCSW &&
          userRole !== ROLE_CS_EXECUTIVE &&
          userRole !== ROLE_NURSE &&
          (userRole !== ROLE_CLINICAL_PHARMACIST ||
            userRole === ROLE_CLINICAL_PHARMACIST ||
            userRole === ROLE_SUPERINTENDENT_PHARMACIST) && (
            <Menu.Item
              onClick={() => this._handleNavigation(appRoutesConst.payments)}
              disabled={isPharmSmartUser}
            >
              Payments
            </Menu.Item>
          )}
        {(userRole !== ROLE_CLINICAL_PHARMACIST ||
          userRole === ROLE_CLINICAL_PHARMACIST ||
          userRole === ROLE_SUPERINTENDENT_PHARMACIST) &&
          !this.props.isChildCorporte && (
            <Menu.Item
              onClick={() =>
                this._handleNavigation(appRoutesConst.staffInfranet)
              }
              disabled={isPharmSmartUser}
            >
              Staff Intranet
            </Menu.Item>
          )}
        {console.log("userRole", userRole)}
        {(userRole !== ROLE_CLINICAL_PHARMACIST ||
          userRole === ROLE_CLINICAL_PHARMACIST ||
          userRole === ROLE_SUPERINTENDENT_PHARMACIST) && (
          <Menu.Item
            onClick={() => this._handleNavigation(appRoutesConst.rightToWork)}
            disabled={isPharmSmartUser}
          >
            Right To Work
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() =>
            this._handleNavigation(appRoutesConst.doctorchangepassword)
          }
          disabled={isPharmSmartUser}
        >
          Change Password
        </Menu.Item>
        {(userRole !== ROLE_CLINICAL_PHARMACIST ||
          userRole === ROLE_CLINICAL_PHARMACIST ||
          userRole === ROLE_SUPERINTENDENT_PHARMACIST) && (
          <Menu.Item
            onClick={() => this._handleNavigation(appRoutesConst.messages)}
          >
            Messages
          </Menu.Item>
        )}
        {(userRole !== ROLE_CLINICAL_PHARMACIST ||
          userRole === ROLE_CLINICAL_PHARMACIST ||
          userRole === ROLE_SUPERINTENDENT_PHARMACIST) && (
          <Menu.Item
            onClick={() =>
              this._handleNavigation(appRoutesConst.callNetworkTest)
            }
          >
            Call Network Test
          </Menu.Item>
        )}

        {!isPharmSmartUser && (
          <Menu.Item onClick={this._onLogOut} danger>
            Logout
          </Menu.Item>
        )}
      </Menu>
    );
  }
  _handleNavigation(path) {
    let { navigateTo } = this.props.navigationAction;
    let currentPathname = window.location.pathname;
    console.log("navigating", path);
    localStorage.removeItem("intakedFilters");
    localStorage.removeItem("upcomingFilters");
    localStorage.removeItem("previousFilters");
    localStorage.removeItem("approveRejectFilters");
    localStorage.removeItem("intakedFiltersNewQuestionnarie");
    localStorage.removeItem("intakedFiltersPastQuestionnarie");
    localStorage.removeItem("intakedFiltersNewReports");
    localStorage.removeItem("intakedFiltersPastReports");
    if (currentPathname === appRoutesConst.videoAppointment) {
      let r = window.confirm("Are you sure want to leave this page");
      if (r === true) {
        store.dispatch({
          type: "IS_VIDEO_CALL_STARTED",
          payload: false,
        });
        store.dispatch({
          type: "ACTUAL_CALL_STARTED",
          payload: false,
        });
        store.dispatch({
          type: "SET_INTIAL_SOCKET_DATA",
          payload: null,
        });
        store.dispatch({
          type: "ON_SET_PATIENT_APPOINTMENT",
          payload: null,
        });
        store.dispatch({
          type: "IS_SCREENSHOT_TAKEN",
          payload: 0,
        });
        store.dispatch({
          type: "SET_APPOINTMENT_REASON_DATA",
          payload: null,
        });
        store.dispatch({
          type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
          payload: null,
        });
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        navigateTo(appRoutesConst.dashboard);
      } else {
        if (typeof navigateTo === "function") {
          navigateTo(appRoutesConst.videoAppointment);
        }
      }
    } else if (currentPathname === appRoutesConst.walkinAppointment) {
      let r = window.confirm("Are you sure want to leave this page");
      if (r === true) {
        store.dispatch({
          type: "SET_OFFLINE_CALL_STATUS",
          payload: null,
        });
        store.dispatch({
          type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
          payload: null,
        });
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        navigateTo(appRoutesConst.dashboard);
      } else {
        if (typeof navigateTo === "function") {
          navigateTo(appRoutesConst.walkinAppointment);
        }
      }
    } else {
      if (typeof navigateTo === "function") {
        if (typeof navigateTo === "function") {
          // alert("In ")
          let { isInErrorBoundry } = this.props;
          if (isInErrorBoundry) {
            store.dispatch({
              type: "IN_ERROR_BOUNDRY",
              payload: false,
            });
            window.location.replace(path);
          } else {
            navigateTo(path);
          }
        }
      }
    }
  }

  handleMenuChange = async (id) => {
    let currentPathname = window.location.pathname;
    let { prev_appt_tab_key } = this.props;

    let r;
    if (
      currentPathname === appRoutesConst.videoAppointment ||
      currentPathname === appRoutesConst.walkinAppointment ||
      (prev_appt_tab_key === "unfinished" &&
        window.location.pathname === "/patient/consultation_detail")
    ) {
      r = window.confirm("Are you sure you want to leave this page?");
      if (!r) {
        // User clicked "Cancel", return early to stop execution
        return;
      }
    }
    store.dispatch({
      type: "SET_POST_GT_QUESTIONNAIRE",
      payload: null,
    });
    let { onCollapseExpandSidebar } = this.props.handleSidebar;
    let { onSetDynamicHealthInput, onSetDynamicInput } =
      this.props.dynamicTabAction;

    if (typeof onCollapseExpandSidebar === "function") {
      onCollapseExpandSidebar(false);
    }
    if (!window.location.pathname.includes(id)) {
      localStorage.removeItem("intakedFilters");
      localStorage.removeItem("upcomingFilters");
      localStorage.removeItem("previousFilters");
      localStorage.removeItem("approveRejectFilters");
      localStorage.removeItem("intakedFiltersNewQuestionnarie");
      localStorage.removeItem("intakedFiltersPastQuestionnarie");
      localStorage.removeItem("intakedFiltersNewReports");
      localStorage.removeItem("intakedFiltersPastReports");
    }

    // let currentPathname = window.location.pathname;
    // alert(currentPathname)
    if (id === "preconsultationReview") {
      store?.dispatch({
        type: "PRE_CONSULT_TAB_KEY",
        payload: "pending",
      });
    }
    // if(currentPathname === '/doctor')
    let { navigateTo } = this.props.navigationAction;

    if (currentPathname === appRoutesConst.videoAppointment) {
      // var r = window.confirm("Are you sure want to leave this page");
      if (r === true) {
        if (
          typeof onSetDynamicHealthInput === "function" &&
          typeof onSetDynamicInput === "function"
        ) {
          await onSetDynamicHealthInput(null);
          await onSetDynamicInput(null);
        }
        store.dispatch({
          type: "IS_VIDEO_CALL_STARTED",
          payload: false,
        });
        store.dispatch({
          type: "ACTUAL_CALL_STARTED",
          payload: false,
        });
        store.dispatch({
          type: "SET_INTIAL_SOCKET_DATA",
          payload: null,
        });
        store.dispatch({
          type: "ON_SET_PATIENT_APPOINTMENT",
          payload: null,
        });
        store.dispatch({
          type: "IS_SCREENSHOT_TAKEN",
          payload: 0,
        });
        store.dispatch({
          type: "SET_APPOINTMENT_REASON_DATA",
          payload: null,
        });
        store.dispatch({
          type: "ON_SET_PATIENT_APPOINTMENT",
          payload: null,
        });
        store.dispatch({
          type: "SET_PATIENT_DETAIL",
          payload: null,
        });
        store.dispatch({ type: "PREV_APPT_TAB_KEY", payload: "finished" });
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        store.dispatch({ type: "SET_IS_GP_INFORMED", payload: false });
        store.dispatch({
          type: "IS_AUTOSAVE_CONSULTATION_DATA",
          payload: false,
        });
        if (id === "dashboard") {
          navigateTo(appRoutesConst.dashboard);
        } else {
          navigateTo(appRoutesConst[id]);
        }
      } else {
        if (typeof navigateTo === "function") {
          navigateTo(appRoutesConst.videoAppointment);
        }
      }
    } else if (
      currentPathname === appRoutesConst.walkinAppointment ||
      (prev_appt_tab_key === "unfinished" &&
        window.location.pathname === "/patient/consultation_detail")
    ) {
      // let r = window.confirm("Are you sure want to leave this page");
      if (r === true) {
        if (
          typeof onSetDynamicHealthInput === "function" &&
          typeof onSetDynamicInput === "function"
        ) {
          await onSetDynamicHealthInput(null);
          await onSetDynamicInput(null);
        }
        store.dispatch({
          type: "SET_OFFLINE_CALL_STATUS",
          payload: null,
        });
        store.dispatch({
          type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
          payload: null,
        });
        store.dispatch({ type: "PREV_APPT_TAB_KEY", payload: "finished" });
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        store.dispatch({
          type: "ON_SET_PATIENT_APPOINTMENT",
          payload: null,
        });
        store.dispatch({
          type: "SET_PATIENT_DETAIL",
          payload: null,
        });
        store.dispatch({ type: "SET_IS_GP_INFORMED", payload: false });
        store.dispatch({
          type: "IS_AUTOSAVE_CONSULTATION_DATA",
          payload: false,
        });
        if (id === "dashboard") {
          navigateTo(appRoutesConst.dashboard);
        } else {
          navigateTo(appRoutesConst[id]);
        }
      } else {
        if (typeof navigateTo === "function") {
          navigateTo(
            prev_appt_tab_key === "unfinished"
              ? appRoutesConst.patientConsultationDetail
              : appRoutesConst.walkinAppointment
          );
        }
      }
    } else {
      // navigateTo(appRoutesConst[id]);
      let { isInErrorBoundry } = this.props;
      if (isInErrorBoundry) {
        store.dispatch({
          type: "IN_ERROR_BOUNDRY",
          payload: false,
        });
        store.dispatch({ type: "SET_IS_GP_INFORMED", payload: false });

        window.location.href = appRoutesConst[id];
      } else {
        if (
          typeof onSetDynamicHealthInput === "function" &&
          typeof onSetDynamicInput === "function"
        ) {
          await onSetDynamicHealthInput(null);
          await onSetDynamicInput(null);
        }
        if (id === "dashboard") {
          store.dispatch({ type: "PREV_APPT_TAB_KEY", payload: "finished" });
        } else if ("consultationReview") {
          store.dispatch({ type: "PREV_APPT_TAB_KEY", payload: "in_review" });
        } else if ("preconsultationReview") {
          store.dispatch({ type: "PREV_APPT_TAB_KEY", payload: "pending" });
        } else if (id === "patientSearch") {
          store.dispatch({ type: "PREV_APPT_TAB_KEY", payload: "finished" });
        } else if (id === "scriptReview") {
          store.dispatch({ type: "PREV_APPT_TAB_KEY", payload: "in_review" });
        }
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        store.dispatch({
          type: "ON_SET_PATIENT_APPOINTMENT",
          payload: null,
        });
        store.dispatch({
          type: "SET_PATIENT_DETAIL",
          payload: null,
        });
        store.dispatch({ type: "SET_IS_GP_INFORMED", payload: false });
        store.dispatch({
          type: "IS_AUTOSAVE_CONSULTATION_DATA",
          payload: false,
        });

        navigateTo(appRoutesConst[id]);
      }
    }

    // let elementAll = document.querySelectorAll(".menu-link");
    // elementAll.forEach((node) => {
    //   node.classList.remove("active_menu");
    // });
    // let element = document.getElementById(id);
    // element.classList.add("active_menu");
  };
  componentWillReceiveProps(nextProps) {
    // console.log(
    //   "PendingQuestionnaireCounters",
    //   nextProps.PendingQuestionnaireCounters
    // );
  }
  handleCollapsiable = () => {
    let { isCollapsed } = this.props;
    let { onCollapseExpandSidebar } = this.props.handleSidebar;
    if (typeof onCollapseExpandSidebar === "function") {
      onCollapseExpandSidebar(!isCollapsed);
    }
  };

  handleNotification = () => {
    // window.scrollTo(0,
    //   this.findPosition(document.getElementById("scrolling_div")));
    this.setState(
      {
        isOpen: !this.state.isOpen,
        page: 1,
        ids: [],
        data: {},
        total: null,
        total_pages: null,
      },
      () => {
        this.GetNotificationsWithLoadMore(this.state.page, false);
      }
    );
  };
  handleMarkAsRead = async (id) => {
    try {
      await this.setState({
        markingRead: true,
        loading: true,
        readMarked: true,
        page: 1,
      });
      await this.setState({ markingRead: false });
      await markAsReadNotificationApi({ id });
      await this.setState({ markingRead: false });
      await this.GetNotificationsWithLoadMore(1, false);
      // await this.props.actionCableActions.customerSupportChannel()
    } catch (error) {
      const { message } = getErrorObject(error);
      await this.setState({ markingRead: false, loading: false });

      errorToast({ content: message });
    }
  };

  GetNotificationsWithLoadMore = async (page, isLoadmore) => {
    try {
      await this.setState({ loading: true });
      const response = await getNotificationsApi({ page });
      await this.setState({ loading: false });
      if (response && response.data.data) {
        if (isLoadmore) {
          // console.log("NOTI RE", JSON.parse(response.headers["x-pagination"]));
          this.setState({
            notifications: [...this.state.notifications, ...response.data.data],
            // pagination: JSON.parse(response.headers["x-pagination"]),
            total: JSON.parse(response.headers["x-pagination"]).total,
            total_pages: JSON.parse(response.headers["x-pagination"])
              .total_pages,
          });
          localStorage.setItem("notificationTotal", this.state.total);
        } else {
          this.setState({
            notifications: response.data.data,
            total: JSON.parse(response.headers["x-pagination"]).total,
            total_pages: JSON.parse(response.headers["x-pagination"])
              .total_pages,
          });
          localStorage.setItem("notificationTotal", this.state.total);
        }
      }
    } catch (error) {
      if (error) {
        if (error.data.status === 950 || error.data.status === 419) {
          localStorage.removeItem("state");
          return false;
        } else {
          const { message } = getErrorObject(error);
          await this.setState({ loading: false });
          errorToast({ content: message });
        }
      } else {
        errorToast({ content: "Server is down please wait ..." });
      }
    }
  };

  fetchMoreData = async () => {
    // if (this.state.page !== this.state.pagination.total_pages) {
    await this.setState(
      (prevState) => {
        return { page: prevState.page + 1, readMarked: false };
      },
      async () => {
        await this.GetNotificationsWithLoadMore(this.state.page, true);
      }
    );
  };

  switchBetweenCStoDP = async () => {
    try {
      await this.setState({
        isSwitching: true,
      });
      let response = await redirectLoginToDP({
        switch_to: "customer_support",
      });
      if (response.data.status === 200) {
        console.log("response", response.data.data);
        // ! For Local Development
        // if (process.env.REACT_APP_ENV === "dev") {
        //   let url = `http://localhost:3002/login-from-cs/${this.props.token}`;
        //   window.open(url, "_blank").focus();
        // }
        if (response?.data?.data?.redirect_url && this.props.token) {
          let url = `${response.data.data.redirect_url}${this.props.token}`;
          console.log("URL", url);
          const newWindow = window.open(url, "_blank");

          // Check if the window was successfully opened
          if (newWindow) {
            newWindow.focus();
          } else {
            console.error("Failed to open new window");
          }
        }
        await this.setState({
          switchChecked: true,
          isSwitching: false,
        });
      }
    } catch (e) {
      const { message } = getErrorObject(e);
      errorToast({
        content: message,
      });
      await this.setState({
        switchChecked: true,
        isSwitching: false,
      });
    }
  };

  switchBetweenDPtoPharmacy = async () => {
    try {
      await this.setState({
        isSwitching: true,
      });
      let response = await redirectLoginToDP({
        switch_to: "pharmacy",
      });
      if (response.data.status === 200) {
        console.log("response", response.data.data);
        // ! For Local Development
        // if (process.env.REACT_APP_ENV === "dev") {
        //   let url = `http://localhost:3002/login-from-cs/${this.props.token}`;
        //   window.open(url, "_blank").focus();
        // }

        if (response?.data?.data?.redirect_url && this.props.token) {
          let url = `${response.data.data.redirect_url}${this.props.token}`;
          console.log("URL", url);
          const newWindow = window.open(url, "_blank");

          // Check if the window was successfully opened
          if (newWindow) {
            newWindow.focus();
          } else {
            console.error("Failed to open new window");
          }
        }
        await this.setState({
          switchChecked: true,
          isSwitching: false,
        });
      }
    } catch (e) {
      const { message } = getErrorObject(e);
      errorToast({
        content: message,
      });
      await this.setState({
        switchChecked: true,
        isSwitching: false,
      });
    }
  };
  handleQRButtonClick = async () => {
    try {
      this.setState({
        fetchingQrCode: true,
      });
      let response = await fetchQRCodesAPI();
      console.log("response----", response);
      this.setState({
        showDropdown: true,
        qrCodeData: verifyObject(response, "data.data", []),
        grouped_poster_file: verifyObject(
          response,
          "data.common.grouped_poster_file",
          null
        ),
        fetchingQrCode: false,
      });
    } catch (e) {
      const { message } = getErrorObject(e);
      errorToast({
        content: message,
      });
      this.setState({
        fetchingQrCode: false,
      });
      setTimeout(() => {}, 10);
    }
  };
  // handleQrCodePrint = (item) => {
  //   console.log("item.poster_file",item.poster_file)
  //   if (typeof window !== "undefined") {
  //     const imgtoPrint =
  //       "<html><head><title>" +
  //       item.health_condition_name +
  //       "</title><script>function step1(){\n" +
  //       "setTimeout('step2()', 10);}\n" +
  //       "function step2(){window.print();window.close()}\n" +
  //       "</scri" +
  //       "pt></head><body onload='step1()'>\n" +
  //       "<img src='" +
  //       item.poster_file +
  //       "' /></body></html>";
  //     var pwa = window.open("", "", "height=700,width=700");
  //     pwa.document.write(imgtoPrint);
  //     pwa.document.close();
  //   }
  // };

  handleQrCodePrint = (item, fromAll) => {
    const pdfUrl = fromAll ? item : item?.poster_file;
    window.open(pdfUrl, "_blank");
    // var newWindow = window.open(pdfUrl, "_blank");
    // setTimeout(() => {
    //   newWindow.print();
    // }, [2000]);
    // this.setState({
    //   pdfUrl: pdfUrl,
    // });

    // printJS({

    // });
    // if (typeof window !== "undefined" && item?.poster_file) {
    //   if (typeof window !== "undefined") {
    //     const pdfUrl = item?.poster_file; // Replace with the actual PDF file URL

    //     if (!pdfUrl) {
    //       console.error("Invalid PDF URL");
    //       return;
    //     }

    //     const pdfToPrint = `<html><head>
    //         <title>${item.health_condition_name}</title>
    //         <style>
    //           iframe { image-rendering: auto; }
    //         </style>
    //         <script>
    //           function step1() {
    //             setTimeout(() => {
    //               step2()
    //             },3000)
    //           }
    //           function step2() {
    //             window.print();
    //              window.close();

    //           }
    //         </script>
    //       </head><body onload='step1()'>

    //       <iframe
    //       title="pdf document"
    //       id="print-file"
    //       height=700
    //       width=900
    //       src=${pdfUrl}>
    //       </body></html>`;

    //     var newWindow = window.open("", "", "height=700,width=1000");
    //     // setTimeout(() => newWindow.print(), 1000);

    //     if (!newWindow) {
    //       console.error("Unable to open new window");
    //       return;
    //     }

    //     newWindow.document.write(pdfToPrint);
    //     // setTimeout(() => newWindow.print(), 1000);
    //     newWindow.document.close();
    //   }
    // }
  };

  _renderQRItems = () => {
    let { qrCodeData, showDropdown, fetchingQrCode } = this.state;
    if (this.state.qrCodeData && showDropdown) {
      return (
        <Menu className="usermenu_dropdown _renderQRItems_Wrapper width-262">
          <Menu.Item key={this.state.grouped_poster_file}>
            <span
              onClick={() =>
                this.handleRedirect(this.state.grouped_poster_file)
              }
            >
              <b>All condition</b>
            </span>

            <img
              id={`${1}_qr_code_to_print`}
              title={"Grouped poster file"}
              src={this.state.grouped_poster_file}
              alt=""
              style={{ display: "none" }}
            />
            <div className="d-flex w-full mt-2">
              <a
                // eslint-disable-next-line no-script-url
                href="javascript:void(0);"
                style={{ marginLeft: "7px", color: "#1445b7" }}
                onClick={
                  () =>
                    this.handleQrCodePrint(this.state.grouped_poster_file, true) // passed true for url of all
                }
                className="text-underline"
              >
                Print Poster
              </a>
            </div>
          </Menu.Item>
          {qrCodeData.length > 0
            ? qrCodeData.map((item, index) => (
                <Menu.Item key={item.health_condition_id}>
                  <span onClick={() => this.handleRedirect(item.poster_file)}>
                    {item.health_condition_name}
                  </span>
                  {/* <div
                    style={{
                      backgroundImage: `url(${item.qr_code})`,
                      height: "300px",
                      width: "300px",
                      backgroundRepeat: "no-repeat",
                    }}
                    id={`${index}_qr_code_to_print`}
                  ></div> */}
                  <img
                    id={`${index}_qr_code_to_print`}
                    title={item.qr_code}
                    src={item.qr_code}
                    alt=""
                    style={{ display: "none" }}
                  />
                  <div className="d-flex w-full mt-2">
                    {/* <Text
                      copyable={{ text: item.qr_link }}
                      onClick={(e) => this.handleCopy(e, item.qr_link)}
                    ></Text> */}
                    <a
                      // eslint-disable-next-line no-script-url
                      href="javascript:void(0);"
                      copyable={{ text: item.qr_link }}
                      onClick={(e) => this.handleCopy(e, item.qr_link)}
                      className="text-underline"
                      style={{ color: "#1445b7" }}
                    >
                      Copy Link
                    </a>
                    {/* <PrinterFilled
                      style={{ marginLeft: "7px", color: "#1445b7" }}
                      onClick={() => this.handleQrCodePrint(item)}
                    /> */}
                    <a
                      // eslint-disable-next-line no-script-url
                      href="javascript:void(0);"
                      style={{ marginLeft: "7px", color: "#1445b7" }}
                      onClick={() => this.handleQrCodePrint(item)}
                      className="text-underline"
                    >
                      Print Poster
                    </a>
                  </div>
                </Menu.Item>
              ))
            : null}
        </Menu>
      );
    }
    return (
      <Menu>
        <Menu.Item>{fetchingQrCode ? "Loading..." : "No data"}</Menu.Item>
      </Menu>
    );
  };
  handleCorporateTagClick = async () => {
    try {
      this.setState({ fetchingCorporate: true });
      const customJWToken = generateTokenFromJWT();
      const email = verifyObject(this.props.user, "email", null);
      let response = await fetchCorporatesFromEmail(
        {
          email: email,
          portal: "doctor",
        },
        customJWToken
      );
      this.setState({
        corporateList: verifyObject(response, "data.data.corporate_lists", []),
        showCorporateDropdown: true,
        fetchingCorporate: false,
      });
    } catch (e) {
      const { message } = getErrorObject(e);
      errorToast({
        content: message,
      });
      this.setState({
        fetchingCorporate: false,
      });
    }
  };
  renderCorporateList = () => {
    let { corporateList, showCorporateDropdown, fetchingCorporate } =
      this.state;
    if (corporateList && showCorporateDropdown) {
      return (
        <Menu className="usermenu_dropdown width-190">
          {corporateList.length > 0
            ? corporateList.map((item, index) => (
                <Menu.Item
                  key={item.id}
                  onClick={() => this.handleSwitchCorporate(item.id)}
                  className={`${
                    item.id === this.state.currentCorporateId
                      ? "ant-dropdown-menu-item-active"
                      : ""
                  }`}
                >
                  <span>{item.name}</span>
                </Menu.Item>
              ))
            : null}
        </Menu>
      );
    }
    return (
      <Menu>
        <Menu.Item>{fetchingCorporate ? "Loading..." : "No data"}</Menu.Item>
      </Menu>
    );
  };
  handleSwitchCorporate = async (id) => {
    console.log("MB SWITCH CORPORATE---", id, this.state.currentCorporateId);
    if (id !== this.state.currentCorporateId) {
      try {
        store.dispatch({ type: "SWITCHING_CORPORATE", payload: true });
        let payload = {
          "device_detail[device_type]": "web",
          "device_detail[player_id]": "",
          "device_detail[corporate_organization_id]": id,

          email: verifyObject(this.props.user, "email", null),
        };
        let response = await switchCorporateApi(payload);

        if (response.data.message) {
          if (
            typeof this.props.sessionActions
              .navigateToCustomerSelectionViaOTP === "function"
          ) {
            await this.props.sessionActions.navigateToCustomerSelectionViaOTP(
              response
            );
            store.dispatch({ type: "SWITCHING_CORPORATE", payload: false });
            successToast({ content: "Corporate switched succefully" });
          }
        }
      } catch (e) {
        const { message } = getErrorObject(e);
        errorToast({
          content: message,
        });
        store.dispatch({ type: "SWITCHING_CORPORATE", payload: false });
      }
    }
    return;
  };
  handleRedirect = (link) => {
    // Redirect logic here
    if (link) {
      window.open(link, "_blank");
    }
  };

  handleCopy = (e, link) => {
    // Prevent the span click event from triggering
    e.stopPropagation();

    // Copy link to clipboard
    navigator.clipboard
      .writeText(link)
      .then(() => {
        message.success("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard", err);
        message.error("Failed to copy text to clipboard");
      });
  };

  render() {
    let {
      user,
      token,
      // appointmentCounters,
      // PendingQuestionnaireCounters,
      // scriptViewCounters,
      userRole,
      dynamicUser,
      actionCableData,
      prev_appt_tab_key,
    } = this.props;
    console.log("dynamicUser", dynamicUser);
    let currentPathname = window.location.pathname;
    let {
      scriptReviewCount,
      consultationReviewCount,
      in_review_pre_consult_count,
      // questionnaireCount,
      // reportsCount,
      appointmentCounter,
      organization_orders_count,
    } = this.state;
    let notification_count = verifyObject(
      actionCableData,
      "customerSupportChannel.data.notification_count",
      null
    );
    const can_access_cs = user?.can_access_cs;
    // const can_access_pharmacy = user?.can_access_pharmacy;
    const isPharmSmartUser = user?.is_pharmsmart_user === true ? true : false;

    const isPrivateCompany = user?.corporate_type === "private_company";
    console.log("can_access_cs", can_access_cs);
    const allowCorporateSwitch =
      window.location.pathname === appRoutesConst.dashboard ||
      window.location.pathname === appRoutesConst.messages ||
      window.location.pathname === appRoutesConst.scriptReview ||
      window.location.pathname === appRoutesConst.preconsultationReview ||
      window.location.pathname === appRoutesConst.patientSearch ||
      window.location.pathname === appRoutesConst.consultationReview;
    return (
      <Layout>
        <header className="header header_wrapper navbar-fixed-top">
          {!isPharmSmartUser && <SessionExpirationModal />}
          <div className="d-flex justify-content-between">
            <div className="header_wrapper_right pl-0">
              <div className="logo-menu-wrapper d-flex">
                <div className="logo_togglemenu">
                  <MenuOutlined onClick={this.handleCollapsiable} />
                </div>

                <h1 className="m-0 line-1 logo_w">
                  <Link to="/" title="healthya.co.uk">
                    <img
                      style={{ width: "150px" }}
                      src={ConsultSmartLogo}
                      alt="consultSmart"
                    />
                    {/* <svg
                      width="45"
                      height="45"
                      viewBox="0 0 45 45"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2545_22976)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22.5 0.5C28.5751 0.5 34.0751 2.96243 38.0564 6.94365C42.0376 10.9249 44.5 16.4249 44.5 22.5C44.5 28.5751 42.0376 34.0751 38.0564 38.0564C34.0751 42.0376 28.5751 44.5 22.5 44.5C16.4249 44.5 10.9249 42.0376 6.94365 38.0564C2.96243 34.0751 0.5 28.5751 0.5 22.5C0.5 16.4249 2.96243 10.9249 6.94365 6.94365C10.9249 2.96243 16.4249 0.5 22.5 0.5Z"
                          fill="#0336AB"
                          stroke="#043095"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26.6129 13.5795V32.4206C26.6129 32.7391 26.3523 33 26.0337 33H21.9036C21.5849 33 21.3242 32.7391 21.3242 32.4206V13.5795C21.3242 13.2602 21.5849 13 21.9036 13H26.0337C26.3523 13 26.6129 13.2602 26.6129 13.5795Z"
                          fill="#AEAFB0"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.7384 32.9991H14.7332C15.1267 32.9991 15.6056 32.7035 15.7923 32.3119C15.799 32.2886 15.807 32.2647 15.8159 32.2407L17.4787 27.7937H21.428C22.0622 27.7937 22.753 27.3409 22.9671 26.7886L24.2353 23.5149C24.4492 22.9626 24.1001 22.51 23.4682 22.51H19.4543L21.7346 16.4113C22.3924 14.6517 24.3677 13.2361 26.1425 13.0267C26.0512 13.0093 25.9551 13 25.8573 13H24.0089H22.8956H21.3557C19.4803 13 17.1958 14.5036 16.4554 16.4113L14.0881 22.51H9.86985C9.23794 22.51 8.5436 22.9662 8.33102 23.5149L7.06288 26.7886C6.84991 27.3385 7.19583 27.7937 7.83002 27.7937H12.0373L10.311 32.2407C10.1468 32.6643 10.3213 32.9991 10.7384 32.9991Z"
                          fill="#FEFEFE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M37.3623 13H33.3673C32.9738 13 32.4947 13.2958 32.3082 13.6871C32.3013 13.7105 32.2933 13.7343 32.2844 13.7583L26.3659 29.5878C25.7079 31.3477 23.7326 32.7629 21.958 32.9724C22.0493 32.99 22.1452 32.9991 22.243 32.9991H24.0921H25.2053H26.7451C28.6206 32.9991 30.9049 31.4957 31.6453 29.5878L37.7897 13.7583C37.9542 13.335 37.7795 13 37.3623 13Z"
                          fill="#FEFEFE"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2545_22976">
                          <rect width="45" height="45" fill="white" />
                        </clipPath>
                      </defs>
                    </svg> */}
                  </Link>
                </h1>
                {this.state.pdfUrl !== undefined && (
                  <ModalPopUp
                    visible={this.state.pdfUrl !== undefined}
                    handleCancel={() => {
                      this.setState({
                        pdfUrl: undefined,
                      });
                    }}
                    width={900}
                  >
                    <div className="text-center">
                      <PrintComponent pdfURL={this.state.pdfUrl} />
                    </div>
                  </ModalPopUp>
                )}
                {/* <Menu theme='dark' mode='horizontal' defaultSelectedKeys={['1']}> */}

                <ul className="main_menu">
                  {
                    <li>
                      <Link
                        id="dashboard"
                        // disabled={isPharmSmartUser}
                        className={`menu-link ${
                          isPharmSmartUser && "disabled-menu"
                        } ${
                          (currentPathname === appRoutesConst.dashboard ||
                            currentPathname ===
                              appRoutesConst.walkinAppointment ||
                            currentPathname ===
                              appRoutesConst.videoAppointment ||
                            currentPathname ===
                              appRoutesConst.patientConsultationDetail) &&
                          prev_appt_tab_key !== "in_review" &&
                          prev_appt_tab_key !== "reviewed" &&
                          prev_appt_tab_key !== "pending" &&
                          prev_appt_tab_key !== "pre_consult_reviewed" &&
                          prev_appt_tab_key !== "link_sent" &&
                          prev_appt_tab_key !== "in_progress" &&
                          prev_appt_tab_key !== "kiosk_reviewed" &&
                          "active_menu"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleMenuChange("dashboard");
                        }}
                        to={appRoutesConst?.dashboard}
                      >
                        Appointments
                        {/* {verifyObject(
                        appointmentCounters,
                        "appointment_count",
                        null
                      ) !== null &&
                        verifyObject(
                          appointmentCounters,
                          "appointment_count",
                          null
                        ) !== 0 && (
                          <span className="head_counter">
                            <span>{appointmentCounter}</span>
                          </span>
                        )} */}
                        {appointmentCounter && (
                          <span className="head_counter">
                            <span>{appointmentCounter}</span>
                          </span>
                        )}
                      </Link>
                    </li>
                  }
                  {/* {userRole === ROLE_CLINICAL_PHARMACIST && (
                    <li>
                      <a
                        id="patientSearch"
                        className={`menu-link ${
                          (currentPathname === appRoutesConst.patientSearch ||
                            currentPathname === appRoutesConst.patientDetail) &&
                          this.props.appointment_obj === null &&
                          "active_menu"
                        }`}
                        onClick={() => this.handleMenuChange("patientSearch")}
                      >
                        Search Patient
                      </a>
                    </li>
                  )}  */}
                  {(userRole === ROLE_DOCTOR ||
                    userRole === ROLE_CLINICAL_PHARMACIST ||
                    userRole === ROLE_SUPERINTENDENT_PHARMACIST) && (
                    <Fragment>
                      {!this.props.isChildCorporte &&
                        userRole === ROLE_DOCTOR && (
                          <li>
                            <Link
                              id="scriptReview"
                              disabled={isPharmSmartUser}
                              className={`menu-link ${
                                (currentPathname ===
                                  appRoutesConst.scriptReview ||
                                  currentPathname ===
                                    appRoutesConst.scriptReview ||
                                  currentPathname ===
                                    appRoutesConst.scriptReviewDetail) &&
                                "active_menu"
                              }`}
                              to={appRoutesConst.scriptReview}
                              onClick={(e) => {
                                e.preventDefault();

                                !isPharmSmartUser &&
                                  this.handleMenuChange("scriptReview");
                              }}
                            >
                              Scripts Review
                              {/* {verifyObject(
                            scriptViewCounters,
                            "scriptreivew_count",
                            null
                          ) !== null &&
                            verifyObject(
                              scriptViewCounters,
                              "scriptreivew_count",
                              null
                            ) !== 0 && (
                              <span className="head_counter">
                                <span>
                                  {scriptViewCounters.scriptreivew_count}
                                </span>
                              </span> */}
                              {/* )} */}
                              {scriptReviewCount && scriptReviewCount !== 0 ? (
                                <span className="head_counter">
                                  <span>
                                    {scriptReviewCount &&
                                    scriptReviewCount !== 0
                                      ? scriptReviewCount
                                      : ""}
                                  </span>
                                </span>
                              ) : null}
                            </Link>
                          </li>
                        )}
                      {userRole !== ROLE_SUPERINTENDENT_PHARMACIST && (
                        <li>
                          <Link
                            id="consultationReview"
                            // disabled={isPharmSmartUser}
                            className={`menu-link ${
                              (currentPathname ===
                                appRoutesConst.consultationReview ||
                                currentPathname ===
                                  appRoutesConst.patientConsultationDetail) &&
                              (prev_appt_tab_key === "in_review" ||
                                prev_appt_tab_key === "reviewed") &&
                              "active_menu"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();

                              this.handleMenuChange("consultationReview");
                            }}
                            to={appRoutesConst.consultationReview}
                          >
                            Consultation Review
                            {consultationReviewCount &&
                            consultationReviewCount !== 0 ? (
                              <span className="head_counter">
                                <span>
                                  {consultationReviewCount &&
                                  consultationReviewCount !== 0
                                    ? consultationReviewCount
                                    : ""}
                                </span>
                              </span>
                            ) : null}
                          </Link>
                        </li>
                      )}

                      <li>
                        <Link
                          id="preconsultReview"
                          // disabled={isPharmSmartUser} // enabling pre-consult for all child non child
                          className={`menu-link ${
                            (currentPathname ===
                              appRoutesConst.preconsultationReview ||
                              currentPathname ===
                                appRoutesConst.patientConsultationDetail) &&
                            (prev_appt_tab_key === "pending" ||
                              prev_appt_tab_key === "link_sent" ||
                              prev_appt_tab_key === "in_progress" ||
                              prev_appt_tab_key === "pre_consult_reviewed" ||
                              prev_appt_tab_key === "kiosk_reviewed" ||
                              prev_appt_tab_key === "kiosk_pending") &&
                            "active_menu"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();

                            // !isPharmSmartUser &&   // enabling pre-consult for all child non child
                            this.handleMenuChange("preconsultationReview");
                          }}
                          to={appRoutesConst.preconsultationReview}
                        >
                          Pre-Consult Review
                          {in_review_pre_consult_count &&
                          in_review_pre_consult_count !== 0 ? (
                            <span className="head_counter">
                              <span>
                                {in_review_pre_consult_count &&
                                in_review_pre_consult_count !== 0
                                  ? in_review_pre_consult_count
                                  : ""}
                              </span>
                            </span>
                          ) : null}
                        </Link>
                      </li>

                      {/* {!this.props.isChildCorporte && (
                        <li>
                          <a
                            id="questionnaireReview"
                            className={`menu-link ${
                              (currentPathname ===
                                appRoutesConst.questionnaireReview ||
                                currentPathname ===
                                  appRoutesConst.questionnaireReviewDetail) &&
                              "active_menu"
                            }`}
                            disabled={isPharmSmartUser}
                            to={appRoutesConst.questionnaireReview}
                            onClick={() =>
                              !isPharmSmartUser &&
                              this.handleMenuChange("questionnaireReview")
                            }
                          >
                            Questionnaire Review
                            {questionnaireCount !== 0 && (
                              <span className="head_counter">
                                <span>
                                  {questionnaireCount &&
                                  questionnaireCount !== 0
                                    ? questionnaireCount
                                    : ""}
                                </span>
                              </span>
                            )}
                          </a>
                        </li>
                      )} */}
                      {/* //dynamic questionnaire */}
                      {/* <li>
                        <a
                          id="dynamicQuestionnaireReview"
                          className={`menu-link ${
                            (currentPathname ===
                              appRoutesConst.dynamicQuestionnaireReview ||
                              currentPathname ===
                                appRoutesConst.dynamicQuestionnaireReviewDetail) &&
                            "active_menu"
                          }`}
                          to={appRoutesConst.dynamicQuestionnaireReview}
                          onClick={() =>
                            this.handleMenuChange("dynamicQuestionnaireReview")
                          }
                        >
                          Dynamic Questionnaire
                          {questionnaireCount !== 0 && (
                            <span className="head_counter">
                              <span>
                                {questionnaireCount && questionnaireCount !== 0
                                  ? questionnaireCount
                                  : ""}
                              </span>
                            </span>
                          )}
                        </a>
                      </li> */}
                      {/* {!isPharmSmartUser && (
                        <li>
                          <a
                            id="reportsReview"
                            disabled={isPharmSmartUser}
                            className={`menu-link ${
                              (currentPathname ===
                                appRoutesConst.reportsReview ||
                                currentPathname ===
                                  appRoutesConst.reportsReviewDetail) &&
                              "active_menu"
                            }`}
                            onClick={() =>
                              !isPharmSmartUser &&
                              this.handleMenuChange("reportsReview")
                            }
                          >
                            Report Review
                            {verifyObject(
                              PendingQuestionnaireCounters,
                              "pending_report",
                              null
                            ) !== null &&
                              verifyObject(
                                PendingQuestionnaireCounters,
                                "pending_report",
                                null
                              ) !== 0 && (
                                <span className="head_counter">
                                  <span>
                                    {
                                      PendingQuestionnaireCounters.pending_report
                                    }
                                  </span>
                                </span>
                              )}
                            {reportsCount !== 0 && !isPharmSmartUser && (
                              <span className="head_counter">
                                <span>
                                  {reportsCount && reportsCount !== 0
                                    ? reportsCount
                                    : ""}
                                </span>
                              </span>
                            )}
                          </a>
                        </li>
                      )} */}
                      <li>
                        <Link
                          id="messages"
                          className={`menu-link ${
                            currentPathname === appRoutesConst.messages &&
                            "active_menu"
                          }`}
                          to={appRoutesConst.messages}
                          onClick={(e) => {
                            e.preventDefault();

                            this.handleMenuChange("messages");
                          }}
                        >
                          Messages
                        </Link>
                      </li>
                      <li>
                        <Link
                          id="patientSearch"
                          className={`menu-link ${
                            (currentPathname === appRoutesConst.patientSearch ||
                              currentPathname ===
                                appRoutesConst.patientDetail) &&
                            this.props.appointment_obj === null &&
                            "active_menu"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();

                            this.handleMenuChange("patientSearch");
                          }}
                          to={appRoutesConst?.patientSearch}
                        >
                          Search Patient
                        </Link>
                      </li>
                    </Fragment>
                  )}
                </ul>
                {/* <Menu theme='dark' mode='horizontal' defaultSelectedKeys={['1']}>
>>>>>>> 7bfc30bd0d5fa4b58789c1d953275a662aade959
									<Menu.Item
										onClick={() =>
											this._handleNavigation(appRoutesConst.dashboard)
										}
										key='1'
									>
										Appointments
										{verifyObject(
											appointmentCounters,
											'appointment_count',
											null
										) !== null &&
											verifyObject(
												appointmentCounters,
												'appointment_count',
												null
											) !== 0 && (
												<span className='head_counter'>
													<span>
														{appointmentCounters.appointment_count}
													</span>
												</span>
											)}
									</Menu.Item>
									<Menu.Item key='2'>
										Scripts Review{' '}
										{verifyObject(
											scriptViewCounters,
											'scriptreivew_count',
											null
										) !== null &&
											verifyObject(
												scriptViewCounters,
												'scriptreivew_count',
												null
											) !== 0 && (
												<span className='head_counter'>
													<span>
														{scriptViewCounters.scriptreivew_count}
													</span>
												</span>
											)}
									</Menu.Item>
									<Menu.Item
										key='3'
										onClick={() =>
											this._handleNavigation(
												appRoutesConst.questionnaireReview
											)
										}
									>
										Questionnaire Review{' '}
										{verifyObject(
											PendingQuestionnaireCounters,
											'pending_questionnaire',
											null
										) !== null &&
											verifyObject(
												PendingQuestionnaireCounters,
												'pending_questionnaire',
												null
											) !== 0 && (
												<span className='head_counter'>
													<span>
														{
															PendingQuestionnaireCounters.pending_questionnaire
														}
													</span>
												</span>
											)}
									</Menu.Item>
									<Menu.Item
										key='4'
										onClick={() =>
											this._handleNavigation(appRoutesConst.reportsReview)
										}
									>
										Report Review{' '}
										{verifyObject(
											PendingQuestionnaireCounters,
											'pending_report',
											null
										) !== null &&
											verifyObject(
												PendingQuestionnaireCounters,
												'pending_report',
												null
											) !== 0 && (
												<span className='head_counter'>
													<span>
														{
															PendingQuestionnaireCounters.pending_report
														}
													</span>
												</span>
											)}
									</Menu.Item>
									<Menu.Item key='5'>Message</Menu.Item>
									<Menu.Item
										onClick={() =>
											this._handleNavigation(appRoutesConst.patientSearch)
										}
										key='6'
									>
										Search Patient
									</Menu.Item>
								</Menu> */}
              </div>
            </div>

            {/* <div className="col-md-4 d-flex justify-content-end align-items-center">
              <div className="help-guide-container  user_role in-block text-capitalize font-11 font-weight-400">

              </div>
            </div> */}

            <div className="d-flex justify-content-end align-items-center header_wrapper_left">
              <div className="userdropdown in-block text-capitalize font-11 font-weight-400 qrcodedropbtn">
                <Dropdown trigger={["click"]} overlay={this._renderQRItems}>
                  <Button
                    disabled={this.state.fetchQRCodesAPI}
                    onClick={this.handleQRButtonClick}
                  >
                    QR Code
                  </Button>
                </Dropdown>
              </div>
              <div
                className={`d-flex align-items-center ${
                  allowCorporateSwitch ? "cursor-pointer" : "disabled-view"
                }`}
              >
                <div className="user_role in-block text-capitalize font-11 font-weight-400">
                  <Dropdown
                    trigger={["click"]}
                    overlay={this.renderCorporateList}
                    disabled={this.state.fetchingCorporate}
                    className=""
                  >
                    <Tag
                      color="processing"
                      className="text-capitalize"
                      onClick={this.handleCorporateTagClick}
                    >
                      {user && user.corporate_name ? user.corporate_name : ""}

                      <CaretDownFilled />
                    </Tag>
                  </Dropdown>
                </div>
              </div>
              <div className="help-guide-container  user_role in-block text-capitalize font-11 font-weight-400">
                <Tooltip placement="bottom" title={"Help Guide"}>
                  <a className="">
                    <img
                      src={
                        require("../../assets/images/common/help.png").default
                      }
                      alt=""
                      style={{ width: "75%" }}
                      onClick={() => {
                        // this.setState({ show: true });
                        window.open(GuidePDF);
                      }}
                    />
                  </a>
                </Tooltip>
              </div>
              <div className="d-flex align-items-center">
                {can_access_cs && !isPrivateCompany && (
                  <div className="csbtn">
                    <a
                      id="patientSearch"
                      className={`menu-link ${
                        (currentPathname === appRoutesConst.patientSearch ||
                          currentPathname === appRoutesConst.patientDetail) &&
                        this.props.appointment_obj === null &&
                        "active_menu"
                      }`}
                      // onClick={() => this.handleMenuChange("patientSearch")}
                      onClick={this.switchBetweenCStoDP}
                    >
                      Pharmacy Portal
                      {organization_orders_count &&
                      organization_orders_count !== 0 ? (
                        <span className="head_counter">
                          <span>
                            {organization_orders_count &&
                            organization_orders_count !== 0
                              ? organization_orders_count
                              : ""}
                          </span>
                        </span>
                      ) : null}
                    </a>
                  </div>
                )}
              </div>
              {this.props.user && this.props.token && (
                <div className="notification in-block margin-right-4 margin-left-4">
                  <HeaderDropDown>
                    <HeaderDropDown.Toggle
                      variant="success"
                      id="dropdown-basic"
                    >
                      <span
                        onClick={this.handleNotification}
                        className="img-responsive center-block in-block margin-right-15 float-left"
                        alt=""
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="24"
                          viewBox="0 0 20 24"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g fill="#FFF" fillRule="nonzero">
                              <g>
                                <path
                                  d="M18.619 19.1c-.07-.046-.602-.418-1.133-1.544-.977-2.066-1.182-4.977-1.182-7.055v-.027c-.01-2.746-1.655-5.114-4.009-6.176V2.674C12.295 1.199 11.098 0 9.626 0h-.221c-1.472 0-2.67 1.2-2.67 2.674v1.624c-2.36 1.066-4.008 3.445-4.008 6.203 0 2.078-.205 4.989-1.181 7.055C1.014 18.682.483 19.054.412 19.1c-.297.138-.443.456-.375.778.069.325.373.55.705.55h5.154C5.925 22.401 7.536 24 9.516 24c1.978 0 3.59-1.598 3.62-3.574h5.153c.332 0 .636-.224.705-.55.069-.321-.078-.64-.375-.777zM8.142 2.673c0-.698.567-1.265 1.263-1.265h.221c.697 0 1.263.567 1.263 1.265v1.168c-.444-.092-.903-.14-1.374-.14-.47 0-.93.048-1.373.14V2.673zM9.516 22.59c-1.204 0-2.185-.966-2.214-2.165h4.427c-.028 1.199-1.01 2.165-2.213 2.165zm2.83-3.573H2.345c.122-.19.246-.404.369-.647.942-1.858 1.42-4.506 1.42-7.87 0-2.972 2.415-5.39 5.382-5.39 2.968 0 5.383 2.418 5.383 5.393v.025c.003 3.35.48 5.989 1.42 7.842.123.243.247.457.369.647h-4.34z"
                                  transform="translate(-1069 -16) translate(1069 16)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                      {notification_count ? (
                        <span
                          onClick={this.handleNotification}
                          className="bg-red notification-badge badge-secondary badge-pill"
                        >
                          {notification_count}
                        </span>
                      ) : notification_count ? (
                        <Spinner />
                      ) : (
                        <span
                          onClick={this.handleNotification}
                          className="bg-red notification-badge badge-secondary badge-pill"
                        >
                          {notification_count}
                        </span>
                      )}
                    </HeaderDropDown.Toggle>

                    <HeaderDropDown.Menu>
                      {!this.state.loading &&
                        this.state.notifications.length === 0 && (
                          <div
                            in-block
                            margin-right-15
                            style={{ height: 300 }}
                            className="notificationinnerlinks dis-block"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="notification_loader_section no-notification-sec"
                            >
                              <span>You're All Caught Up</span>
                            </div>
                          </div>
                        )}

                      <div
                        id="scrolling_div"
                        className="notificationinnerlinks dis-block"
                      >
                        <ul
                          // id="scrolling_div"
                          className="notifications_text"
                        >
                          {this.state.loading && (
                            <span className="notification_loader_section ng-scope">
                              {/* <PlaceholderLoader contentName="Notifications..." /> */}
                              <div className="noti-loader-wrapper">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                              </div>
                            </span>
                          )}
                          <InfiniteScroll
                            dataLength={this.state.notifications.length}
                            next={this.scrollMoreData}
                            hasMore={this.state.page !== this.state.total_pages}
                            height={300}
                            // loader={}
                            scrollableTarget="scrollableDiv"
                          >
                            {/* <Scrollbars style={{ height: "300px" }}> */}
                            {this.state.notifications.map((id) => {
                              return (
                                <li key={id.id}>
                                  <div className="notification_img">
                                    <div className="notification_img_sprite schedule_consult"></div>
                                  </div>
                                  <div className="notification_data">
                                    <p className="font-13 weight-500 text-default-color push-10-b notication-title">
                                      {id.combine_json.title}
                                      <span className="font-11 weight-400 text-dark-gray float-right notication-date-time">
                                        {moment
                                          .utc(id.created_at)
                                          .format("Do MMM YYYY h:mm")}
                                      </span>
                                    </p>
                                    <div className="dot_div"></div>
                                    <p className="font-13 weight-400 text-dark-gray margin-bottom-0">
                                      {id.combine_json.message}
                                    </p>
                                    <a
                                      onClick={() =>
                                        this.handleMarkAsRead(id.id)
                                      }
                                      href={() => false}
                                      className="mark-read"
                                    >
                                      Mark as read
                                    </a>
                                  </div>
                                </li>
                              );
                            })}
                            {/* </Scrollbars> */}
                          </InfiniteScroll>
                        </ul>
                        {/* </Scrollbars> */}
                      </div>
                    </HeaderDropDown.Menu>
                  </HeaderDropDown>
                </div>
              )}{" "}
              {user && token && (
                <div className="userdropdown in-block text-capitalize font-11 font-weight-400 min-w-100">
                  <Dropdown trigger={["click"]} overlay={this._renderMenuItems}>
                    <a className="text-capitalize" style={{ color: "white" }}>
                      {user.first_name} {user.last_name}
                      <CaretDownFilled />
                      {user && token && (
                        <div className="badge-blue-small text-blue userrole__badge mt-1">
                          <Tag color="processing" className="text-capitalize">
                            {verifyObject(user, "profile.designation")
                              ? verifyObject(user, "profile.designation")
                              : user.role
                              ? typeof user.role.replaceAll === "function"
                                ? user.role.replaceAll("_", " ")
                                : user.role
                              : ""}
                          </Tag>
                        </div>
                      )}
                    </a>
                  </Dropdown>
                </div>
              )}
            </div>
            <ModalPopUp
              title="Help Guide"
              visible={this.state.show}
              handleCancel={() => {
                this.setState({ show: false });
              }}
              width={828}
              cancelBtnclassName="custom_cancel_btn"
              footer={false}
              maskClosable={false}
            >
              <PDFViewer pdf={GuidePDF} />
            </ModalPopUp>
          </div>
        </header>
      </Layout>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    corporate_id: verifyObject(state.localStore, "user.corporate_id", null),
    user: verifyObject(state.localStore, "user", null),
    isChildCorporte: verifyObject(
      state.localStore,
      "user.add_child_corporate_cs",
      null
    ),
    token: verifyObject(state.localStore, "token", null),
    userRole: verifyObject(state.localStore, "user.role", null),
    isCollapsed: verifyObject(state, "dynamicTab.isCollapsed", false),
    dynamicUser: verifyObject(state, "dynamicTab.user", false),
    scriptViewCounters: verifyObject(
      state.actionCableData,
      "ScriptreviewCounter.data",
      null
    ),
    appointmentCounters: verifyObject(
      state.actionCableData,
      "AppointmentCounter.data",
      null
    ),
    PendingQuestionnaireCounters: verifyObject(
      state.actionCableData,
      "PendingQuestionnaireChannel.data",
      null
    ),
    appointment_obj: verifyObject(state, "localStore.appointment_obj", null),
    isInErrorBoundry: verifyObject(state, "localStore.isInErrorBoundry", false),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sessionActions: bindActionCreators(
      { logOut, navigateToCustomerSelectionViaOTP },
      dispatch
    ),
    navigationAction: bindActionCreators({ navigateTo }, dispatch),
    actionCableActions: bindActionCreators(actionCableActions, dispatch),
    handleSidebar: bindActionCreators({ onCollapseExpandSidebar }, dispatch),
    dynamicTabAction: bindActionCreators(
      {
        onSetDynamicInput,

        onSetDynamicHealthInput,
      },
      dispatch
    ),
  };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Header);
export default withRouter(connectedComponent);
