import {toast, cssTransition} from "react-toastify";
import {responseHeaderKeys} from "services/index";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {get, isArray, isEmpty} from "lodash";
import {v4 as uuidv4} from "uuid";
import {customAlphabet} from "nanoid";
import {toast as _Toast} from "react-hot-toast";

// import jwt from "jsonwebtoken";
import {ALPHANUMERIC} from "constants/common";
import {message} from "antd";
const jwt = require("jsonwebtoken");

export const reasonPhoneOptions = [
  {
    value: "Patient underage does not have own mobile device",
  },
  {
    value: "Patient has no mobile phone device",
  },
  {
    value: "Child account",
  },
  {
    value: "other",
  },
];
export const reasonEmailOptions = [
  {
    value: "Patient underage does not have own email account",
  },
  {
    value: "Patient digitally poor - no email",
  },
  {
    value: "Child account",
  },
  {
    value: "other",
  },
];
export const showToast = (content, options = {}) => {
  return _Toast(() => content, {
    ...options,
    duration: options.duration || 2000,
    className: "react-hot-toast-container",
  });
};

export const diagnosiColorArray = [
  {min: 65, max: 100, color: "#ff7f27"},
  {min: 40, max: 64, color: "#fdc400"},
  {min: 0, max: 39, color: "#eadb95"},
];

export const abbrevationDiagnosis = {
  ALLERGICDISORDERS: "ALLERG",
  CARDIACDISORDERS: "CARDIO",
  DERMATOLOGYDISORDERS: "DERM",
  EARDISORDERS: "EAR",
  ENDOCRINEDISORDERS: "ENDO",
  ENVIRONMENTALHEALTHHAZARDS: "ENVIRO",
  GASTROINTESTINALDISORDERS: "GASTRO",
  GENETICDISORDERS: "GENE",
  GERIATRICMEDICINEDISORDERS: "GERI",
  GYNAECOLOGICALDISORDERS: "GYNE",
  HAEMATOLOGYDISORDERS: "HEMAT",
  IATROGENICDISORDERS: "IATRO",
  IMMUNOLOGICDISORDERS: "IMMUNO",
  INFECTIOUSDISEASES: "INFEC",
  LIVERDISORDERS: "HEPATO",
  METABOLICDISEASES: "METAB",
  NEONATALINFANTDISEASES: "NEON",
  NEOPLASTICDISEASES: "NEOPL",
  NEPHROLOGYDISORDERS: "NEPHRO",
  NERVOUSSYSTEMDISORDERS: "NEURO",
  NEUROMUSCULARDISORDERS: "NEURO/MUSC",
  NUTRITIONALDISORDERS: "NUTRI",
  OBSTETRICDISORDERS: "OBS",
  OPHTHALMOLOGYDISORDERS: "OPHTHAL",
  ORTHOPAEDICDISORDERS: "ORTHO",
  PSYCHOLOGICDISORDERS: "PSYC",
  RESPIRATORYDISORDERS: "RESP",
  RHEUMATICDISEASES: "RHEUM",
  SHOCKSTATES: "SHOCK",
  SOCIALISSUES: "SOC",
  TOXICOLOGYDISORDERS: "TOX",
  TRAUMADISORDERS: "TRAUMA",
  UNCLASSIFIEDDISEASES: "UNCLASS",
  UROLOGICDISORDERS: "UROL",
  VASCULARDISORDERS: "VASC",
};
export const Health_Assessment_KVP = [
  {label: "Pulse", key: "pulse_per_minute", value: null, id: 0, unit: "bpm"},
  {label: "Diastolic", key: "diastolic_bp", value: null, id: 1, unit: "mmHg"},
  {label: "Systolic", key: "systolic_bp", value: null, id: 2, unit: "mmHg"},
  {
    label: "SpO2",
    key: "blood_oxygen_saturation",
    value: null,
    id: 3,
    unit: "%",
  },
  {
    label: "Temperature",
    key: "body_temperature",
    value: null,
    id: 4,
    unit: "°C",
  },
  {label: "Height", key: "height", value: null, id: 5, unit: "cm"},
  {label: "Weight", key: "weight", value: null, id: 6, unit: "kg"},
  {
    label: "Respiratory rate",
    key: "respiratory_rate",
    value: null,
    id: 7,
    unit: "bpm",
  },
  {label: "BMI", key: "bmi", value: null, id: 8, unit: ""},
];

export const checkboxArray = [
  {
    short_text: "Wells DVT",
    long_text: "Wells' Criteria for DVT",
    link: "https://www.mdcalc.com/calc/362/wells-criteria-dvt",
    snomed_code: 429053008,
    id: process.env.REACT_APP_ENV === "prod" ? 1301920 : 1989892,
    snomed_code_description:
      "Wells deep vein thrombosis clinical probability score (observable entity)",
    value: null,
    allowNegativeValue: true,
  },

  {
    short_text: "Wells PE",
    long_text: "Wells' Criteria for Pulmonary Embolism",
    link: "https://www.mdcalc.com/calc/115/wells-criteria-pulmonary-embolism",
    snomed_code: 513651000000104,
    id: process.env.REACT_APP_ENV === "prod" ? 11818 : 1197151,
    snomed_code_description:
      "Wells pulmonary embolus clinical probability score (observable entity)",
    value: null,
    allowNegativeValue: false,
  },

  {
    short_text: "Stroke",
    long_text: "NIH Stroke Scale/Score ",
    link: "https://www.mdcalc.com/calc/715/nih-stroke-scale-score-nihss",
    snomed_code: 450741005,
    id: process.env.REACT_APP_ENV === "prod" ? 1416108 : 1989893,
    snomed_code_description:
      "National Institutes of Health stroke scale (assessment scale)",
    value: null,
    allowNegativeValue: false,
  },
  {
    short_text: "PHQ-9",
    long_text: "PHQ-9 (Patient Health Questionnaire-7)",
    link: "https://www.mdcalc.com/calc/1725/phq9-patient-health-questionnaire9",
    snomed_code: 720433000,
    id: process.env.REACT_APP_ENV === "prod" ? 1526377 : 1059904,
    snomed_code_description:
      "Patient Health Questionnaire Nine Item score (observable entity)",
    value: null,
    allowNegativeValue: false,
  },
  {
    short_text: "Pregnancy",
    long_text: "Pregnancy Due Dates Calculator",
    link: "https://www.mdcalc.com/calc/423/pregnancy-due-dates-calculator",
    snomed_code: 161714006,
    id: process.env.REACT_APP_ENV === "prod" ? 767810 : 611741,
    snomed_code_description: "Estimated date of delivery (observable entity)",
    value: null,
    allowNegativeValue: false,
  },
  {
    short_text: "Centor throat",
    long_text: "Centor Score (Modified/McIsaac) for Strep Pharyngitis",
    link: "https://www.mdcalc.com/calc/104/centor-score-modified-mcisaac-strep-pharyngitis",
    snomed_code: 716599007,
    id: process.env.REACT_APP_ENV === "prod" ? 1516512 : 1050033,
    snomed_code_description: "Centor criteria score (observable entity)",
    value: null,
    allowNegativeValue: true,
  },

  {
    short_text: "Framingham CHD",
    long_text: "Risk Score for Hard Coronary Heart Disease",
    link: "https://www.mdcalc.com/calc/38/framingham-risk-score-hard-coronary-heart-disease",
    snomed_code: 450759008,
    id: process.env.REACT_APP_ENV === "prod" ? 1416119 : 1989894,
    snomed_code_description:
      "Framingham coronary heart disease 10 year risk score (observable entity)",
    value: null,
    allowNegativeValue: false,
  },
  {
    short_text: "Sepsis",
    long_text: "SIRS Sepsis and Septic Shock Criteria",
    link: "https://www.mdcalc.com/calc/1096/sirs-sepsis-septic-shock-criteria",
    snomed_code: 426929000,
    id: process.env.REACT_APP_ENV === "prod" ? 1391479 : 1989891,
    snomed_code_description:
      "Systemic inflammatory response syndrome score (observable entity)",
    value: null,
    allowNegativeValue: false,
  },
  {
    short_text: "GAD-7",
    long_text: "GAD-7 (General Anxiety Disorder-7)",
    link: "https://www.mdcalc.com/calc/1727/gad7-general-anxiety-disorder7",
    snomed_code: 445455005,
    id: process.env.REACT_APP_ENV === "prod" ? 1764150 : 1989895,
    snomed_code_description:
      "Generalized Anxiety Disorder 7 item score (observable entity)",
    value: null,
    allowNegativeValue: false,
  },
  {
    short_text: "GCS",
    long_text: "Glasgow Coma Scale/Score (GCS)",
    link: "https://www.mdcalc.com/calc/64/glasgow-coma-scale-score-gcs",
    snomed_code: 248241002,
    id: process.env.REACT_APP_ENV === "prod" ? 863231 : 707166,
    snomed_code_description: "Glasgow coma score (observable entity)",
    value: null,
    allowNegativeValue: false,
  },
];

export const arrayRange = (start, stop, step) =>
  Array.from(
    {length: (stop - start) / step + 1},
    (value, index) => `${start + index * step}`
  );
export const heightRangeArray = (start, end, pointStart, pointEnd, unit) => {
  let ar = [];

  if (pointStart !== null) {
    for (let i = start; i <= end; i++) {
      for (let m = pointStart; m <= pointEnd; m++) {
        let inches = `${i} ft ${m} inches`;
        ar.push({
          label: `${i}.${m} ${unit} `,
          value: unit === "feet" ? convertToInches(inches) : `${i}.${m}`,
        });
      }
    }
  } else {
    for (let i = start; i <= end; i++) {
      // for (let m = 0; m < 12; m++) {
      ar.push({
        label: `${i} ${unit ? unit : ""}`,
        value: `${i}`,
      });
      // }
    }
  }

  return ar;
};
export const feetToInches = (feet) => {
  const inches = feet * 12;
  return inches;
};
export const scrolltoElement = (id) => {
  let elm = document.getElementById(id);
  console.log("scroll to element", id);
  if (elm) {
    var headerOffset = 95;
    var elementPosition = elm.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    elm.scrollIntoView({
      // top: offsetPosition,
      behavior: "smooth",
    });
  }
};
export const generateTokenFromJWT = () => {
  let secret_key =
    "e0456c1df4a1de93160b8ce1dd4bd189e1d794b7256890067218794eb16950e16f9a84dbad9cbc773aef8c408016514679bc9d2414a0ade70a17f56b281f90c3";

  if (process.env.REACT_APP_ENV === "prod") {
    secret_key =
      "d2b1c88cdc61ecbc09d8cf68d291f1adf95f27e8f458ff07c0caf6856e398072d2ac047d710b01a70ec2ee455d39ec26f5110f8fcc35b21870593f0371e6a45f";
  }

  const nanoid = customAlphabet(ALPHANUMERIC, 10);
  const payload = {
    timestamp: `AddVantageDr-${moment().utc().format("YYYY-MM-DD")}`,
    secret_code: nanoid(),
    exp: moment().utc().add("10", "minutes").unix(),
    code: uuidv4(),
  };
  console.log("Payload", payload);

  const jwtToken = jwt.sign(payload, secret_key, {
    algorithm: "HS256",
  });
  return jwtToken;
};
//for height arrayin BMI tab

export function convertToInches(distance) {
  const regex = /(\d+)\s*(?:ft|feet)?\s*(\d+)?\s*(?:in|inches)?/;
  const match = distance.match(regex);
  if (!match) {
    return null; // handle invalid input
  }
  const feet = parseInt(match[1], 10) || 0;
  const inches = parseInt(match[2], 10) || 0;
  const totalInches = feet * 12 + inches;
  return totalInches;
}
export function convertToInchesDy(feet) {
  if (feet) {
    const spl = feet.split(".");
    // eslint-disable-next-line radix
    const feets = parseInt(spl[0]);
    const inch = parseInt(spl[1]);
    const totalInches = feets * 12 + inch;
    return totalInches;
  }
  return;
}
//for height arrayin BMI tab NOTE:due to 4.2>4.11 and for range max value not showing 4.11 if  min selected 4.2 so this conversion is.
export function convertToFeet(inches) {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  if (feet === 0) {
    return `${remainingInches}`;
  } else if (remainingInches === 0) {
    return `${feet}`;
  } else {
    return `${feet}.${remainingInches}`;
  }
}

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  duration: 300,
});

const commonDateFormats = {
  input: {
    dateAndTime: "",
    date: "YYYY-MM-DD",
    time: "",
  },
  output: {
    dateAndTime: "Do MMM YYYY, h:mm a",
    date: "Do MMM YYYY",
    time: "h:mm a",
  },
};

export const dateFormats = {
  common: commonDateFormats,
  customers: {
    input: {
      created_at: "",
      date_of_birth: "YYYY-MM-DD",
    },
    output: {
      created_at: commonDateFormats.output.dateAndTime,
      date_of_birth: commonDateFormats.output.date,
    },
  },
};

export const formatDate = (params) => {
  const {date, format} = params;
  if (!date) {
    return null;
  }

  return moment(date, format.input).format(format.output);
};

export const formatFullDateTime = ({date}) => {
  if (!date) {
    return null;
  }
  return formatDate({
    date,
    format: {
      input: commonDateFormats.input.dateAndTime,
      output: commonDateFormats.output.dateAndTime,
    },
  });
};

export const formatJustDate = ({date}) => {
  if (!date) {
    return null;
  }
  return formatDate({
    date,
    format: {
      input: commonDateFormats.input.date,
      output: commonDateFormats.output.date,
    },
  });
};

export const getErrorObject = (error) => {
  console.log("🚀 ~ getErrorObject ~ error:", error);
  if (error.message !== "canceled" && error.code !== "ERR_CANCELED" && error.code !=="ECONNABORTED") {
    let message = "Error!";
    if (error && error.data && error.data.message) {
      // API server error message
      message = error.data.message;
    } else if (error && error.message) {
      // js error message
      message = error.message;
    }
    if (error?.data?.status === 404 || error === "404") {
      return {
        message: null,
      };
    } else {
      return {
        message,
      };
    }
  } else {
    return {
      message: null,
    };
  }
  // if (error && error.data && error.data.message) {
  //   // API server error message
  //   message = error.data.message;
  // } else if (error && error.message) {
  //   // js error message
  //   message = error.message;
  // }
  // return {
  //   message,
  // };
};

export const errorToast = ({content, options = {}}) => {
  if (!content) {
    return;
  }
  return toast(content, {
    transition: Zoom,
    type: "error",
    containerId: options.containerId ? options.containerId : "container_main",
    ...options,
  });
};

export const successToast = ({content, options = {}}) => {
  if (!content) {
    return;
  }
  return toast(content, {
    transition: Zoom,
    type: "success",
    containerId: options.containerId ? options.containerId : "container_main",
    ...options,
  });
};

export const infoToast = ({content, options = {}}) => {
  if (!content) {
    return;
  }
  return toast(content, {
    type: "info",
    ...options,
  });
};

export const disableCopy = (e) => {
  e.preventDefault();
  message.error({
    content: "Copy is not allowed",
    duration: 3,
  });
  return false;
};

export const warningToast = ({content, options = {}}) => {
  if (!content) {
    return;
  }
  return toast(content, {
    type: "warning",
    ...options,
  });
};

export const normalize = ({data, key = "id"}, fromSearchPatient) => {
  let obj = {};
  let ids = [];
  data &&
    data.length > 0 &&
    data.forEach((element, i) => {
      if (element[key]) {
        obj[element[key]] = element;
        ids.push(element[key]);
      } else {
        obj[i] = element;
        ids.push(i);
      }
    });
  return {data: fromSearchPatient ? data : obj, ids};
};
export const generateParamsURL = (data) => {
  return "?q=" + JSON.stringify(data);
};
export const normalizeByGroup = ({data, key, groupKey}) => {
  let obj = {};
  let ids = [];
  let group = {};
  let groupIds = new Set();
  data.forEach((element) => {
    obj[element[key]] = element;
    groupIds.add(element[groupKey]);
    if (!group[element[groupKey]]) {
      group[element[groupKey]] = [];
    }
    group[element[groupKey]].push(element[key]);
    ids.push(element[key]);
  });
  return {data: obj, ids, groupIds: [...groupIds], group};
};

export const currencies = {
  inr: {key: "inr", symbol: "₹"},
  euro: {key: "euro", symbol: "€"},
  pound: {key: "pound", symbol: "£"},
};

export const normalizeResponseWithPagination = (
  {response, key = "id"},
  fromSearchPatient
) => {
  const dataArr = response.data.data ? response.data.data : [];

  //Current workaround
  let resPagination = response.headers[responseHeaderKeys.pagination];
  if (resPagination) {
    resPagination = JSON.parse(resPagination);
  } else {
    resPagination = {
      total: 0,
      total_pages: 0,
      previous_page: null,
      next_page: null,
    };
  }

  const {data, ids} = normalize({data: dataArr, key}, fromSearchPatient);

  const {total, total_pages, previous_page, next_page} = resPagination;

  return {
    data,
    ids,
    pagination: {total, total_pages, previous_page, next_page},
  };
};
let pubNubCr = {};
if (process.env.REACT_APP_ENV === "prod") {
  pubNubCr.publishKey = "pub-c-6d2a67ad-f642-4ab1-948b-e4c4cd2b7087";
  pubNubCr.subscribeKey = "sub-c-f6dcaefe-c0c4-11e8-91cd-c6d399a24064";
} else {
  pubNubCr.publishKey = "pub-c-0817e702-1f50-49bd-b0e3-3473128601f5";
  pubNubCr.subscribeKey = "sub-c-17b49a9a-57fe-11e8-9796-063929a21258";
}

export const chatChannelCreator = ({order_id, order_type, currentTab}) => {
  // const orderIdTemp = `${order_id}_`;
  // switch (order_type) {
  //   case orderTypes.nhs_medication.key: {
  //     const temp = `${orderIdTemp}nhs_`;
  //     if (currentTab === "doctor") {
  //       return `${temp}${currentTab}_patient`;
  //     } else {
  //       return `${temp}patient_${currentTab}`;
  //     }
  //   }
  //   case orderTypes.private_medication.key: {
  //     const temp = `${orderIdTemp}private_medication_`;
  //     if (currentTab === "doctor") {
  //       return `${temp}${currentTab}_patient`;
  //     } else {
  //       return `${temp}patient_${currentTab}`;
  //     }
  //   }
  //   default:
  //     return;
  // }
};
export const getExtraDataFromListing = (response) => {
  const responseData = response.data;
  const extraData = {};
  for (const key in responseData) {
    if (key !== "data") {
      extraData[key] = responseData[key];
    }
  }
  return extraData;
};

export const verifyObject = (obj, param, defaultValue) => {
  let data = get(obj, param, defaultValue);
  return data;
};

export const modifiedDataObjectToArray = (dataObject) => {
  if (dataObject && typeof dataObject === "object") {
    let rectifiedData = [];
    Object.keys(dataObject).map((key) => rectifiedData.push(dataObject[key]));
    return rectifiedData.reverse();
  } else {
    return [];
  }
};
const autoResize = (e) => {
  console.log("e.target", e);
  e.target.style.height = "auto";
  e.target.style.height = e.target.scrollHeight + "px";
};

export const isValidJSONString = (passedString) => {
  let dd = /^[\],:{}\s]*$/.test(
    passedString
      .replace(/\\["\\\/bfnrtu]/g, "@")
      .replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
        "]"
      )
      .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
  );
  return dd;
};
export const resizeTextArea = (id) => {
  let textarea = document.querySelector(`${id}`);
  if (textarea) {
    console.log("textarea", textarea);
    textarea.addEventListener("change", autoResize, false);
    textarea.addEventListener("onload", autoResize, false);
    textarea.addEventListener("loadeddata", autoResize, false);
    textarea.addEventListener("input", autoResize, false);
  }
};

export const modifiedDataObjectToArrayByIDs = (dataSource) => {
  let dataObject = verifyObject(dataSource, "data", {});
  let ids = verifyObject(dataSource, "ids", []);
  let customizedArray = [];
  if (ids && isArray(ids)) {
    ids.map((id) => {
      customizedArray.push(dataObject[id]);
      return null;
    });
    // console.log("customizedArray", customizedArray);
    return customizedArray;
  } else {
    return [];
  }
};

export const calculateBMI = (fac_obj) => {
  var first_height_value = 0,
    second_height_value = 0,
    final_inches = 0;
  var first_weight_value = 0,
    second_weight_value = 0,
    final_weight = 0;
  // console.log("fac_obj", fac_obj);
  if (
    (fac_obj.height_obj.selected_feet !== null ||
      fac_obj.height_obj.selected_feet !== undefined ||
      fac_obj.height_obj.selected_centimeter !== undefined ||
      fac_obj.height_obj.selected_centimeter !== null) &&
    (fac_obj.weight_obj.selected_stone !== null ||
      fac_obj.weight_obj.selected_stone !== undefined ||
      fac_obj.weight_obj.selected_kilogram !== undefined ||
      fac_obj.weight_obj.selected_kilogram !== null)
  ) {
    if (
      fac_obj.height_obj.selected_unit_type &&
      fac_obj.height_obj.selected_unit_type === "imperial"
    ) {
      if (fac_obj.height_obj.selected_feet !== null) {
        first_height_value = parseInt(
          fac_obj.height_obj.selected_feet.split("")[0],
          10
        );
      }
      if (
        fac_obj.height_obj.selected_inch &&
        fac_obj.height_obj.selected_inch !== null
      ) {
        second_height_value = parseInt(
          fac_obj.height_obj.selected_inch.split(" ")[0],
          10
        );
      }

      if (first_height_value !== "") {
        final_inches = first_height_value * 12;
        final_inches += second_height_value;
        final_inches = final_inches * 2.54;
      }
    } else {
      if (
        fac_obj.height_obj.selected_meter &&
        fac_obj.height_obj.selected_meter !== null
      ) {
        first_height_value = parseInt(
          fac_obj.height_obj.selected_meter.split(" ")[0],
          10
        );
      }
      if (
        fac_obj.height_obj.selected_centimeter &&
        fac_obj.height_obj.selected_centimeter !== null
      ) {
        second_height_value = parseInt(
          fac_obj.height_obj.selected_centimeter.split(" ")[0],
          10
        );
      }
      if (first_height_value !== "") {
        var temp_calc_value = 0;
        temp_calc_value = first_height_value * 100;
        final_inches = temp_calc_value + second_height_value;
      }
    }
    if (
      fac_obj.weight_obj.selected_unit_type &&
      fac_obj.weight_obj.selected_unit_type === "imperial"
    ) {
      if (fac_obj.weight_obj.selected_stone) {
        first_weight_value = parseInt(
          fac_obj.weight_obj.selected_stone.split(" ")[0],
          10
        );
      }
      if (fac_obj.weight_obj.selected_pound) {
        second_weight_value = parseInt(
          fac_obj.weight_obj.selected_pound.split(" ")[0],
          10
        );
      }
      // var temp_calc_value = 0;
      temp_calc_value = first_weight_value * 14;
      final_weight = temp_calc_value + second_weight_value;
      final_weight = final_weight * 0.45;
    } else if (
      fac_obj.weight_obj.selected_unit_type &&
      fac_obj.weight_obj.selected_unit_type === "metric"
    ) {
      if (fac_obj.weight_obj.selected_kilogram) {
        first_weight_value = parseInt(
          fac_obj.weight_obj.selected_kilogram.split(" ")[0],
          10
        );
        final_weight = first_weight_value;
      }
    }
  }

  var answer = 0;
  if (final_inches > 0 && final_weight > 0) {
    var final_count_value = 0,
      temp_value;
    temp_value = final_inches / 100;
    final_count_value = temp_value * temp_value;
    answer = (final_weight / final_count_value).toFixed(2);
    var bmi_type = "";
    if (answer <= 18) {
      bmi_type = "Underweight";
    } else if (answer >= 18.5 && answer < 25) {
      bmi_type = "Normal (healthy weight)	";
    } else if (answer >= 25 && answer < 30) {
      bmi_type = "Overweight";
    } else if (answer >= 30 && answer < 35) {
      bmi_type = "Obese Class I";
    } else if (answer >= 35 && answer <= 40) {
      bmi_type = "Obese Class II";
    } else if (answer > 40) {
      bmi_type = "Obese Class III";
    }

    var bmi_value = answer + " " + bmi_type;

    return bmi_value;
  }
};
export const renderHealthAssessmentInputColor = (value, field, isText) => {
  switch (field) {
    case "diastolic_bp":
      if (value && value < 60) {
        return isText
          ? "ha-lowBP-text"
          : "ha-lowBP text-white custom-input-number";
      } else if (value >= 60 && value < 80) {
        return isText
          ? "ha-normalBP-text"
          : "ha-normalBP text-white custom-input-number";
      } else if (value >= 80 && value < 90) {
        return isText
          ? "ha-preHyperBP-text"
          : "ha-preHyperBP text-white custom-input-number";
      } else if (value >= 90 && value < 100) {
        return isText
          ? "ha-stage1BP-text"
          : "ha-stage1BP text-white custom-input-number";
      } else if (value >= 100 && value < 120) {
        return isText
          ? "ha-stage2BP-text"
          : "ha-stage2BP text-white custom-input-number";
      } else if (value >= 120) {
        return isText
          ? "ha-stage3BP-text"
          : "ha-stage3BP text-white custom-input-number";
      } else {
        return "custom-input-number";
      }
    case "systolic_bp":
      if (value && value >= 70 && value < 90) {
        return isText
          ? "ha-lowBP-text"
          : "ha-lowBP text-white custom-input-number";
      } else if (value >= 90 && value < 120) {
        return isText
          ? "ha-normalBP-text"
          : "ha-normalBP text-white custom-input-number";
      } else if (value >= 120 && value < 140) {
        return isText
          ? "ha-preHyperBP-text"
          : "ha-preHyperBP text-white custom-input-number";
      } else if (value >= 140 && value < 160) {
        return isText
          ? "ha-stage1BP-text"
          : "ha-stage1BP text-white custom-input-number";
      } else if (value >= 160 && value < 180) {
        return isText
          ? "ha-stage2BP-text"
          : "ha-stage2BP text-white custom-input-number";
      } else if (value >= 180) {
        return isText
          ? "ha-stage3BP-text"
          : "ha-stage3BP text-white custom-input-number";
      } else {
        return "custom-input-number";
      }

    case "pulse_per_minute":
      if (value && value >= 40 && value < 59) {
        return "bg-primary text-white custom-input-number";
      } else if (value >= 60 && value < 100) {
        return "bg-success text-white custom-input-number";
      } else if (value > 100) {
        return "bg-danger text-white custom-input-number";
      } else {
        return "custom-input-number";
      }
    case "body_temperature":
      if (value && value < 36) {
        return "bg-primary text-white custom-input-number";
      } else if (value >= 36.1 && value < 37.2) {
        return "bg-success text-white custom-input-number";
      } else if (value > 37.2) {
        return "bg-danger text-white custom-input-number";
      } else {
        return "custom-input-number";
      }
    case "blood_oxygen_saturation":
      if (value && value >= 1 && value <= 95) {
        return "bg-primary text-white custom-input-number";
      } else if (value >= 96 && value < 100) {
        return "bg-success text-white custom-input-number";
      } else if (value > 100) {
        return "bg-danger text-white custom-input-number";
      } else {
        return "custom-input-number";
      }
    default:
      return "custom-input-number";
  }
};
export {pubNubCr};
