/* eslint-disable eqeqeq */
import React, {Fragment} from "react";
import DatePickerItem from "./DatePickerItem";
import { isArray } from "highcharts";
import { SPLIT_SYMBOL } from "constants/common";

export default function DatePickerQuestion({
  handleAnswerChange,
  childQuestions,
  parentQuestion,
  question,
  childOfQuestions,
  gender,
  currentIndex
}) {
  if (
    question.is_conditional &&
    childQuestions &&
    (parentQuestion.category === "single_choice" ||
      parentQuestion.category === "multi_choice" ||
      parentQuestion.category === "numerical") &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    //TODO: Handle conditional
    let selectedOption =  parentQuestion.category === "multi_choice" ? parentQuestion?.chekedAnswer: parentQuestion.answer;
    if (parentQuestion.category === "single_choice") {
      if (question?.conditional_text_values && question?.conditional_text_values[0] === selectedOption) {
        return (
          <Fragment>
            <DatePickerItem
              childOfQuestions={childOfQuestions}
              childQuestions={childQuestions}
              handleAnswerChange={handleAnswerChange}
              parentQuestion={parentQuestion}
              question={question}
              currentIndex={currentIndex}
            />
          </Fragment>
        );
      } else {
        return <div></div>;
      }
    }
    if (parentQuestion.category === "multi_choice") {
      console.log("Please selectedOption", selectedOption);
      console.log("Please multi_choice", question.conditional_text_values);
      let AllOptions =
      selectedOption &&
      isArray(selectedOption) &&
      selectedOption.map((d) => d.split(SPLIT_SYMBOL)[0]);

    console.log("Please All options", AllOptions);
    console.log(
      "question?.conditional_text_values",
      question?.conditional_text_values
    );

    if (
      question?.conditional_text_values &&
      AllOptions &&
      AllOptions[0] === question?.conditional_text_values[0] &&
      AllOptions.toString() === question?.conditional_text_values?.toString()
    ) {
      // if (question?.conditional_text_values && question?.conditional_text_values[0] === selectedOption) {
        return (
          <Fragment>
            <DatePickerItem
              childOfQuestions={childOfQuestions}
              childQuestions={childQuestions}
              handleAnswerChange={handleAnswerChange}
              parentQuestion={parentQuestion}
              question={question}
              currentIndex={currentIndex}
            />
          </Fragment>
        );
      } else {
        return <Fragment></Fragment>;
      }
    }
    if (parentQuestion.category === "numerical") {
      let selectedValue = parseInt(parentQuestion.answer, 10);
      const { conditional_max_value, conditional_min_value } = question;
      console.log("selectedValue", selectedValue);
      console.log("Max", conditional_max_value);
      console.log("Min", conditional_min_value);
      if (
        selectedValue <= conditional_max_value &&
        selectedValue >= conditional_min_value
      ) {
        return (
          <Fragment>
            <DatePickerItem
              childOfQuestions={childOfQuestions}
              childQuestions={childQuestions}
              handleAnswerChange={handleAnswerChange}
              parentQuestion={parentQuestion}
              question={question}
              currentIndex={currentIndex}
            />
          </Fragment>
        );
      } else {
        return <div></div>;
      }
    }
  } else if (
    !childOfQuestions &&
    childQuestions &&
    !question.is_conditional &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <DatePickerItem
          childOfQuestions={childOfQuestions}
          childQuestions={childQuestions}
          handleAnswerChange={handleAnswerChange}
          parentQuestion={parentQuestion}
          question={question}
          currentIndex={currentIndex}
        />
      </Fragment>
    );
  } else if (
    childOfQuestions &&
    childQuestions.answer &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <DatePickerItem
          childOfQuestions={childOfQuestions}
          childQuestions={childQuestions}
          handleAnswerChange={handleAnswerChange}
          parentQuestion={parentQuestion}
          question={question}
          currentIndex={currentIndex}
        />
      </Fragment>
    );
  } else if (
    !childOfQuestions &&
    !childQuestions &&
    question &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <DatePickerItem
          childOfQuestions={childOfQuestions}
          childQuestions={childQuestions}
          handleAnswerChange={handleAnswerChange}
          parentQuestion={parentQuestion}
          currentIndex={currentIndex}
          question={question}
        />
      </Fragment>
    );
  } else {
    return <div></div>;
  }
}
