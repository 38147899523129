/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  DP_QUESTIONNAIRE,
  DP_QUESTIONNAIRE_LIVE,
  SPLIT_SYMBOL,
} from "constants/common";
import React, {Fragment, useEffect, useState} from "react";
import {Select, Spin} from "antd";
import {getQuestionnairDetailApi} from "services/questionnaireReview";
import {errorToast, getErrorObject, successToast} from "utilities/utils";
import ModalPopUp from "components/common/ModalPopUp";
import {isArray, isEmpty, map} from "lodash";
import QuestionnaireFillUpItem from "./QuestionnaireFillUpItem";
import {ValidateFillUpAnswer} from "./ValidateFillupAnswers";

export default function QuestionnaireFillUp({
  handleExaminationChange,
  gender,
  updateSnomedCodes,
  updateReasonText,
  selectedCodesArray,
  disabled,
  fromPreConsult,
  post_gt_questionnaire,
  // symptompSnomedCode,
}) {
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  const [questionnaireDetails, setQuestionnaireDetails] = useState(null);
  const [questionnaireTemplate, setQuestionnaireTemplates] = useState(null);
  const [QUESTIONNAIRE_LIST, setQuestionnaireList] = useState([]);
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const QUESTIONNAIRE_LIST =
      process.env.REACT_APP_ENV === "prod"
        ? DP_QUESTIONNAIRE_LIVE
        : DP_QUESTIONNAIRE;
    setQuestionnaireList(QUESTIONNAIRE_LIST);
  }, []);

  useEffect(() => {
    if (post_gt_questionnaire) {
      setQuestionnaireList([
        {...post_gt_questionnaire[0], gender_preference: "both"},
      ]);
      setSelectedQuestionnaire(post_gt_questionnaire[0]?.id);
    }
  }, [post_gt_questionnaire]);

  const handleChange = (value) => {
    setSelectedQuestionnaire(value);
  };

  useEffect(() => {
    if (selectedQuestionnaire) {
      _getQuestionnaireDetails();
    }
  }, [selectedQuestionnaire]);

  const _resetQuestionnaireDetails = async () => {
    setQuestionnaireTemplates(questionnaireTemplate);
  };

  const _getQuestionnaireDetails = async () => {
    try {
      setLoading(true);

      let response = await getQuestionnairDetailApi({
        id: selectedQuestionnaire,
      });
      setLoading(false);
      const questionnairetemplate =
        response?.data?.data?.questionnaire_template;
      console.log(
        "🚀 ~ file: QuestionnaireFillUp.jsx:56 ~ const_getQuestionnaireDetails= ~ questionnairetemplate:",
        questionnairetemplate
      );
      const questionsDetails = questionnairetemplate?.question_details;
      console.log(
        "🚀 ~ file: QuestionnaireFillUp.jsx:62 ~ const_getQuestionnaireDetails= ~ questionsDetails:",
        questionsDetails
      );

      setQuestionnaireTemplates(questionnairetemplate);
      setQuestionnaireDetails(questionnairetemplate);
      setVisible(true);
    } catch (e) {
      let {message} = getErrorObject(e);
      errorToast({
        content: message,
      });
      setLoading(false);
    }
  };

  const handleAnswerChange = (val, question, childQue, childOfChild) => {
    console.log("parent", question);
    console.log("childQue", childQue);
    console.log("childOfChild", childOfChild);
    console.log("val-->", val);

    if (question && childQue && childOfChild) {
      console.log("childOfChild", childOfChild?.title);
      setQuestionnaireDetails((questionnaire) => {
        const questionsDetails = questionnaire.question_details;
        questionsDetails?.map((d) => {
          if (d.id === question.id) {
            d?.children.map((k) => {
              if (k.id === childQue.id) {
                k?.children?.map((j) => {
                  if (j.id === childOfChild.id) {
                    let updatedChild = j;
                    updatedChild.is_child = true;
                    updatedChild.parent_id = question.id;

                    if (updatedChild.category === "date") {
                      updatedChild.error = null;
                      updatedChild.answer = val;
                    } else if (updatedChild.category === "file") {
                      updatedChild.imageURL = URL.createObjectURL(val);
                    } else if (updatedChild.category === "multi_choice") {
                      console.log(
                        "child multi_choice value",
                        val.map((d) => d.split(SPLIT_SYMBOL)[0]).toString()
                      );
                      updatedChild.error = null;
                      updatedChild.answer = map(
                        val,
                        (d) => d.split(`${SPLIT_SYMBOL}`)[0]
                      ).toString();
                      updatedChild.chekedAnswer = val;
                      updatedChild.optionId = map(
                        val,
                        (d) => d.split(`${SPLIT_SYMBOL}`)[1]
                      ).toString();
                    } else {
                      updatedChild.error = null;
                      updatedChild.answer = val;
                    }
                  }
                  return j;
                });
              }
              return k;
            });
          }
          return d;
        });
        return {...questionnaire, question_details: questionsDetails};
      });
    } else if (question && childQue) {
      console.log("Child", childQue);

      setQuestionnaireDetails((questionnaire) => {
        const questionsDetails = questionnaire.question_details;
        questionsDetails?.map((d) => {
          if (d.id === question.id) {
            d.children.map((k) => {
              if (k.id === childQue.id) {
                let updatedChild = k;
                updatedChild.is_child = true;
                updatedChild.parent_id = question.id;

                if (updatedChild.category === "date") {
                  updatedChild.error = null;
                  updatedChild.answer = val;
                } else if (updatedChild.category === "file") {
                  updatedChild.imageURL = URL.createObjectURL(val);
                } else if (updatedChild.category === "multi_choice") {
                  console.log(
                    "child multi_choice value",
                    val.map((d) => d.split(SPLIT_SYMBOL)[0]).toString()
                  );
                  updatedChild.error = null;
                  updatedChild.answer = map(
                    val,
                    (d) => d.split(`${SPLIT_SYMBOL}`)[0]
                  ).toString();
                  updatedChild.chekedAnswer = val;
                  updatedChild.optionId = map(
                    val,
                    (d) => d.split(`${SPLIT_SYMBOL}`)[1]
                  ).toString();
                } else {
                  updatedChild.error = null;
                  updatedChild.answer = val;
                }
              }
              return k;
            });
          }
          return d;
        });
        return {...questionnaire, question_details: questionsDetails};
      });
    } else {
      // console.log("Parent_______________", question?.title);
      // alert()

      setQuestionnaireDetails((questionnaire) => {
        const updatedQuestionsDetails = questionnaire.question_details.map(
          (detail) => {
            if (detail.id === question.id) {
              let d = detail;

              if (d.category === "date") {
                d.error = null;
                d.answer = val;
              }
              if (d.category === "file") {
                const url = URL.createObjectURL(val);
                d.imageURL = url;
              }
              if (d.category === "multi_choice") {
                console.log("multi_choice value", val);
                d.error = null;
                d.answer = map(
                  val,
                  (d) => d.split(`${SPLIT_SYMBOL}`)[0]
                ).toString();
                d.chekedAnswer = val;
                d.optionId = map(
                  val,
                  (d) => d.split(`${SPLIT_SYMBOL}`)[1]
                ).toString();
              } else {
                d.error = null;
                d.answer = val;
              }
            }
            return detail;
          }
        );

        return {...questionnaire, question_details: updatedQuestionsDetails};
      });
    }
  };
  // console.log("questionnaireDetails ------>", questionnaireDetails);
  /**
   * Updates the questionnaire details by handling errors and setting the error message for a specific question.
   *
   * @param {string} errMsg - The error message to be set.
   * @param {object} question - The question object that needs to be updated.
   * @return {void} This function does not return anything.
   */
  const handleErrors = (errMsg, question, childQue, childOfChild) => {
    if (question && childQue && childOfChild) {
      setQuestionnaireDetails((questionnaire) => {
        const questionsDetails = questionnaire.question_details;
        questionsDetails?.map((d) => {
          if (d.id === question.id) {
            d?.children.map((k) => {
              if (k.id === childQue.id) {
                k?.children?.map((j) => {
                  if (j.id === childOfChild.id) {
                    j.error = errMsg;
                  }
                  return j;
                });
              }
              return k;
            });
          }
          return d;
        });
        return {...questionnaire, question_details: questionsDetails};
      });
    } else if (question && childQue) {
      console.log("Child", childQue?.title);

      setQuestionnaireDetails((questionnaire) => {
        const questionsDetails = questionnaire.question_details;
        questionsDetails?.map((d) => {
          if (d.id === question.id) {
            d.children.map((k) => {
              if (k.id === childQue.id) {
                k.error = errMsg;
              }
              return k;
            });
          }
          return d;
        });
        return {...questionnaire, question_details: questionsDetails};
      });
    } else {
      console.log("Parent Err message", errMsg);

      setQuestionnaireDetails((questionnaire) => {
        const questionsDetails = questionnaire.question_details;
        questionsDetails?.map((d) => {
          if (d.id === question.id) {
            d.error = errMsg;
          }
          return d;
        });
        return {...questionnaire, question_details: questionsDetails};
      });
    }
  };

  const resizeTextArea = () => {
    let textarea = document.querySelector(`#history_textarea`);
    if (textarea) {
      console.log("textarea", textarea);
      textarea.addEventListener("change", autoResize, false);
      textarea.addEventListener("input", autoResize, false);
    }
  };

  const autoResize = (e) => {
    console.log("e.target", e);
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };
  /**
   * Handles the form submission for the questionnaire.
   */
  const handleSubmit = async () => {
    // Validate the fill-up answer
    const errors = ValidateFillUpAnswer(
      questionnaireDetails,
      handleErrors,
      gender
    );
    const regex = /(<([^>]+)>)/gi;
    let questionAnswerString = "Questionnaire Answers \n";
    console.log("Errors Vaidation", errors);

    // Generate the question-answer string
    if (errors.isValid) {
      questionnaireDetails?.question_details?.forEach((d) => {
        if (!isEmpty(d.answer)) {
          questionAnswerString +=
            " " +
            d.title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
            "-" +
            " " +
            d.answer.toString() +
            "\n";
        } else {
          questionAnswerString +=
            " " +
            d.title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
            "-" +
            " " +
            "Not Answered" +
            "\n";
        }

        d?.children?.forEach((e) => {
          if (!isEmpty(e.answer)) {
            questionAnswerString +=
              "   " +
              e.title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
              "-" +
              "   " +
              e.answer.toString() +
              "\n";
          } else {
            questionAnswerString +=
              "   " +
              e.title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
              "-" +
              "   " +
              "Not Answered" +
              "\n";
          }

          e?.children?.forEach((f) => {
            if (!isEmpty(f.answer)) {
              questionAnswerString +=
                "       " +
                f.title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
                "-" +
                "       " +
                f.answer.toString() +
                "\n";
            } else {
              questionAnswerString +=
                "      " +
                f.title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
                "-" +
                "     " +
                "Not Answered" +
                "\n";
            }
          });
        });
      });
      // Call the handleExaminationChange function with the question-answer string

      if (fromPreConsult) {
        handleExaminationChange(questionAnswerString);
        successToast({
          content: "Answers are stored successfully.",
        });
      } else {
        handleExaminationChange(questionAnswerString);
      }
      let found = QUESTIONNAIRE_LIST.find(
        (d) => d.id === selectedQuestionnaire
      );
      if (found) {
        let removeOldSnomedCodeArray = selectedCodesArray.filter((selcted) => {
          return (
            !selcted.id ||
            !QUESTIONNAIRE_LIST.some(
              (item) => item.snomed_code_id === selcted.id
            )
          );
        });
        if (!fromPreConsult) {
          updateSnomedCodes(
            found,
            removeOldSnomedCodeArray
            // removeOldSymptomSnomedCode
          );
          updateReasonText({label: found.name, value: found.id});
        }
        _resetQuestionnaireDetails(); // set intial questionnaire
      }

      // Hide the form
      setVisible(false);
      setSelectedQuestionnaire(null);
      resizeTextArea();

      // Scroll to the examination section
    }
  };
  return (
    <Fragment>
      {!fromPreConsult && (
        <div className="consultant_review push-10-b questionary_select">
          <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
            Proforma History
          </p>
          {!fromPreConsult && (
            <Spin spinning={loading}>
              <Select
                className={`form-control-textarea input-bg-f9f9f9 full-width custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus min-height-92`}
                onChange={handleChange}
                value={selectedQuestionnaire}
                disabled={loading || disabled}
                loading={loading}
                filterOption={(input, option) =>
                  // console.log("option ----", option, input)
                  option.children.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Select Questionnaire"
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
                showSearch
                optionFilterProp="children"
              >
                {/* <Select.Option value={null}>Select Questionnaire</Select.Option> */}
                {QUESTIONNAIRE_LIST.map((d) => {
                  if (
                    d.gender_preference !== gender &&
                    d.gender_preference !== "both"
                  ) {
                    return null;
                  } else {
                    return (
                      <Select.Option
                        disabled={
                          d.gender_preference !== gender &&
                          d.gender_preference !== "both"
                        }
                        value={d.id}
                      >
                        <span>{d.name}</span>
                      </Select.Option>
                    );
                  }
                })}
              </Select>
            </Spin>
          )}

          {!fromPreConsult && (
            <ModalPopUp
              visible={visible}
              footer={true}
              handleCancel={() => {
                setVisible(false);
                setSelectedQuestionnaire(null);
                _resetQuestionnaireDetails(); // set intial questionnaire
              }}
              // title={`${questionnaireDetails?.name}`}
              width={1200}
              handleOk={handleSubmit}
              okText={"Save"}
              className="profomaquestionaire"
            >
              <div className="quastionanairepopup mb-3">
                {questionnaireDetails?.question_details &&
                  isArray(questionnaireDetails.question_details) &&
                  questionnaireDetails?.question_details?.map((questions) => {
                    return (
                      <QuestionnaireFillUpItem
                        questions={questions}
                        handleAnswerChange={handleAnswerChange}
                        handleErrors={handleErrors}
                        gender={gender}
                      />
                    );
                  })}
              </div>
            </ModalPopUp>
          )}
        </div>
      )}
      {fromPreConsult && (
        <div className="form-row push-10-b">
          <div className="col-md-12">
            <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
              Post Gateway Questionnaire
            </p>
            <div className="quastionanairepopup consultquestion">
              {questionnaireDetails?.question_details &&
                isArray(questionnaireDetails.question_details) &&
                questionnaireDetails?.question_details?.map((questions) => {
                  return (
                    <QuestionnaireFillUpItem
                      questions={questions}
                      handleAnswerChange={handleAnswerChange}
                      handleErrors={handleErrors}
                      gender={gender}
                    />
                  );
                })}
              <div>
                <button
                  className="btn btn-custom btn-blue font-12 weight-500 submitBtn width-100px height-30 p-0"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
